import React, { useEffect, useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { setRedux } from "../../redux/byhorses/actions";
import { Button, Checkbox, Col, Image, Input, Row, Select, Space } from 'antd'
import { DownOutlined, SearchOutlined } from '@ant-design/icons'
import horse from "../../assets/images/nft-horse/at.png"
import NftMarketplaceCard from '../../components/nftMarketplace/NftMarketplaceCard'
import axios from 'axios';
import { getAllNfts } from './api';
import filterIcon from "../../assets/images/icons/filter.png"
import filterWhiteIcon from "../../assets/images/icons/filter-white.png"
import { t } from 'i18next';

const NftMarketplace = (props) => {
  const [toggleFilterDiv, setToggleFilterDiv] = useState(false);
  const [orderingNfts, setOrderingNfts] = useState(2);
  const [nftType, setNftType] = useState(4);
  const [cardData, setCardData] = useState([])
  // const [classFilter, setClassFilter] = useState([])
  const [sexFilter, setSexFilter] = useState([])
  const [breedFilter, setBreedFilter] = useState([])
  const [typeFilter, setTypeFilter] = useState([])


  const [limit, setLimit] = useState(9)
  const [isOutOfBoundary, setIsOutOfBoundary] = useState(false)

  const history = useHistory()


  useEffect(() => {
    document.body.classList.add('bg-black',)
    return () => {
      document.body.classList.remove('bg-black')
    }
  }, [])

  // Filtreler eklendiğinde filtreleri sayfalar arası yakalamak için bu useEffect kullanılacak
  // useEffect(() => {
  //   if (!props.location.state) return
  //   setFilter(props.location.state.filter)
  //   setOrderingNfts(props.location.state.orderingNfts)
  // }, [props.location.state])

  const handleRouting = (id) => {
    // Filtreler eklendiğinde filtreleri sayfalar arası göndermek için kullanılacak 
    // history.push(`/nft-marketplace/${id}`, { orderingNfts: orderingNfts })
    history.push(`/nft-marketplace/${id}`)
  }

  // const handleClassFilter = (checked, value) => {
  //   if (checked) {
  //     const state = [...classFilter]
  //     state.push(value)
  //     setClassFilter(state)
  //   } else {
  //     const oldState = [...classFilter]
  //     const state = oldState.filter((val) => val !== value)
  //     setClassFilter(state)
  //   }
  // }

  const handleSexFilter = (checked, value) => {
    if (checked) {
      const state = [...sexFilter]
      state.push(value)
      setSexFilter(state)
    } else {
      const oldState = [...sexFilter]
      const state = oldState.filter((val) => val !== value)
      setSexFilter(state)
    }
  }
  const handleBreedFilter = (checked, value) => {
    if (checked) {
      const state = [...breedFilter]
      state.push(value)
      setBreedFilter(state)
    } else {
      const oldState = [...breedFilter]
      const state = oldState.filter((val) => val !== value)
      setBreedFilter(state)
    }
  }
  const handleTypeFilter = (checked, value) => {
    if (checked) {
      const state = [...typeFilter]
      state.push(value)
      setTypeFilter(state)
    } else {
      const oldState = [...typeFilter]
      const state = oldState.filter((val) => val !== value)
      setTypeFilter(state)
    }
  }
  const handleClearFilters = () => {
    // setClassFilter([])
    setSexFilter([])
    setBreedFilter([])
    setTypeFilter([])
  }

  const handleExpand = () => {
    setLimit(limit + 9)
  }

  const getNfts = async ({ source }) => {
    try {
      const response = await getAllNfts({ payload: { limit: limit }, cancelToken: source.token })
      if(response.data.alnfts.length < limit){
        setIsOutOfBoundary(true)
      }
      const tempArr = response.data.alnfts.map((data, index) => {
        data.image = horse
        return {
          key: index,
          id: data.id,
          data: data,
          onClick: () => handleRouting("horse-" + data.id)
        }
      })
      setCardData(tempArr)
    } catch (error) {
      console.log('ERROR: ', error)
    }
  }

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source()
    getNfts({ source })
    return () => {
      source.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit])


  const NftCards = cardData.map((data, index) => (
    <Col xs={{ span: 12, offset: 0 }} lg={{ span: 8, offset: 0 }} key={index}>
      <NftMarketplaceCard {...data} />
    </Col>
  ))

  const filterNormalStyle = {
    display: 'inline-block',
    padding: "5px 10px",
    borderRadius: 50,
    cursor: 'pointer',
    backgroundColor: '#FFFFFF',
    color: '#231340',
    fontSize: 14,
  }
  const filterSelectedStyle = {
    display: 'inline-block',
    padding: "5px 10px",
    borderRadius: 50,
    cursor: 'pointer',
    backgroundColor: '#c6017f',
    color: '#FFFFFF',
    fontSize: 14,
  }
  
  const filterDivStyle = {
    transition: 'all 0.6s',
    height: props.reduxState?.innerWidth > 768 ? 400 : "101%",
    width: props.reduxState?.innerWidth > 768 ? '60%' : '80%',
    backgroundColor: '#341C61',
    borderRadius: 50,
    boxShadow: "0px 0px 20px rgba(255, 255, 255, 0.5)",
    overflow: 'hidden',
    padding: "50px 70px 0px 70px",
  }
  const filterDivCloseStyle = {
    transition: 'all 0.6s',
    height: 0,
    width: '0%',
    backgroundColor: 'transparent',
    borderRadius: 50,
    boxShadow: "none",
    overflow: 'hidden',
    padding: 0,
  }
  
  const filterTitleStyle = {
    fontSize: 20,
    fontWeight: 700
  }
  
  return (
    <Row>
      <Col xs={{span: 22, offset: 1}} lg={{ span: 20, offset: 2 }}>
        <Row gutter={[16, 36]}>
          <Col xs={24} lg={9} align='left'>
            <Input className='pink-input' placeholder={t(`translation:nftSearchPlaceholder`)}
              style={{ width: props.reduxState?.innerWidth > 768 ? '100%' : '100%', borderRadius: 30 }}
              suffix={<SearchOutlined className='pink-input-suffix' style={{ color: '#231340' }} />} />
          </Col>
          <Col xs={8} lg={5}>
            <div style={toggleFilterDiv ? filterSelectedStyle : filterNormalStyle} onClick={() => setToggleFilterDiv(!toggleFilterDiv)}>
            {t(`translation:filter`)} &nbsp; &nbsp;  <Image preview={false} src={toggleFilterDiv ? filterWhiteIcon : filterIcon} width={15} />
            </div>
          </Col>
          <Col xs={16} lg={{ span: 10, offset: 0 }} align={props.reduxState?.innerWidth > 768 ? 'right' : 'left'}>
            <Space size={'large'}>
              <Select className='pink-select' placeholder="NFT" value={nftType} onChange={setNftType} style={{ width: props.reduxState?.innerWidth > 768 ? 120 : "120%" }} >
                <Select.Option value={1}>{t(`translation:horses`)}</Select.Option>
                <Select.Option value={2}>{t(`translation:jockeys`)}</Select.Option>
                <Select.Option value={3}>{t(`translation:studs`)}</Select.Option>
                <Select.Option value={4}>{t(`translation:all`)}</Select.Option>
              </Select>
              <Select className='pink-select' placeholder="Önerilen" value={orderingNfts} onChange={setOrderingNfts} style={{ width: props.reduxState?.innerWidth > 768 ? 150 : "120%" }} >
                {/* <Select.Option value={1}>Önerilen</Select.Option> */}
                <Select.Option value={2}>{t(`translation:mostRecent`)}</Select.Option>
                <Select.Option value={3}>{t(`translation:lowestPrice`)}</Select.Option>
                <Select.Option value={4}>{t(`translation:highestPrice`)}</Select.Option>
                <Select.Option value={5}>{t(`translation:mostFavorites`)}</Select.Option>
              </Select>
            </Space>
          </Col>
          {
            <Col xs={24} align='center'>
              <div style={toggleFilterDiv ? filterDivStyle : filterDivCloseStyle}>
                <Row gutter={[24,24]}>
                  <Col xs={24} lg={{ span: 7, offset: 2 }} align='left'>
                    <Row gutter={[24, 24]}>
                      <Col xs={24}>
                        <span style={filterTitleStyle}>{t(`translation:race`)}</span>
                      </Col>
                      <Col xs={24}>
                        <Checkbox checked={breedFilter.includes(1)} onChange={(event) => handleBreedFilter(event.target.checked, 1)}>{t(`translation:arabian`)}</Checkbox>
                      </Col>
                      <Col xs={24}>
                        <Checkbox checked={breedFilter.includes(2)} onChange={(event) => handleBreedFilter(event.target.checked, 2)}>{t(`translation:thorougbred`)}</Checkbox>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} lg={7} align='left'>
                    <Row gutter={[24, 24]}>
                      <Col xs={24}>
                        <span style={filterTitleStyle}>{t(`translation:gender`)}</span>
                      </Col>
                      <Col xs={24}>
                        <Checkbox checked={sexFilter.includes(1)} onChange={(event) => handleSexFilter(event.target.checked, 1)}>{t(`translation:filly`)}</Checkbox>
                      </Col>
                      <Col xs={24}>
                        <Checkbox checked={sexFilter.includes(2)} onChange={(event) => handleSexFilter(event.target.checked, 2)}>{t(`translation:colt`)}</Checkbox>
                      </Col>
                      <Col xs={24}>
                        <Checkbox checked={sexFilter.includes(3)} onChange={(event) => handleSexFilter(event.target.checked, 3)}>{t(`translation:mare`)}</Checkbox>
                      </Col>
                      <Col xs={24}>
                        <Checkbox checked={sexFilter.includes(4)} onChange={(event) => handleSexFilter(event.target.checked, 4)}>{t(`translation:stallion`)}</Checkbox>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} lg={7} align='left'>
                    <Row gutter={[24, 24]}>
                      <Col xs={24}>
                        <span style={filterTitleStyle}>{t(`translation:type`)}</span>
                      </Col>
                      <Col xs={24}>
                        <Checkbox checked={typeFilter.includes(1)} onChange={(event) => handleTypeFilter(event.target.checked, 1)}>{t(`translation:digitalTwin`)}</Checkbox>
                      </Col>
                      <Col xs={24}>
                        <Checkbox checked={typeFilter.includes(2)} onChange={(event) => handleTypeFilter(event.target.checked, 2)}>{t(`translation:digital`)}</Checkbox>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} style={{ marginTop: 40 }} align='center'>
                    <Button style={{ borderRadius: 20 }} onClick={handleClearFilters} className='clear-btn'><span>{t(`translation:clear`)}</span></Button>
                  </Col>
                </Row>
              </div>
            </Col>
          }
          <Col xs={24}>
            <Row gutter={props.reduxState?.innerWidth > 768 ? [160, 96] : [8, 8]}>
              {NftCards}
            </Row>
          </Col>
          <Col xs={24} align='center'>
            <DownOutlined onClick={handleExpand} className='expand-icon' style={{ display: isOutOfBoundary ? 'none' : 'inline-block' }} />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(NftMarketplace));