import React from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../redux/byhorses/actions";
import { Col, Row } from 'antd'
import HomeCarousel from '../../components/home/HomeCarousel'
import NftStats from '../../components/home/NftStats'
import HomeCoins from '../../components/home/HomeCoins'
import HomeGame from '../../components/home/HomeGame'
import HomeHorseNft from '../../components/home/HomeHorseNft'
import HomeJockeyNft from '../../components/home/HomeJockeyNft'
import HomeStudNft from '../../components/home/HomeStudNft'
import HomeFotofinishNft from '../../components/home/HomeFotofinishNft'
import { useTranslation } from 'react-i18next'

const Home = (props) => {
  const { t } = useTranslation()
  return (
    <div className='home'>
      <Row gutter={[0, 121]}>
        <Col xs={24}>
          <Row className='carousel-bg-effect'>
            <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }}>
              <HomeCarousel t={t} />
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <Row className='world-bg' gutter={[0, 144]}>
            <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }}>
              <span style={{
                fontWeight: 600,
                fontSize: props.reduxState && props.reduxState.innerWidth > 768 ? 48 : 28,
                display: "flex",
                justifyContent: "center", 
                marginTop: props.reduxState && props.reduxState.innerWidth > 768 ? 100 : 30, 
                marginBottom: props.reduxState && props.reduxState.innerWidth > 768 ? 100 : 30
              }}>
                {t('translation:nftMarketplace')}
              </span>
              <NftStats t={t} />
            </Col>
            {props.reduxState && props.reduxState.innerWidth > 768 &&
              <Col xs={{ span: 22, offset: 1 }} lg={{ span: 8, offset: 8 }}>
                <HomeCoins t={t} />
              </Col>
            }
          </Row>
          {props.reduxState && props.reduxState.innerWidth < 768 &&
            <Row style={{marginTop: 20}}>
              <Col xs={{ span: 22, offset: 1 }} lg={{ span: 8, offset: 8 }}>
                <HomeCoins t={t} />
              </Col>
            </Row>
          }
        </Col>
        <Col xs={24}>
          <Row className='horse-nft-bg' align='middle'>
            <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }}>
              <HomeHorseNft t={t} innerWidth={props.reduxState?.innerWidth} />
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <Row className='jockey-nft-bg' align='middle'>
            <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }}>
              <HomeJockeyNft t={t} innerWidth={props.reduxState?.innerWidth} />
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <Row className='stud-nft-bg' align='middle'>
            <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }}>
              <HomeStudNft t={t} innerWidth={props.reduxState?.innerWidth} />
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <Row className='fotofinish-nft-bg' align='middle'>
            <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }}>
              <HomeFotofinishNft t={t} innerWidth={props.reduxState?.innerWidth} />
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <Row className='how-to-play-bg'>
            <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }}>
              <HomeGame t={t} innerWidth={props.reduxState?.innerWidth} />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(Home));