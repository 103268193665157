import maleHorse from "../assets/images/icons/male-horse.png";
import femaleHorse from "../assets/images/icons/female-horse.png";

export const FamilyTree = ({ sex, main, mother, mothersMother, mothersFather, father, fathersMother, fathersFather }) => (
  <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="1000" height="500" viewBox="0 0 1492.105 743.606">
    <defs>
      <filter id="Line_1" x="225.288" y="313.531" width="1049.455" height="35" filterUnits="userSpaceOnUse">
        <feOffset dx="10" dy="10" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="5" result="blur" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter id="Ellipse_25" x="516.287" y="245.125" width="174" height="176" filterUnits="userSpaceOnUse">
        <feOffset dx="10" dy="10" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="5" result="blur-2" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-2" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter id="Ellipse_26" x="808.287" y="245.125" width="174" height="176" filterUnits="userSpaceOnUse">
        <feOffset dx="10" dy="10" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="5" result="blur-3" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-3" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter id="Ellipse_23" x="660.287" y="465.125" width="178" height="175" filterUnits="userSpaceOnUse">
        <feOffset dx="10" dy="10" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="5" result="blur-4" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-4" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter id="Line_2" x="731.787" y="315.125" width="35" height="201" filterUnits="userSpaceOnUse">
        <feOffset dx="10" dy="10" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="5" result="blur-5" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-5" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter id="Line_3" x="1074.33" y="68.134" width="344.609" height="37.184" filterUnits="userSpaceOnUse">
        <feOffset dx="10" dy="10" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="5" result="blur-6" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-6" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter id="Line_4" x="1241.514" y="71.354" width="35.728" height="276.14" filterUnits="userSpaceOnUse">
        <feOffset dx="10" dy="10" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="5" result="blur-7" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-7" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter id="Line_5" x="78.185" y="68.862" width="395.567" height="36.456" filterUnits="userSpaceOnUse">
        <feOffset dx="10" dy="10" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="5" result="blur-8" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-8" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter id="Ellipse_31" x="6.833" y="0" width="175.636" height="175.636" filterUnits="userSpaceOnUse">
        <feOffset dx="10" dy="10" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="5" result="blur-9" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-9" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter id="Ellipse_32" x="297.377" y="0" width="175.636" height="175.636" filterUnits="userSpaceOnUse">
        <feOffset dx="10" dy="10" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="5" result="blur-10" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-10" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter id="Ellipse_33" x="1026.652" y="0" width="175.636" height="175.636" filterUnits="userSpaceOnUse">
        <feOffset dx="10" dy="10" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="5" result="blur-11" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-11" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter id="Ellipse_34" x="1316.468" y="0" width="175.636" height="175.636" filterUnits="userSpaceOnUse">
        <feOffset dx="10" dy="10" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="5" result="blur-12" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-12" />
        <feComposite in="SourceGraphic" />
      </filter>
      <pattern id="male" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 400 400">
        <image width="400" height="400" href={maleHorse} />
      </pattern>
      <pattern id="female" preserveAspectRatio="none" width="100%" height="100%" viewBox="0 0 400 400">
        <image width="400" height="400" href={femaleHorse} />
      </pattern>
      <filter id="Line_7" x="222.514" y="71.354" width="35.728" height="276.14" filterUnits="userSpaceOnUse">
        <feOffset dx="10" dy="10" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="5" result="blur-13" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-13" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Group_94" data-name="Group 94" transform="translate(-220.713 -261.875)">
      <g id="Group_90" data-name="Group 90">
        <g transform="matrix(1, 0, 0, 1, 220.71, 261.88)" filter="url(#Line_1)">
          <line id="Line_1-2" data-name="Line 1" x2="1019.455" transform="translate(230.29 321.03)" fill="none" stroke="#231340" strokeWidth="5" />
        </g>
        <g transform="matrix(1, 0, 0, 1, 220.71, 261.88)" filter="url(#Ellipse_25)">
          <ellipse id="Ellipse_25-2" data-name="Ellipse 25" cx="72" cy="73" rx="72" ry="73" transform="translate(521.29 250.12)" fill="#231340" />
        </g>
        <g transform="matrix(1, 0, 0, 1, 220.71, 261.88)" filter="url(#Ellipse_26)">
          <ellipse id="Ellipse_26-2" data-name="Ellipse 26" cx="72" cy="73" rx="72" ry="73" transform="translate(813.29 250.12)" fill="#231340" />
        </g>
        <g transform="matrix(1, 0, 0, 1, 220.71, 261.88)" filter="url(#Ellipse_23)">
          <ellipse id="Ellipse_23-2" data-name="Ellipse 23" cx="74" cy="72.5" rx="74" ry="72.5" transform="translate(665.29 470.12)" fill="#231340" />
        </g>
        <g transform="matrix(1, 0, 0, 1, 220.71, 261.88)" filter="url(#Line_2)">
          <line id="Line_2-2" data-name="Line 2" y2="171" transform="translate(739.29 320.12)" fill="none" stroke="#231340" strokeWidth="5" />
        </g>
        <g transform="matrix(1, 0, 0, 1, 220.71, 261.88)" filter="url(#Line_3)">
          <line id="Line_3-2" data-name="Line 3" x2="314.575" y2="2.185" transform="translate(1079.35 75.63)" fill="none" stroke="#231340" strokeWidth="5" />
        </g>
        <g transform="matrix(1, 0, 0, 1, 220.71, 261.88)" filter="url(#Line_4)">
          <line id="Line_4-2" data-name="Line 4" x2="0.728" y2="246.125" transform="translate(1249.01 76.36)" fill="none" stroke="#231340" strokeWidth="5" />
        </g>
        <g transform="matrix(1, 0, 0, 1, 220.71, 261.88)" filter="url(#Line_5)">
          <line id="Line_5-2" data-name="Line 5" y1="1.456" x2="365.547" transform="translate(83.19 76.36)" fill="none" stroke="#231340" strokeWidth="5" />
        </g>
        <g transform="matrix(1, 0, 0, 1, 220.71, 261.88)" filter="url(#Ellipse_31)">
          <circle id="Ellipse_31-2" data-name="Ellipse 31" cx="72.818" cy="72.818" r="72.818" transform="translate(11.83 5)" fill="#231340" />
        </g>
        <g transform="matrix(1, 0, 0, 1, 220.71, 261.88)" filter="url(#Ellipse_32)">
          <circle id="Ellipse_32-2" data-name="Ellipse 32" cx="72.818" cy="72.818" r="72.818" transform="translate(302.38 5)" fill="#231340" />
        </g>
        <g transform="matrix(1, 0, 0, 1, 220.71, 261.88)" filter="url(#Ellipse_33)">
          <circle id="Ellipse_33-2" data-name="Ellipse 33" cx="72.818" cy="72.818" r="72.818" transform="translate(1031.65 5)" fill="#231340" />
        </g>
        <g transform="matrix(1, 0, 0, 1, 220.71, 261.88)" filter="url(#Ellipse_34)">
          <circle id="Ellipse_34-2" data-name="Ellipse 34" cx="72.818" cy="72.818" r="72.818" transform="translate(1321.47 5)" fill="#231340" />
        </g>
        <rect id="fathersFather" data-name="erkek at icon" width="123" height="124" transform="translate(242 277)" fill="url(#male)" />
        <rect id="fathersMother" data-name="at icon dişi" width="124" height="125" transform="translate(536 277)" fill="url(#female)" />
        <rect id="mothersFather" data-name="erkek at icon" width="124" height="125" transform="translate(1261 277)" fill="url(#male)" />
        <rect id="mothersMother" data-name="at icon dişi" width="124" height="125" transform="translate(1556 277)" fill="url(#female)" />
        <rect id="father" data-name="erkek at icon" width="125" height="124" transform="translate(750 522)" fill="url(#male)" />
        <rect id="mother" data-name="at icon dişi" width="125" height="124" transform="translate(1046 522)" fill="url(#female)" />
        <rect id="main" data-name="at icon dişi" width="123" height="123" transform="translate(899 743)" fill={`url(#${sex})`} />
        <text id="mainName" data-name="Learn more" transform="translate(849.316 897.481)" fill="#FFFFFF" fontSize="25" fontFamily="BaiJamjuree-Light, Bai Jamjuree" fontWeight="300"><tspan x="30" y="25">{main}</tspan></text>
        <text id="fatherName" data-name="Learn more" transform="translate(724.433 667.375)" fill="#FFFFFF" fontSize="25" fontFamily="BaiJamjuree-Light, Bai Jamjuree" fontWeight="300"><tspan x="30" y="25">{father} </tspan></text>
        <text id="motherName" data-name="Learn more" transform="translate(1026.993 667.375)" fill="#FFFFFF" fontSize="25" fontFamily="BaiJamjuree-Light, Bai Jamjuree" fontWeight="300"><tspan x="30" y="25">{mother}</tspan></text>
        <text id="mothersMotherName" data-name="Learn more" transform="translate(1538.904 422.706)" fill="#FFFFFF" fontSize="25" fontFamily="BaiJamjuree-Light, Bai Jamjuree" fontWeight="300"><tspan x="0" y="25">{mothersMother}</tspan></text>
        <text id="mothersFatherName" data-name="Learn more" transform="translate(1252.365 422.706)" fill="#FFFFFF" fontSize="25" fontFamily="BaiJamjuree-Light, Bai Jamjuree" fontWeight="300"><tspan x="0" y="25">{mothersFather}</tspan></text>
        <text id="fathersMotherName" data-name="Learn more" transform="translate(493.69 422.706)" fill="#FFFFFF" fontSize="25" fontFamily="BaiJamjuree-Light, Bai Jamjuree" fontWeight="300"><tspan x="30" y="25">{fathersMother}</tspan></text>
        <text id="fathersFatherName" data-name="Learn more" transform="translate(220.713 422.706)" fill="#FFFFFF" fontSize="25" fontFamily="BaiJamjuree-Light, Bai Jamjuree" fontWeight="300"><tspan x="0" y="25">{fathersFather}</tspan></text>
        <g transform="matrix(1, 0, 0, 1, 220.71, 261.88)" filter="url(#Line_7)">
          <line id="Line_7-2" data-name="Line 7" x2="0.728" y2="246.125" transform="translate(230.01 76.36)" fill="none" stroke="#231340" strokeWidth="5" />
        </g>
      </g>
    </g>
  </svg>
)