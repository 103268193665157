import React, { useEffect } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../redux/byhorses/actions";
import { Col, Image, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import gmImg from "../../assets/images/byhorse/team/omer-girgin.png"
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { useState } from 'react'
import aboutUsTRImg from "../../assets/images/background/about-us-img.png"
import aboutUsENImg from "../../assets/images/background/about-us-img-en.png"
import asliAkar from "../../assets/images/byhorse/team/asli-akar.png"
import ozanOktay from "../../assets/images/byhorse/team/ozan-oktay.png"
import hakanAysel from "../../assets/images/byhorse/team/hakan-aysel.png"
import melisaBiler from "../../assets/images/byhorse/team/melisa-biler.png"
import batuhanCetin from "../../assets/images/byhorse/team/batuhan-cetin.png"
import sudeTandogdu from "../../assets/images/byhorse/team/sude-tandogdu.png"
import durmusPala from "../../assets/images/byhorse/team/durmus-pala.png"
import ilyasGokcek from "../../assets/images/byhorse/team/ilyas-gokcek.png"
import sezerEmer from "../../assets/images/byhorse/team/sezer-emer.png"
import gokhanUsluer from "../../assets/images/byhorse/team/gokhan-usluer.png"


const StaffCard = (props) => {
  return (
    <Row style={{
      border: "1px solid #FFFFFF",
      borderRadius: 20,
      padding: 10,
      width: '100%',
      height: '100%'
    }}>
      <Col xs={24} align='center'>
        <Image preview={false} src={props.image} style={{ borderRadius: '50%', width: '100%' }} />
        <h2>{props.name}</h2>
        <h3 style={{ color: '#c6017f' }}>{props.title}</h3>
      </Col>
    </Row>
  )
}


const AboutUs = (props) => {
  const { t } = useTranslation()
  const [expandContent, setExpandContent] = useState(false)
  const [language, setLanguage] = useState('')

  // Handle Language
  useEffect(() => {
    let lang = localStorage.getItem('multilang')
    if (!lang) lang = 'tr'
    setLanguage(lang)
  }, [])

  const cLevelStaff = [
    {
      name: "Aslı Akar",
      title: t('translation:cfo'),
      image: asliAkar
    },
    {
      name: "Ozan Oktay",
      title: t('translation:coo'),
      image: ozanOktay
    },
    {
      name: "Hakan Aysel",
      title: t('translation:cto'),
      image: hakanAysel
    },
    {
      name: "M. Melisa Biler",
      title: t('translation:cco'),
      image: melisaBiler
    },
  ]

  const normalStaff = [
    {
      name: "H. Batuhan Çetin",
      title: t('translation:cm'),
      image: batuhanCetin
    },
    {
      name: "Durmuş Pala",
      title: t('translation:softwareDeveloper'),
      image: durmusPala
    },
    {
      name: "İlyas Gökçek",
      title: t('translation:softwareDeveloper'),
      image: ilyasGokcek
    },
    {
      name: "Sezer Emer",
      title: t('translation:softwareDeveloper'),
      image: sezerEmer
    },
    {
      name: "Gökhan Usluer",
      title: t('translation:softwareDeveloper'),
      image: gokhanUsluer
    },
    {
      name: "N. Sude Tandoğdu",
      title: t('translation:graphicDesingner'),
      image: sudeTandogdu
    },
  ]

  const cLevelCards = cLevelStaff.map((staff, index) => (
    <Col xs={24} lg={6} align='center' key={index}>
      <StaffCard {...staff} />
    </Col>
  ))
  const staffCards = normalStaff.map((staff, index) => (
    <Col xs={12} lg={6} align='center' key={index}>
      <StaffCard {...staff} />
    </Col>
  ))

  const handleExpand = () => {
    setExpandContent(!expandContent)
  }

  return (
    <Row>
      <Col xs={{ span: 22, offset: 1 }} lg={{ span: 24, offset: 0 }}>
        <Row>
          <Col xs={24} className='about-us-top' style={{ zIndex: 10, marginTop: 100 }}>
            <Row align='middle'>
              <Col xs={24} lg={{ span: 10, offset: 0 }} align='center'>
                <Image preview={false} src={language === 'tr' ? aboutUsTRImg : aboutUsENImg} width={props.reduxState?.innerWidth > 768 ? 400 : 300} />
              </Col>
              <Col xs={24} lg={{ span: 10, offset: 1 }} align='center'>
                <p style={{ fontSize: 17 }}>{t('translation:aboutUsP1L1')}<br />
                {t('translation:aboutUsP1L2')}<br />
                {t('translation:aboutUsP1L3')}<br /><br />
                {t('translation:aboutUsP2L1')}<br />{t('translation:aboutUsP2L2')} <br />{t('translation:aboutUsP2L3')}<br /><br />
                {t('translation:aboutUsP3L1')}<br />{t('translation:aboutUsP3L2')} <br />{t('translation:aboutUsP3L3')}<br />{t('translation:aboutUsP3L4')}<br /><br />
                {t('translation:aboutUsP4L1')}<br />{t('translation:aboutUsP4L2')} <br />{t('translation:aboutUsP4L3')}
                </p>
              </Col>
            </Row>
          </Col>
          <Col xs={24} lg={{ span: 20, offset: 2 }} style={{ marginTop: 150 }}>
            <Row>
              <Col xs={24} align='center'>
                <h1 style={{ color: '#c6017f' }}>{t('translation:team')}</h1>
              </Col>
              <Col xs={24}>
                <div style={{
                  border: "1px solid #FFFFFF",
                  borderRadius: 20,
                  padding: 20
                }}>
                  <Row style={{ transition: 'height 0.7s' }}>
                    <Col xs={24} lg={8} align='center'>
                      <Image preview={false} src={gmImg} width={250} />
                    </Col>
                    <Col xs={24} lg={16}>
                      <h1>Ömer Faruk Girgin</h1>
                      <h4 style={{ color: '#c6017f', marginTop: '-10px' }}>{t('translation:omerGirginTitle')}</h4>
                      <p>{t('translation:omerGirginP1')}<br /><br />{t('translation:omerGirginP2')}</p>
                      <Row>
                        <Col xs={24} align='center'>
                          <DownOutlined onClick={handleExpand} className='expand-icon' style={{ display: expandContent ? 'none' : 'inline-block' }} />
                        </Col>
                      </Row>
                    </Col>
                    {expandContent &&
                      <Col xs={24} style={{ marginTop: 20 }}>
                        <p style={{ color: '#c6017f' }}>{t('translation:omerGirginP3')}</p>
                        <p>{t('translation:omerGirginP4')}</p>
                        <p>{t('translation:omerGirginP5')}</p>
                        <p>{t('translation:omerGirginP6')}</p>
                        <p>{t('translation:omerGirginP7')}</p>
                        <p>{t('translation:omerGirginP8')}</p>
                        <p>
                          {t('translation:omerGirginJob1')}<br />
                          {t('translation:omerGirginJob2')}<br />
                          {t('translation:omerGirginJob3')}<br />
                          {t('translation:omerGirginJob4')}<br />
                          {t('translation:omerGirginJob5')}<br />
                          {t('translation:omerGirginJob6')}<br />
                          {t('translation:omerGirginJob7')}<br />
                          {t('translation:omerGirginJob8')}<br />
                          {t('translation:omerGirginJob9')}<br />
                        </p>
                        <p>{t('translation:omerGirginP9')}</p>
                        <p>{t('translation:omerGirginP10')}</p>
                        <p>{t('translation:omerGirginP11')}</p>
                        <p>{t('translation:omerGirginP12')}</p>
                        <p>{t('translation:omerGirginP13')}</p>
                        <p>{t('translation:omerGirginP14')}</p>
                        <p style={{ color: '#c6017f' }}>{t('translation:omerGirginP15')}</p>
                        <p>{t('translation:omerGirginP16')}</p>
                        <p>{t('translation:omerGirginP17')}</p>
                        <p>{t('translation:omerGirginP18')}</p>
                        <p>{t('translation:omerGirginP19')}</p>
                        <p>{t('translation:omerGirginP20')}</p>
                        <p>{t('translation:omerGirginP21')}</p>
                        <p>{t('translation:omerGirginP22')}</p>
                        <p>{t('translation:omerGirginP23')}</p>
                        <p>{t('translation:omerGirginP24')}</p>
                        <p>{t('translation:omerGirginP25')}</p>
                        <p>{t('translation:omerGirginP26')}</p>
                        <p>{t('translation:omerGirginP27')}</p>
                        <p>{t('translation:omerGirginP28')}</p>
                        <p>{t('translation:omerGirginP29')}</p>
                        <p>{t('translation:omerGirginP30')}</p>
                        <p>{t('translation:omerGirginP31')}</p>
                        <p>{t('translation:omerGirginP32')}</p>
                        <p>{t('translation:omerGirginP33')}</p>
                        <p>{t('translation:omerGirginP34')}</p>
                        <p>{t('translation:omerGirginP35')}</p>
                        <p>{t('translation:omerGirginP36')}</p>
                        <p>{t('translation:omerGirginP37')}</p>
                        <p>{t('translation:omerGirginP38')}</p>
                        <p>{t('translation:omerGirginP39')}</p>
                        <p>{t('translation:omerGirginP40')}</p>
                        <p>{t('translation:omerGirginP41')}</p>
                        <p>{t('translation:omerGirginP42')}</p>
                        <p>{t('translation:omerGirginP43')}</p>
                        <p>{t('translation:omerGirginP44')}</p>
                        <p>{t('translation:omerGirginP45')}</p>
                        <p>{t('translation:omerGirginP46')}</p>
                        <p style={{ color: '#c6017f' }}>{t('translation:omerGirginP47')}</p>
                      </Col>
                    }
                    <Col xs={24} align='center'>
                      <UpOutlined onClick={handleExpand} className='expand-icon' style={{ display: !expandContent ? 'none' : 'inline-block' }} />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={24} lg={{ span: 20, offset: 2 }}>
                <Row gutter={[24, 24]} style={{ marginTop: 50 }}>
                  {cLevelCards}
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={24} lg={{ span: 18, offset: 3 }} align='center'>
                <Row gutter={[16, 16]} style={{ marginTop: 50 }} justify="center">
                  {staffCards}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(AboutUs));