import React from 'react'
import { Button, Carousel, Col, Image, Row } from 'antd'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../redux/byhorses/actions";
import slide1Img from '../../assets/images/carousel/slider_img_1.png'
import slide2Img from '../../assets/images/carousel/slider_img_2.png'
import slide3Img from '../../assets/images/carousel/slider_img_3.png'
import ReactPlayer from 'react-player'
// import ReactPlayer from 'react-player'


const imgStyle = {
  width: '100%'
}

const RedLine = () => (
  <hr style={{
    width: '10%',
    display: 'inline-flex',
    border: "1px solid #BF027F"
  }} />
)



const titleStyle = {
  color: '#c6017f',
  marginBottom: 40,
  fontWeight: 'normal',
}

const SlideVideo = (props) => {
  return (
    <Row>
      <Col xs={24}>
        <ReactPlayer url={"https://byhorses-api.devtagon.com/media/uploadImage/byHorses_Home_Page_Opener_Mobile.mp4"}
          config={{ file: { attributes: { playsInline: true, }, }, }}
          style={{}} width={"100%"} height={"100%"} playing muted={true} loop={true} />
        <div style={props.innerWidth > 768 ? {
          position: 'absolute',
          bottom: 40,
          left: "4%",
          display: 'flex',
          verticalAlign: "top"
        } : {
          marginTop: "-20px"
        }}>
          <span style={{ fontWeight: 600, fontSize: (props.innerWidth > 768 && props.innerWidth < 1600) ? 34 : (props.innerWidth >= 1600) ? 60 : 16 }}>
            {props.t('translation:homeSliderVideo')}
          </span> &nbsp; &nbsp; &nbsp;
          <Button className='colorful-btn' size='large'
            style={{
              zIndex: 10,
              marginTop: 10,
              fontSize: (props.innerWidth >= 1600) ? 50 : undefined,
              height: (props.innerWidth >= 1600) ? 70 : undefined,
              paddingTop: (props.innerWidth >= 1600) ? 0 : undefined,
            }}>
            {props.t('translation:sliderStartNow')}
          </Button>
        </div>
      </Col>
    </Row>
  )
}

const Slide1 = (props) => {
  return (
    <Row align='middle' className='font-white'>
      <Col xs={24} lg={12} >
        <h1 className={props.innerWidth > 768 ? 'font-2rem' : "font-1rem"} style={titleStyle}>{props.t('translation:homeSlider1Title1')}<br />{props.t('translation:homeSlider1Title2')}</h1>
        <p style={props.paragraphStyle}>
          {props.t('translation:homeSlider1Paragraph1')}<br />
          {props.t('translation:homeSlider1Paragraph2')}
        </p>
        <RedLine />
        <Row align='middle'>
          <Col xs={12} lg={8}>
            <Button className='colorful-btn' style={{ marginTop: 40, zIndex: 10 }} size='large'>{props.t('translation:sliderStartNow')}</Button>
          </Col>
        </Row>
      </Col>
      <Col xs={24} lg={12} align='right'>
        <Image preview={false} src={slide1Img} style={imgStyle} />
      </Col>
    </Row>
  )
}
const Slide2 = (props) => {
  return (
    <Row align='middle' className='font-white'>
      <Col xs={24} lg={12}>
        <h1 className={props.innerWidth > 768 ? 'font-2rem' : "font-1rem"} style={titleStyle}>{props.t('translation:homeSlider2Title1')}<br />{props.t('translation:homeSlider2Title2')}</h1>
        <p style={props.paragraphStyle}>
          {props.t('translation:homeSlider2Paragraph1')}<br />
          {props.t('translation:homeSlider2Paragraph2')}
        </p>
        <RedLine />
        <Row align='middle'>
          <Col xs={12} lg={8}>
            <Button className='colorful-btn' style={{ marginTop: 40 }} size='large'>{props.t('translation:sliderStartNow')}</Button>
          </Col>
        </Row>
      </Col>
      <Col xs={24} lg={12} align='right'>
        <Image preview={false} src={slide2Img} style={imgStyle} />
      </Col>
    </Row>
  )
}
const Slide3 = (props) => {
  return (
    <Row align='middle' className='font-white'>
      <Col xs={24} lg={12}>
        <h1 className={props.innerWidth > 768 ? 'font-2rem' : "font-1rem"} style={titleStyle}>{props.t('translation:homeSlider3Title1')}<br />{props.t('translation:homeSlider3Title2')}</h1>
        <p style={props.paragraphStyle}>
          {props.t('translation:homeSlider3Paragraph1')}<br />
          {props.t('translation:homeSlider3Paragraph2')}
        </p>
        <RedLine />
        <Row align='middle'>
          <Col xs={12} lg={8}>
            <Button className='colorful-btn' style={{ marginTop: 40 }} size='large'>{props.t('translation:sliderStartNow')}</Button>
          </Col>
        </Row>
      </Col>
      <Col xs={24} lg={12} align='right'>
        <Image preview={false} src={slide3Img} style={imgStyle} />
        {/* <ReactPlayer url={"https://byhorses-api.devtagon.com/media/uploadImage/at_video.mov"} style={{marginRight: 30}} playing width={400} muted={true} loop={true} /> */}
        {/* <ReactPlayer url={"https://byhorses-api.devtagon.com/media/uploadImage/HARA_SON.mp4"} style={{marginRight: 30}} playing width={400} muted={true} loop={true} /> */}
      </Col>
    </Row>
  )
}

const HomeCarousel = (props) => {
  const paragraphStyle = {
    fontWeight: 300,
    fontSize: props.reduxState?.innerWidth > 768 ? 22 : 16,
  }
  return (
    <Carousel autoplay={false} autoplaySpeed={5000} swipeToSlide draggable className='carousel-btn'>
      <div>
        <SlideVideo t={props.t} innerWidth={props.reduxState?.innerWidth} paragraphStyle={paragraphStyle} />
      </div>
      <div>
        <Slide1 t={props.t} innerWidth={props.reduxState?.innerWidth} paragraphStyle={paragraphStyle} />
      </div>
      <div>
        <Slide2 t={props.t} innerWidth={props.reduxState?.innerWidth} paragraphStyle={paragraphStyle} />
      </div>
      <div>
        <Slide3 t={props.t} innerWidth={props.reduxState?.innerWidth} paragraphStyle={paragraphStyle} />
      </div>
    </Carousel>
  )
}


const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(HomeCarousel));