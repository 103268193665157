import React from "react";
import { Provider } from "react-redux";
import { configureStore } from "./redux/store";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "../src/assets/css/styles.less"
import PrivateRoute from "./routes/PrivateRoute.js";
import { ConfigProvider } from 'antd';
import trTR from 'antd/lib/locale/tr_TR';
import enUs from 'antd/lib/locale/en_US';
import Page404 from "./pages/error/Page404";
import ScrollToTop from "./routes/ScrollToTop";
import Header from "./components/header/Header";
import Home from "./pages/home/Home";
import Footer from "./components/footer/Footer";
import NftMarketplace from "./pages/nftMarketplace/NftMarketplace";
import NftDetail from "./pages/nftDetail/NftDetail";
import AboutUs from "./pages/aboutUs/AboutUs";
import Contact from "./pages/contact/Contact";
import HorseAndHistory from "./pages/horseAndHistory/HorseAndHistory";
import Roadmap from "./pages/roadmap/Roadmap";
import Account from "./pages/account/Account";
import Sale from "./pages/nftDetail/sale/Sale";
import Faq from "./pages/faq/Faq";
import Tokenomics from "./pages/tokenomics/Tokenomics";
import WhitePaper from "./pages/whitepaper/WhitePaper";
import Game from "./pages/game/Game";
import Byh from "./pages/byh/Byh";
import TermsOfService from "./pages/policies/TermsOfService";
import PrivacyPolicy from "./pages/policies/PrivacyPolicy";
import ClarificationText from "./pages/policies/ClarificationText";
import CookiePolicy from "./pages/policies/CookiePolicy";



const App = () => {

  return (
    <Provider store={configureStore()}>
      <BrowserRouter>
        <ConfigProvider locale={window.localStorage.getItem('multilang') === 'tr' ? trTR : enUs}>
          <ScrollToTop>
            <Header />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/nft-marketplace" component={NftMarketplace} />
              <Route exact path="/nft-marketplace/:id" component={NftDetail} />
              <Route exact path="/nft-marketplace/sell/:id" component={Sale} />
              <Route exact path="/aboutus" component={AboutUs} />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/horse-and-horse-history" component={HorseAndHistory} />
              <Route exact path="/roadmap" component={Roadmap} />
              <Route exact path="/faq" component={Faq} />
              <Route exact path="/tokenomics" component={Tokenomics} />
              <Route exact path="/whitepaper" component={WhitePaper} />
              <Route exact path="/roadmap" component={Roadmap} />
              <Route exact path="/game" component={Game} />
              <Route exact path="/byh" component={Byh} />
              <Route exact path="/terms-of-service" component={TermsOfService} />
              <Route exact path="/privacy-policy" component={PrivacyPolicy} />
              <Route exact path="/kvkk" component={ClarificationText} />
              <Route exact path="/cookie-policy" component={CookiePolicy} />
              <PrivateRoute exact path="/account" component={Account} />
              <Route path="*">
                <Page404 />
              </Route>
            </Switch>
            <Footer />
          </ScrollToTop>
        </ConfigProvider>
      </BrowserRouter>
    </Provider>
  );
}
export default App;
