const CONFIG = [
  {// Local api info
    environment: 'test',
    host: 'localhost:4040',
    apiBase: "https://byhorses-api.devtagon.com/",
    apiBaseClear: "https://byhorses-api.devtagon.com",
    webBase: "http://byhorsesweb.devtagon.com/"
  },
  {// Test api info
    environment: 'test',
    host: 'byhorsesweb.devtagon.com',
    apiBase: "https://byhorses-api.devtagon.com/",
    apiBaseClear: "https://byhorses-api.devtagon.com",
    webBase: "http://byhorsesweb.devtagon.com/"
  },
  {// Prod api info
    environment: 'production',
    host: 'byhorsesweb.devtagon.com',
    apiBase: "https://byhorses-api.devtagon.com/",
    apiBaseClear: "https://byhorses-api.devtagon.com",
    webBase: "http://byhorsesweb.devtagon.com/"
  }
]

const getConfig = () => {
  const parsedUrl = new URL(window.location.href);
  const host = parsedUrl.host
  const config = CONFIG.filter(data => data.host === host)[0]
  return config
}


export const getApiBase = () => {
  const config = getConfig()
  return config.apiBase
}
export const getApiBaseClear = () => {
  const config = getConfig()
  return config.apiBaseClear
}
export const getWebBase = () => {
  const config = getConfig()
  return config.webBase
}
export const getEnvironment = () => {
  const config = getConfig()
  return config.environment
}