/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import { Col, Row, Space } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

const HorseAndHistory = () => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState(0)

  const titles = [
    t('translation:horseAndHistoryTitle1'),
    t('translation:horseAndHistoryTitle2'),
    t('translation:horseAndHistoryTitle3'),
    t('translation:horseAndHistoryTitle4'),
    t('translation:horseAndHistoryTitle5'),
    t('translation:horseAndHistoryTitle6'),
    t('translation:horseAndHistoryTitle7'),
    t('translation:horseAndHistoryTitle8'),
    t('translation:horseAndHistoryTitle9'),
  ]

  const handleChangeSelected = (selected) => {
    setSelected(selected)
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    } catch (error) {
      // for older browser
      window.scrollTo(0, 0)
    }
  }

  const titleButtons = titles.map((title, index) => (
    <div key={index} onClick={() => handleChangeSelected(index)}
      className={selected === index ? 'horse-history-btn horse-history-selected' : 'horse-history-btn'}>{title}</div>
  ))

  const handlePage = (page) => {
    setSelected(7 + page)
  }

  return (
    <div className={selected === 9 ? `horse-history-index8-2-bg` : `horse-history-index${selected}-bg`}>
      <Row>
        <Col xs={24} lg={{ span: 20, offset: 2 }}>
          <Row gutter={[24, 16]}>
            <Col xs={24} align='center' style={{ marginTop: 20, marginBottom: 20 }}>
              <h1>{t('translation:horseAndHistory')}</h1>
            </Col>
            <Col xs={24} lg={10}>
              {titleButtons}
            </Col>
            <Col xs={{ span: 22, offset: 1 }} lg={13} style={{ marginTop: 6 }}>
              {selected === 0 ?
                <Index0 t={t} /> :
                selected === 1 ?
                  <Index1 t={t} /> :
                  selected === 2 ?
                    <Index2 t={t} /> :
                    selected === 3 ?
                      <Index3 t={t} /> :
                      selected === 4 ?
                        <Index4 t={t} /> :
                        selected === 5 ?
                          <Index5 t={t} /> :
                          selected === 6 ?
                            <Index6 t={t} /> :
                            selected === 7 ?
                              <Index7 t={t} /> :
                              selected === 8 || selected === 9 ?
                                <Index8 t={t} page={handlePage} /> : null
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

const minHeight = 410

const Index0 = (props) => (
  <div>
    <p>
      {props.t('translation:horseAndHistoryTitle1P1')}
      <br /><br />
      {props.t('translation:horseAndHistoryTitle1P2')}
      <br /><br />
      {props.t('translation:horseAndHistoryTitle1P3')}
      <br /><br />
      {props.t('translation:horseAndHistoryTitle1P4')}
      <br /><br />
      {props.t('translation:horseAndHistoryTitle1P5')}
      <br /><br />
      {props.t('translation:horseAndHistoryTitle1P6L1')}<br />
      {props.t('translation:horseAndHistoryTitle1P6L2')}<br />
      {props.t('translation:horseAndHistoryTitle1P6L3')}<br />
      {props.t('translation:horseAndHistoryTitle1P6L4')}<br />
      {props.t('translation:horseAndHistoryTitle1P6L5')}<br />
      {props.t('translation:horseAndHistoryTitle1P6L6')}<br />
      {props.t('translation:horseAndHistoryTitle1P6L7')}<br />
      {props.t('translation:horseAndHistoryTitle1P6L8')}<br />
      <br /><br />
      {props.t('translation:horseAndHistoryTitle1P7')}

    </p>
  </div>
)
const Index1 = (props) => (
  <div>
    <h2>HYRACOTHERIUM / HOHIPPUS</h2>
    <p>{props.t('translation:horseAndHistoryTitle2P1')}</p><br />
    <h2>OROHİPPUS</h2>
    <p>{props.t('translation:horseAndHistoryTitle2P2')}</p><br />
    <h2>MESOHIPPUS</h2>
    <p>{props.t('translation:horseAndHistoryTitle2P3')}</p><br />
    <h2>MIOHIPPUS</h2>
    <p>{props.t('translation:horseAndHistoryTitle2P4')}</p><br />
  </div>
)
const Index2 = (props) => (
  <div>
    <h2>KALOBATIPPUS</h2>
    <p>{props.t('translation:horseAndHistoryTitle3P1')}</p><br />
    <h2>ANCHITHERIUM</h2>
    <p>{props.t('translation:horseAndHistoryTitle3P2')}</p><br />
    <h2>ARCHAEOHIPPUS</h2>
    <p>{props.t('translation:horseAndHistoryTitle3P3')}</p><br />
    <h2>HYPOHIPPUS</h2>
    <p>{props.t('translation:horseAndHistoryTitle3P4')}</p><br />
    <h2>MEGAHIPPUS</h2>
    <p>{props.t('translation:horseAndHistoryTitle3P5')}</p><br />
  </div>
)
const Index3 = (props) => (
  <div>
    <p>{props.t('translation:horseAndHistoryTitle4P1')}</p><br />
    <h2>Arahippus</h2><br />
    <p>{props.t('translation:horseAndHistoryTitle4P2')}</p><br />
    <h2>Merychippus</h2><br />
    <p>{props.t('translation:horseAndHistoryTitle4P3')}</p>
  </div>
)
const Index4 = (props) => (
  <div>
    <p>{props.t('translation:horseAndHistoryTitle5P1')}</p><br />
    <h2>HIPPARION</h2><br />
    <p>{props.t('translation:horseAndHistoryTitle5P2')}</p><br />
    <h2>PROTOHIPPIN</h2><br />
    <p>{props.t('translation:horseAndHistoryTitle5P3')}</p><br />
    <h2>{props.t('translation:horseAndHistoryTitle5L3')}</h2><br />
    <p>{props.t('translation:horseAndHistoryTitle5P4')}</p><br />
  </div>
)
const Index5 = (props) => (
  <div>
    <p>{props.t('translation:horseAndHistoryTitle6P1')}</p>
    <br />
    <h2>PLIOHIPPUS</h2><br />
    <p>{props.t('translation:horseAndHistoryTitle6P2')}</p>
    <h2>ASTROHIPPUS</h2><br />
    <p>{props.t('translation:horseAndHistoryTitle6P3')}</p>
    <h2>{props.t('translation:horseAndHistoryTitle6L3')}</h2><br />
    <p>{props.t('translation:horseAndHistoryTitle6P4')}</p>
    <h2>NEOHIPPARION</h2><br />
    <p>{props.t('translation:horseAndHistoryTitle6P5')}</p>
    <h2>{props.t('translation:horseAndHistoryTitle6L5')}</h2><br />
    <p>
      {props.t('translation:horseAndHistoryTitle6P6')}
      <br />
      <br />
      {props.t('translation:horseAndHistoryTitle6P7')}
      <br />
      <br />
      {props.t('translation:horseAndHistoryTitle6P8')}
      <br />
      <br />
      {props.t('translation:horseAndHistoryTitle6P9')}
      <br />
      <br />
      {props.t('translation:horseAndHistoryTitle6P10')}
    </p>
  </div>
)
const Index6 = (props) => (
  <div>
    <p>
      {props.t('translation:horseAndHistoryTitle7P1')}
    </p>
  </div>
)
const Index7 = (props) => {
  return (
    <Row gutter={[24, 24]}>
      <Col xs={24} style={{ minHeight: minHeight }}>
        <p>
          {props.t('translation:horseAndHistoryTitle8P1')}
          <br /><br />
          {props.t('translation:horseAndHistoryTitle8P2')}
          <br /><br />
          {props.t('translation:horseAndHistoryTitle8P3')}
          <br /><br />
          {props.t('translation:horseAndHistoryTitle8P4')}
          <br /><br />
          {props.t('translation:horseAndHistoryTitle8P5')}
        </p>
        <p>
          {props.t('translation:horseAndHistoryTitle8P6')}
          <br /><br />
          {props.t('translation:horseAndHistoryTitle8P7')}
        </p>
      </Col>
    </Row>
  )
}
const Index8 = (props) => {
  const [selectedPage, setSelectedPage] = useState(1)
  const handleChangePage = (page) => {
    props.page(page)
    setSelectedPage(page)
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    } catch (error) {
      // for older browser
      window.scrollTo(0, 0)
    }
  }
  const pStyle = {
    lineHeight: 1.5
  }

  return (
    <Row gutter={[24, 24]}>
      <Col xs={24} style={{ minHeight: minHeight }}>
        {selectedPage === 1 ?
          <Row>
            <Col xs={12}>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P1')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P2')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P3')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P4')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P5')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P6')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P7')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P8')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P9')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P10')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P11')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P12')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P13')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P14')}</p>
            </Col>
            <Col xs={12}>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P15')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P16')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P17')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P18')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P19')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P20')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P21')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P22')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P23')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P24')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P25')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P26')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P27')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P28')}</p>
            </Col>
          </Row>
          :
          <Row>
            <Col xs={12}>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P29')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P30')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P31')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P32')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P33')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P34')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P35')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P36')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P37')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P38')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P39')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P40')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P41')}</p>
            </Col>
            <Col xs={12}>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P42')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P43')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P44')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P45')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P46')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P47')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P48')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P49')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P50')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P51')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P52')}</p>
              <p style={pStyle}>{props.t('translation:horseAndHistoryTitle9P53')}</p>
            </Col>
          </Row>
        }
      </Col>
      <Col xs={24} align='center' style={{ marginBottom: 20 }}>
        <Space>
          <LeftOutlined style={{ cursor: 'pointer', visibility: selectedPage === 1 && "hidden" }} onClick={() => handleChangePage(1)} />
          <div className={selectedPage === 1 ? 'pagination-style selected-pagination' : 'pagination-style'}
            onClick={() => handleChangePage(1)}>1</div>
          <div className={selectedPage === 2 ? 'pagination-style selected-pagination' : 'pagination-style'}
            onClick={() => handleChangePage(2)}>2</div>
          <RightOutlined style={{ cursor: 'pointer', visibility: selectedPage === 2 && "hidden" }} onClick={() => handleChangePage(2)} />
        </Space>
      </Col>
    </Row>
  )
}

export default HorseAndHistory