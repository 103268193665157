import React from 'react'
import { Button, Col, Image, Row } from 'antd'
import image from "../../assets/images/byhorse/home_nft_fotofinish.png"
import { useHistory } from 'react-router-dom'


const imgStyle = {
  width: '100%'
}

const titleStyle = {
  marginBottom: 40,
  fontWeight: 600,
}


const HomeFotofinishNft = (props) => {
  const history = useHistory()
  const paragraphStyle = {
    fontWeight: 300,
    fontSize: props.innerWidth > 768 ? 24 : 15,
    whiteSpace: "nowrap",
  }

  const handleRoute = () => {
    history.push("nft-marketplace?nft=photofinish")
  }

  return (
    <Row align='middle' className='font-white reveal'>
      {props.innerWidth < 768 &&
        <Col xs={24} lg={12} align='center'>
          <Image preview={false} src={image} style={imgStyle} />
        </Col>
      }
      <Col xs={24} lg={12} >
        <h1 className='font-3rem' style={titleStyle}>{props.t('translation:photofinishes')}</h1>
        <Row style={paragraphStyle}>
          <Col xs={24}>
            {props.t('translation:photofinishNftParagraph1')}
          </Col>
          <Col xs={24}>
            {props.t('translation:photofinishNftParagraph2')}
          </Col>
          <Col xs={24}>
            {props.t('translation:photofinishNftParagraph3')}
          </Col>
          <Col xs={24}>
            {props.t('translation:photofinishNftParagraph4')}
          </Col>
          <Col xs={24}>
            {props.t('translation:photofinishNftParagraph5')}
          </Col>
          <Col xs={24}>
            {props.t('translation:photofinishNftParagraph6')}
          </Col>
        </Row>
        <Row align='middle'>
          <Col xs={12} lg={8}>
            <Button className='colorful-btn' style={{ marginTop: 40, zIndex: 10 }} size='large' onClick={handleRoute}>{props.t('translation:discoverNFTs')}</Button>
          </Col>
        </Row>
      </Col>

      {props.innerWidth >= 768 &&
        <Col xs={24} lg={12} align='center'>
          <Image preview={false} src={image} style={imgStyle} />
        </Col>
      }
    </Row>
  )
}

export default HomeFotofinishNft