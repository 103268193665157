import React from 'react'
import { Button, Col, Image, Row } from 'antd'
import image from "../../assets/images/byhorse/home_nft_horse.gif"
import { useHistory } from 'react-router-dom'


const imgStyle = {
  width: '80%',
  marginRight: 15
}

const titleStyle = {
  marginBottom: 40,
  fontWeight: 600,
}

const HomeHorseNft = (props) => {
 const history = useHistory()
  const paragraphStyle = {
    fontWeight: 300,
    fontSize: props.innerWidth > 768 ? 24 : 15,
    whiteSpace: "nowrap",
  }

  const handleRoute = () => {
    history.push("nft-marketplace?nft=horse")
  }

  return (
    <Row align='middle' className='font-white reveal'>
      <Col xs={24} lg={12} align='center'>
        <Image preview={false} src={image} style={imgStyle} />
      </Col>
      <Col xs={24} lg={12} >
        <h1 className={'font-3rem'} style={titleStyle}>{props.t('translation:horses')}</h1>
        <Row style={paragraphStyle}>
          <Col xs={24}>
          {props.t('translation:horseNftParagraph1')}
          </Col>
          <Col xs={24}>
          {props.t('translation:horseNftParagraph2')}
          </Col>
          <Col xs={24}>
          {props.t('translation:horseNftParagraph3')}
          </Col>
          <Col xs={24}>
          {props.t('translation:horseNftParagraph4')}
          </Col>
        </Row>
        <Row align='middle'>
          <Col xs={12} lg={8}>
            <Button className='colorful-btn' style={{ marginTop: 40, zIndex: 10 }} size='large' onClick={handleRoute}>{props.t('translation:discoverNFTs')}</Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default HomeHorseNft