import { notification } from 'antd';


export const showNotification = ({type, title, description, style, icon, duration, className}) => {
    notification[type]({
        message: title,
        description: description,
        className: className || undefined,
        style: style || undefined,
        icon: icon || undefined,
        duration: duration || undefined
    });
};
