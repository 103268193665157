import { publicApi } from '../../services/axios'

export const getHorseDetail = async ({ id, cancelToken }) => {
  try {
    const response = await publicApi.get(`management/horse/${id}`, { cancelToken: cancelToken });
    return response;
  } catch (error) {
    return error.response;
  }
}
export const getJockeyDetail = async ({ id, cancelToken }) => {
  try {
    const response = await publicApi.get(`management/jockey/${id}`, { cancelToken: cancelToken });
    return response;
  } catch (error) {
    return error.response;
  }
}