import React from 'react'
import { Col, Row } from 'antd'

const Policy = (props) => {
  return (
    <Row>
      <Col xs={24} align='center'>
        <h1>{props.policyName}</h1>
      </Col>
      <Col xs={24}>
        <div className='black-text' style={{
          backgroundColor: "#FFFFFF",
          padding: 48,
        }}>
          {props.policy}
        </div>
      </Col>
    </Row>
  )
}

export default Policy