import { ClockCircleOutlined } from "@ant-design/icons";
import { notification } from "antd";

export const comingSoon = (t) => {
  notification.open({
      message: t('translation:comingSoon'),
      description: t('translation:comingSoonDesc'),
      icon: <ClockCircleOutlined style={{ color: '#472682' }} />,
      duration: 2
  });
};