import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { setRedux } from "../../redux/byhorses/actions";
import { Card, Col, Image, Row } from 'antd'
import Fav from './Fav';
import { t } from 'i18next';
const normalStyle = {
  borderTop: '1px solid #FFFFFF',
  paddingTop: 5,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  transition: 'all 0.4s',
  height: 40
}
const hoverStyle = {
  borderTop: '1px solid #C6017F',
  paddingTop: 5,
  paddingBottom: 0,
  paddingLeft: 0,
  paddingRight: 0,
  borderRadius: 20,
  // borderBottomLeftRadius: 20,
  // borderBottomRightRadius: 20,
  backgroundColor: '#C6017F',
  transition: 'all 0.4s',
  height: 40,
  cursor: 'pointer'
}

const NftMarketplaceCard = (props) => {
  const [isMouseEnter, setIsMouseEnter] = useState(false)

  const handleClickToCard = () => {
    props.onClick()
  }

  return (
    <>
      <Card className='card-white-border paddingless-card'
        style={{ backgroundColor: 'transparent', borderRadius: 20, height: '100%' }} hoverable onClick={handleClickToCard}>
        <Row>
          <Col xs={{ span: 20, offset: 2 }} align='left'>
            <Image preview={false} src={props.data.image} style={{ minHeight: 150 }} />
            <span style={{ color: '#c6017f' }}>{t(`translation:rare`)}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span>#{props.data.id}</span>
            <p style={{ marginTop: 10 }}>{props.data.type_of_nft === 1 ? props.data.name : props.data.type_of_nft === 2 ? (props.data.firstName + " " + props.data.lastName) : null}</p>
          </Col>
          <Col className='hover-text-align' xs={isMouseEnter ? 24 : { span: 20, offset: 2 }} style={isMouseEnter ? hoverStyle : normalStyle}
            onMouseEnter={(event) => setIsMouseEnter(true)} onMouseLeave={() => setIsMouseEnter(false)}>
            <h4 style={isMouseEnter ? { fontSize: 18, fontWeight: 700 } : undefined}>{isMouseEnter ? t(`translation:buy`) : (props.data.price ? props.data.price + " eth" : "0 eth")}</h4>
          </Col>
        </Row>
      </Card>
      <Fav data={props.data} isCard={true} />
    </>
  )
}

const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(NftMarketplaceCard));