/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../redux/byhorses/actions";
import { Button, Col, Dropdown, Image, Menu, Modal, Row } from 'antd'
import metamask from "../../assets/images/icons/metamask.png"
import walletConnect from "../../assets/images/icons/wallet-connect.png"
import { useTranslation } from 'react-i18next'
import { comingSoon } from '../../utils/ComingSoon'
import { showNotification } from '../../utils/showNotification';
import Web3 from 'web3';
import { isConnected } from '../../contract/utils';
import { logIn, signUp } from './api';

const WalletCard = (props) => {
  return (
    <div className='connect-wallet-card' onClick={props.onClick} id={props.id}>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Image preview={false} width={75} src={props.image} />
        </Col>
        <Col xs={24} align='center'>
          <span>{props.walletName}</span>
        </Col>
      </Row>
    </div>
  )
}

const ConnectWallet = (props) => {
  const { t } = useTranslation()

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [account, setAccount] = useState(null)

  const closeModal = () => setIsModalVisible(false)
  const openModal = () => setIsModalVisible(true)

  useEffect(() => {
    const checkConnection = async () => {
      const isConnected_ = await isConnected()
      const account_ = window.localStorage.getItem('account')
      if (isConnected_ && account_) {
        setAccount(account_)
        LoginProccess({metamaskAccount: account_})
      } else {
        window.localStorage.removeItem("account")
      }
    }

    checkConnection()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const connectMetamask = async () => {
    if (window.ethereum && window.ethereum.isMetaMask) {
      try {
        const web3 = new Web3(Web3.givenProvider || "http://localhost:4040/");
        const accounts = await web3.eth.requestAccounts();
        window.localStorage.setItem("account", accounts[0])
        setAccount(accounts[0])
        LoginProccess({metamaskAccount: accounts[0]})
        closeModal()
      } catch (ex) {
        console.log('CONNECT EX : ', ex)
        showNotification({
          type: 'error',
          title: 'Something Went Wrong!',
          description: "Couldn't connect to metamask"
        })
      }
    } else {
      if (props.reduxState?.innerWidth < 600) {
        showNotification({
          type: 'error',
          title: 'Not Metamask Browser!',
          description: 'You need to open webpage in metamask application'
        })
      } else {
        showNotification({
          type: 'error',
          title: 'Not Installed!',
          description: 'You need to install metamask first!'
        })
      }
    }
  }

  const disconnectMetamask = async () => {
    try {
      // const web3 = new Web3(Web3.givenProvider || "http://localhost:4040/");
      // await web3.eth.currentProvider.disconnect();
      window.localStorage.removeItem("account")
      setAccount(null)
    } catch (ex) {
      console.log('CONNECT EX : ', ex)
    }
  }

  const showAccount = () => {
    const first = account.slice(0, 4)
    const last = account.slice(-4)
    return first + "...." + last
  }

  const register = async ({metamaskAccount}) => {
    const payload = {
      username: metamaskAccount,
      email: `${metamaskAccount}@byhorses.com`,
      password1: metamaskAccount,
      password2: metamaskAccount,
      userType: 2,
    }

    await signUp(payload)
    // Register sonrası direkt logine istek çık.
    // Zaten kayıtlı kullanıcı ise 400 dönecek ve login sürecine devam edecek.
    // Kullanıcıyı kaydederse de 201 dönecek ve login'e devam edecek.
    login({metamaskAccount: metamaskAccount})

    
  }
  const login = async ({metamaskAccount}) => {
    const payload = {
      username: metamaskAccount,
      email: `${metamaskAccount}@byhorses.com`,
      password: metamaskAccount,
      requestType: 2,
    }

    const response = await logIn(payload) 

    const user = {
      access_token: response.data.access_token,
      refresh_token: response.data.refresh_token,
      id: response.data.user.id
    }
    window.localStorage.setItem('user', JSON.stringify(user))
    props.setRedux({user: user})

  }

  const LoginProccess = ({metamaskAccount}) => {
    const user = JSON.parse(window.localStorage.getItem('user'))
    if (user) {
      login({metamaskAccount: metamaskAccount})
    } else {
      register({metamaskAccount: metamaskAccount})
    }
  }

  return (
    <>
      {account ?
        // <Dropdown trigger={props.mobile ? ['click'] : undefined} overlay={<Menu className='wallet-dropdown'
        //   items={[
        //     {
        //       key: '1',
        //       label: (
        //         <div onClick={disconnectMetamask}>
        //           {t('translation:disconnectWallet')}
        //         </div>
        //       ),
        //     },
        //   ]}
        // />} placement="bottom">
        // </Dropdown>
        <Button style={{ backgroundColor: '#FFFFFF' }} size='large'><span style={{ color: '#C6017F' }}>{showAccount()}</span></Button>
        :
        <Button id={"metamask-btn"} style={{ backgroundColor: '#FFFFFF' }} size='large' onClick={connectMetamask}>
          <span style={{ color: '#C6017F' }}>{t('translation:connectWallet')}</span>
        </Button>
      }

      <Modal title={''} className='connect-wallet-modal'
        visible={isModalVisible} onOk={closeModal} onCancel={closeModal} footer={false}>
        <Row gutter={[24, 24]}>
          <Col xs={24} align='center'>
            <span className='font-1rem'>{t('translation:connectWallet')}</span>
          </Col>
          <Col xs={24} lg={12} align='center'>
            <WalletCard image={metamask} walletName={"Metamask"} onClick={connectMetamask} />
          </Col>
          <Col xs={24} lg={12} align='center'>
            <WalletCard image={walletConnect} walletName={"WalletConnect"} onClick={() => comingSoon(t)} />
          </Col>
        </Row>
      </Modal>
    </>
  )
}

const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(ConnectWallet));