import React from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../redux/byhorses/actions";
import { Dropdown, Image, Menu } from 'antd'
import { SettingOutlined, StarFilled, UserOutlined } from '@ant-design/icons'
import { FiLayers, FiLogOut } from 'react-icons/fi'
import profileIcon from "../../assets/images/icons/header-profile-icon.png";
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isConnected } from '../../contract/utils';
import { handleLogOut } from '../../utils/Authentication';

const ProfileButton = (props) => {
  const [isUserCreated, setIsUserCreated] = useState(false)
  const history = useHistory()
  const { t } = useTranslation()

  const routeAccount = (tab) => {
    history.push(`/account`, { accountTab: tab })
  }

  useEffect(() => {
    const user_ = window.localStorage.getItem('user')
    const checkConnection = async () => {
      const isConnected_ = await isConnected()
      if (isConnected_ && user_) {
        setIsUserCreated(true)
      }
    }

    checkConnection()
  }, [props.reduxState.user])

  const handleClick = () => {
    const connectWalletBtn = document.getElementById("metamask-btn");
    connectWalletBtn.click()
  }

  


  const menu = (
    <Menu
      items={[
        {
          key: '1',
          label: (
            <div className='profile-icon-menu-container'>
              <UserOutlined style={{ fontSize: 22, color: "#341C61" }} />&nbsp;&nbsp;&nbsp;
              <span className='profile-icon-menu-label'>{t('translation:profile')}</span>
            </div>
          ),
          onClick: () => routeAccount("1")
        },
        {
          key: '2',
          label: (
            <div className='profile-icon-menu-container'>
              <StarFilled style={{ fontSize: 22, color: "#341C61" }} />&nbsp;&nbsp;&nbsp;
              <span className='profile-icon-menu-label'>{t('translation:favorites')}</span>
            </div>
          ),
          onClick: () => routeAccount("2")
        },
        {
          key: '3',
          label: (
            <div className='profile-icon-menu-container'>
              <FiLayers style={{ fontSize: 22, color: "#341C61" }} />&nbsp;&nbsp;&nbsp;
              <span className='profile-icon-menu-label'>{t('translation:collection')}</span>
            </div>
          ),
          onClick: () => routeAccount("3")
        },
        {
          key: '4',
          label: (
            <div className='profile-icon-menu-container'>
              <SettingOutlined style={{ fontSize: 22, color: "#341C61" }} />&nbsp;&nbsp;&nbsp;
              <span className='profile-icon-menu-label'>{t('translation:settings')}</span>
            </div>
          ),
          onClick: () => routeAccount("4")
        },
        {
          key: '5',
          label: (
            <div className='profile-icon-menu-container'>
              <FiLogOut style={{ fontSize: 22, color: "#341C61" }} />&nbsp;&nbsp;&nbsp;
              <span className='profile-icon-menu-label'>{t('translation:logOut')}</span>
            </div>
          ),
          onClick: handleLogOut
        },
      ]}
    />
  );
  if (props.reduxState.innerWidth > 768) {
    if (isUserCreated) {
      return (
        <Dropdown overlay={menu} placement="bottom" trigger={["click"]}>
          <Image preview={false} src={profileIcon} width={30} style={{ cursor: "pointer" }} />
        </Dropdown>
      )
    }
    return (
      <Image preview={false} src={profileIcon} width={30} style={{ cursor: "pointer" }} onClick={handleClick} />
    )
  }

}


const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(ProfileButton));