import { InfoCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import React from 'react'

const Info = (props) => {
  return (
    <Tooltip placement={props.placement} title={props.title} color={props.color || undefined}>
      <InfoCircleOutlined style={props.iconStyle} />
    </Tooltip>
  )
}

export default Info