import React, { useEffect } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../../redux/byhorses/actions";
import { Button, Col, Image, Modal, Row, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { FaEthereum } from 'react-icons/fa';
import { useState } from 'react';


const spanStyle = {
  color: "#231340",
  fontWeight: 500,
  fontSize: 16
}


const SaleModal = (props) => {
  const [step, setStep] = useState(1)


  useEffect(() => {
    if(props.visible){
      setTimeout(() => {
        setStep(2)
      },3000)
    }
  }, [props.visible])

  return (
    <>
      <Modal title={''} width={props.reduxState?.innerWidth > 768 ? (step !== 2 ? "50%" : "35%") : "99%"} className='purchase-modal'
        visible={props.visible} onOk={props.onOk} onCancel={props.onCancel} footer={false}>
        {step !== 2 ?
          <>
            <Row align='middle'>
              {/* <Col xs={6}>
                <ArrowLeftOutlined style={{ fontSize: 22 }} />
              </Col> */}
              <Col xs={24} align='center'>
                <span style={{ fontSize: 22, fontWeight: 500, color: "#231340" }}>{props.t('translation:listing')}</span>
              </Col>
            </Row>
            <Row style={{ marginTop: 30, paddingBottom: 8, borderBottom: "1px solid #231340" }}>
              <Col xs={12} align='left'>
                <span style={spanStyle}>{props.t('translation:product')}</span>
              </Col>
              <Col xs={12} align='right'>
                <span style={spanStyle}>{props.t('translation:total')}</span>
              </Col>
            </Row>
            <Row gutter={[24, 24]} align='middle' style={{ marginTop: 8, paddingBottom: 8, borderBottom: "1px solid #231340" }}>
              <Col xs={6} lg={4}>
                <Image preview={false} style={{ borderRadius: 8 }} width={props.reduxState?.innerWidth > 768 ? undefined : 75} 
                src={props.image || "https://dummyimage.com/400x400/c756c7/0011ff.png&text=."} />
              </Col>
              <Col xs={12} lg={16}>
                <h1 style={{ fontSize: props.reduxState?.innerWidth > 768 ? 28 : 22, color: "#231340", fontWeight: 500, lineHeight: 0.5 }}>JIGGLYPUFF</h1>
                <h4 style={{ fontSize: 14, color: "#C6017F", fontWeight: 500, lineHeight: 1 }}>Koleksiyon Adı</h4>
              </Col>
              <Col xs={6} lg={4} align='right'>
                <h1 style={{ fontSize: 14, color: "#231340", fontWeight: 600, lineHeight: 0.5 }}><FaEthereum /> 0.064</h1>
                <h4 style={{ fontSize: 14, color: "#231340", fontWeight: 400, lineHeight: 1 }}>$68.86</h4>
              </Col>
            </Row>
            <Row gutter={[24, 24]} style={{ marginTop: 20 }} align='top'>
                  <Col xs={24}>
                    <h1 style={{ fontSize: 22, fontWeight: 600, color: "#231340" }}>{props.t('translation:listingConfirmation')}</h1>
                    <p style={{ color: "#231340", fontSize: 12 }}>{props.t('translation:listingConfirmationParagraph')}</p>
                    <div style={{
                      borderRadius: 8,
                      padding: 8,
                      display: "flex",
                      justifyContent: "space-between",
                      width: 190,
                      backgroundColor: "#D9D8D9",
                    }}>
                      <span style={{ color: "#453F4E", fontWeight: 600, fontSize: 16 }}>{props.t('translation:awaitingApproval')}</span>
                      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                    </div>
                  </Col>
                </Row>
          </>
          :
          <>
            <Row align='middle'>
              <Col xs={6}>
                {/* <ArrowLeftOutlined style={{ fontSize: 22 }} /> */}
              </Col>
              <Col xs={12} align='center'>
                <span style={{ fontSize: 22, fontWeight: 500, color: "#231340" }}>{props.t('translation:productListed')}</span>
              </Col>
            </Row>
            <Row gutter={[24, 24]} style={{ marginTop: 24 }} >
              <Col xs={24} align='center'>
                <Image preview={false} style={{ borderRadius: 8 }} width={180} src={props.image || "https://dummyimage.com/400x400/c756c7/0011ff.png&text=."} />
              </Col>
              <Col xs={24} align='center'>
                <span style={{ color: "#C6017F", fontWeight: 500 }}>Koleksiyon Adı </span>
                <span style={{ color: "#000" }}>koleksiyonundan listelediğiniz </span>
                <span style={{ color: "#C6017F", fontWeight: 500 }}>JIGGLYPUFF</span>
              </Col>
              <Col xs={24} align='center'>
                <span style={{ color: "#000", fontWeight: 600 }}>{props.t('translation:transactionId')}</span><br />
                <span style={{ color: "#C6017F", fontWeight: 500 }}>TX HASH</span>
              </Col>
              <Col xs={24} align='center'>
                <Button type="primary" size='large' style={{ fontSize: 22, padding: "0px 24px 0px 24px" }}>{props.t('translation:nftMarketplace')}</Button>
              </Col>
            </Row>
          </>
        }
      </Modal>
    </>
  )
}



const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(SaleModal));
