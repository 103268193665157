import React from 'react'
import { Col, Image, Row } from 'antd'
import solana from '../../assets/images/icons/solana.png';
import byhorses from '../../assets/images/byhorse/HorseCoin.png';
import metamask from '../../assets/images/icons/metamaskTurqoise.png';

const HomeCoins = (props) => {

  const icons = [byhorses, solana, metamask]

  const renderIcons = icons.map((img, i) => (
    <Col xs={8} key={i} align='center'>
      <Image key={i} preview={false} src={img} width={100} />
    </Col>
  )
  )

  return (
    <Row align='center' gutter={[8, 24]} className='card-shadow reveal' style={{marginBottom: 100, backgroundColor: '#231340', borderRadius: 20, padding: '10px 10px'}}>
      {renderIcons}
    </Row>
  )
}

export default HomeCoins