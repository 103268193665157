import React from 'react'
import { Col, Image, Row, Space } from 'antd'
import logo from '../../assets/images/byhorses-logo/pink-logo.png'
import coin from '../../assets/images/byhorse/HorseCoin.png'
import twitter from '../../assets/images/icons/twitter.png'
import telegram from '../../assets/images/icons/telegram.png'
import instagram from '../../assets/images/icons/instagram.png'
import medium from '../../assets/images/icons/medium.png'
import tiktok from '../../assets/images/icons/tiktok.png'
import { useTranslation } from 'react-i18next'
import SelectLanguage from '../header/SelectLanguage'

const MobileFooter = (props) => {
  const { t } = useTranslation()


  const iconWidth = 25

  const titleStyle = {
    color: '#c6017f'
  }

  return (
    <>
      <div className='p-20' style={{ paddingBottom: 10, marginTop: 30 }}>
        <Row>
          <Col xs={24} lg={{ span: 20, offset: 2 }}>
            <Row gutter={[24, 24]} justify="center">
              <Col xs={24} align='center'>
                <h4 style={titleStyle}>{t('translation:aboutUs')}</h4>
                <a href='/aboutus'>{t('translation:team')}</a><br />
                <a href='/faq'>{t('translation:faq')}</a><br />
                <a href='/horse-and-horse-history'>{t('translation:horseAndHorseHistory')}</a><br />
                <a href='/contact'>{t('translation:contactUs')}</a><br />
              </Col>
              <Col xs={24} align='center'>
                <h4 style={titleStyle}>byHorses</h4>
                <a href='/whitepaper'>{t('translation:whitepaper')}</a><br />
                <a href='/roadmap'>{t('translation:roadmap')}</a><br />
                <a href='/nft-marketplace'>{t('translation:marketplace')}</a><br />
                <a href='/tokenomics'>{t('translation:tokenomics')}</a>
              </Col>
              <Col xs={24} align='center'>
                <h4 style={titleStyle}>{t('translation:smartContracts')}</h4>
                <a href='/' target={"_blank"}>Github</a><br />
              </Col>
              {/* <Col align='left'>
                <span className='link-on-white' style={{ fontSize: 12 }}>{t('translation:confidentialityAgreement')}</span>
              </Col>
              <Col align='left'>
                <span className='link-on-white' style={{ fontSize: 12 }}>{t('translation:privacyPolicy')}</span>
              </Col>
              <Col xs={24} align='center'>
                <span className='link-on-white'>{t('translation:cookiePolicy')}</span>
              </Col>
              <Col xs={24} align='center'>
                <span className='link-on-white'>{t('translation:clarificationText')}</span>
              </Col> */}
              <Col xs={12} align="right">
                <Image preview={false} src={logo} width={80} style={{ marginTop: '50%' }} />
              </Col>
              <Col xs={12} align={'left'}>
                <Image preview={false} src={coin} width={80} style={{ marginTop: '50%' }} />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <Row style={{ backgroundColor: '#FFFFFF', fontSize: 12, fontWeight: 600, paddingTop: 6, paddingBottom: 6 }} justify="center">
        <Col xs={24} align='center'>
          <Space>
            <a target={"_blank"} href='https://twitter.com/byhorsesgame?s=21&t=FUAGW8MgvQedJ6-YB0N87Q' rel="noreferrer"><Image preview={false} src={twitter} width={iconWidth} /></a>
            <a target={"_blank"} rel="noreferrer" href='https://t.me/byHorsesTurkey'><Image preview={false} src={telegram} width={iconWidth} /></a>
            <a target={"_blank"} rel="noreferrer" href='https://www.instagram.com/byhorsesgame/?igshid=YmMyMTA2M2Y='><Image preview={false} src={instagram} width={iconWidth} /></a>
            <a target={"_blank"} rel="noreferrer" href='https://medium.com/@byHorsesgame'><Image preview={false} src={medium} width={iconWidth} /></a>
            <a target={"_blank"} rel="noreferrer" href='https://vt.tiktok.com/ZSR1Nmeaq/'><Image preview={false} src={tiktok} width={iconWidth} /></a>
          </Space>
          <div style={{
            position: "absolute",
            right: 5,
            top: 5,
          }}>
          <SelectLanguage />
          </div>
        </Col>
      </Row>
    </>
  )
}

export default MobileFooter