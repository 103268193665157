import React from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../redux/byhorses/actions";
import { Col, Image, Row } from 'antd'
import CountUp from "react-countup";
import VisibilitySensor from 'react-visibility-sensor';
import horseImg from "../../assets/images/byhorse/NFTHorse.png"
import jockeyImg from "../../assets/images/byhorse/NFTJockey.png"
import studImg from "../../assets/images/byhorse/NFTStudfarm.png"
import legendImg from "../../assets/images/byhorse/NFTLegend.png"
import fotoFinish from "../../assets/images/byhorse/NFTFotofinish.png"


const HORSE_COUNT = 999
const JOCKEY_COUNT = 111
const STUD_COUNT = 333
const LEGENDS_COUNT = 666
const FOTOFINIS_COUNT = 444



const NftStats = (props) => {


  const titleStyle = {
    fontSize: props.reduxState && props.reduxState.innerWidth > 768 ? 24 : 18,
    fontWeight: 300,
    display: 'block',
    marginTop: 4
  }
  const countStyleWeb = { fontSize: '2rem', display: 'block', marginTop: '-10px' }

  const countStyleMobile = {
    fontSize: 18,
    display: "block",
    marginTop: 10
  }


  const IMG_WIDTH = props.reduxState && props.reduxState.innerWidth > 768 ? 200 : 70

  const ROW_GUTTER = props.reduxState && props.reduxState.innerWidth > 768 ? [96, 48] : [24, 24]

  return (
    <Row>
      <Col xs={24} lg={{ span: 18, offset: 3 }}>
        <Row gutter={ROW_GUTTER} justify="center">
          <Col xs={12} lg={8} align='center' className='reveal'>
            <div className='nft-stats-card'>
              <span style={titleStyle}>{props.t('translation:horses')}</span>
              <Image preview={false} src={horseImg} width={IMG_WIDTH} />
              <CountUp end={HORSE_COUNT} redraw={true} useEasing={true}>
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <span style={props.reduxState && props.reduxState.innerWidth > 768 ? countStyleWeb : countStyleMobile} ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
            </div>
          </Col>
          <Col xs={12} lg={8} align='center' className='reveal'>
            <div className='nft-stats-card'>
              <span style={titleStyle}>{props.t('translation:jockeys')}</span>
              <Image preview={false} src={jockeyImg} width={IMG_WIDTH} />
              <CountUp end={JOCKEY_COUNT} redraw={true} useEasing={true}>
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <span style={props.reduxState && props.reduxState.innerWidth > 768 ? countStyleWeb : countStyleMobile} ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
            </div>
          </Col>
          <Col xs={12} lg={8} align='center' className='reveal'>
            <div className='nft-stats-card'>
              <span style={titleStyle}>{props.t('translation:studs')}</span>
              <Image preview={false} src={studImg} width={IMG_WIDTH} />
              <CountUp end={STUD_COUNT} redraw={true} useEasing={true}>
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <span style={props.reduxState && props.reduxState.innerWidth > 768 ? countStyleWeb : countStyleMobile} ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
            </div>
          </Col>
          <Col xs={12} lg={{ span: 8, offset: 0 }} align='center' className='reveal'>
            <div className='nft-stats-card'>
              <span style={titleStyle}>{props.t('translation:legends')}</span>
              <Image preview={false} src={legendImg} width={IMG_WIDTH} />
              <CountUp end={LEGENDS_COUNT} redraw={true} useEasing={true}>
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <span style={props.reduxState && props.reduxState.innerWidth > 768 ? countStyleWeb : countStyleMobile} ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
            </div>
          </Col>
          <Col xs={12} lg={{ span: 8 }} align='center' className='reveal'>
            <div className='nft-stats-card'>
              <span style={{ ...titleStyle, marginTop: 10 }}>{props.t('translation:photofinishes')}</span>
              <Image preview={false} src={fotoFinish} width={IMG_WIDTH} />
              <CountUp end={FOTOFINIS_COUNT} redraw={true} useEasing={true}>
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <span style={props.reduxState && props.reduxState.innerWidth > 768 ? countStyleWeb : countStyleMobile} ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(NftStats));