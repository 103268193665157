import { Col, Image, Row } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import roadImg from "../../assets/images/roadmap/steps.png"
import img1 from '../../assets/images/roadmap/1.png';
import img2 from '../../assets/images/roadmap/2.png';
import img3 from '../../assets/images/roadmap/3.png';
import img4 from '../../assets/images/roadmap/4.png';
import img5 from '../../assets/images/roadmap/5.png';
import img6 from '../../assets/images/roadmap/6.png';
import img7 from '../../assets/images/roadmap/7.png';
import img8 from '../../assets/images/roadmap/8.png';
import img9 from '../../assets/images/roadmap/9.png';
import img10 from '../../assets/images/roadmap/10.png';
import img11 from '../../assets/images/roadmap/11.png';
import img12 from '../../assets/images/roadmap/12.png';
import img13 from '../../assets/images/roadmap/13.png';
import img14 from '../../assets/images/roadmap/14.png';
import img15 from '../../assets/images/roadmap/15.png';
import img16 from '../../assets/images/roadmap/16.png';

const Roadmap = () => {
  const { t } = useTranslation()
  const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15, img16]

  const titleStyle = {
    color: "#c6017f",
    fontWeight: 600
  }
  const ulStyle = {
    paddingLeft: 14,
  }

  const renderImages = images.map((img, i) => (
    <Col xs={{span: 16, offset: 4}} align={i % 2 === 0 ? "right" : "left"}>
      <Image preview={false} src={img} width={150} />
    </Col>
  ))


  return (
    <Row>
      <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }}>
        <Row>
          <Col xs={24} align='center' style={{marginTop:10, marginBottom: 50}}>
            <h1>{t('translation:roadmap')}</h1>
          </Col>
          <Col xs={11}>
            <Row gutter={[0,64]}>
              {renderImages}
            </Row>
          </Col>
          <Col xs={2}>
            <Image preview={false} src={roadImg} width={"60px"} />
          </Col>
          <Col xs={11}>
            <Row>
              <Col xs={24} style={{ marginTop: 10 }}>
                <h1 style={titleStyle}>Q1 2022</h1>
                <ul style={ulStyle}>
                  <li>{t('translation:roadmapQ1Li1')}</li>
                  <li>{t('translation:roadmapQ1Li2')}</li>
                  <li>{t('translation:roadmapQ1Li3')}</li>
                  <li>{t('translation:roadmapQ1Li4')}</li>
                </ul>
              </Col>
              <Col xs={24}>
                <h1 style={titleStyle}>Q2 2022</h1>
                <ul style={ulStyle}>
                  <li>{t('translation:roadmapQ2Li1')}</li>
                  <li>{t('translation:roadmapQ2Li2')}</li>
                  <li>{t('translation:roadmapQ2Li3')}</li>
                  <li>{t('translation:roadmapQ2Li4')}</li>
                </ul>
              </Col>
              <Col xs={24}>
                <h1 style={titleStyle}>Q3 2022</h1>
                <ul style={ulStyle}>
                  <li>{t('translation:roadmapQ3Li1')}</li>
                  <li>{t('translation:roadmapQ3Li2')}</li>
                  <li>{t('translation:roadmapQ3Li3')}</li>
                  <li>{t('translation:roadmapQ3Li4')}</li>
                  <li>{t('translation:roadmapQ3Li5')}</li>
                </ul>
              </Col>
              <Col xs={24}>
                <h1 style={titleStyle}>Q4 2022</h1>
                <ul style={ulStyle}>
                  <li>{t('translation:roadmapQ4Li1')}</li>
                  <li>{t('translation:roadmapQ4Li2')}</li>
                  <li>{t('translation:roadmapQ4Li3')}</li>
                  <li>{t('translation:roadmapQ4Li4')}</li>
                  <li>{t('translation:roadmapQ4Li5')}</li>
                  <li>{t('translation:roadmapQ4Li6')}</li>
                  <li>{t('translation:roadmapQ4Li7')}</li>
                </ul>
              </Col>
              <Col xs={24}>
                <h1 style={titleStyle}>Q1 2023</h1>
                <ul style={ulStyle}>
                  <li>{t('translation:roadmapQ5Li1')}</li>
                  <li>{t('translation:roadmapQ5Li2')}</li>
                  <li>{t('translation:roadmapQ5Li3')}</li>
                  <li>{t('translation:roadmapQ5Li4')}</li>
                  <li>{t('translation:roadmapQ5Li5')}</li>
                  <li>{t('translation:roadmapQ5Li6')}</li>
                  <li>{t('translation:roadmapQ5Li7')}</li>
                  <li>{t('translation:roadmapQ5Li8')}</li>
                  <li>{t('translation:roadmapQ5Li9')}</li>
                  <li>{t('translation:roadmapQ5Li10')}</li>
                  <li>{t('translation:roadmapQ5Li11')}</li>
                  <li>{t('translation:roadmapQ5Li12')}</li>
                </ul>
              </Col>
              <Col xs={24} style={{ marginTop: 10 }}>
                <h1 style={titleStyle}>Q2 2023</h1>
                <ul style={ulStyle}>
                  <li>{t('translation:roadmapQ6Li1')}</li>
                  <li>{t('translation:roadmapQ6Li2')}</li>
                  <li>{t('translation:roadmapQ6Li3')}</li>
                  <li>{t('translation:roadmapQ6Li4')}</li>
                  <li>{t('translation:roadmapQ6Li5')}</li>
                  <li>{t('translation:roadmapQ6Li6')}</li>
                  <li>{t('translation:roadmapQ6Li7')}</li>
                </ul>
              </Col>
              <Col xs={24}>
                <h1 style={titleStyle}>Q3 2023</h1>
                <ul style={ulStyle}>
                  <li>{t('translation:roadmapQ7Li1')}</li>
                  <li>{t('translation:roadmapQ7Li2')}</li>
                  <li>{t('translation:roadmapQ7Li3')}</li>
                  <li>{t('translation:roadmapQ7Li4')}</li>
                  <li>{t('translation:roadmapQ7Li5')}</li>
                  <li>{t('translation:roadmapQ7Li6')}</li>
                  <li>{t('translation:roadmapQ7Li7')}</li>
                  <li>{t('translation:roadmapQ7Li8')}</li>
                  <li>{t('translation:roadmapQ7Li9')}</li>
                </ul>
              </Col>
              <Col xs={24}>
                <h1 style={titleStyle}>Q4 2023</h1>
                <ul style={ulStyle}>
                  <li>{t('translation:roadmapQ8Li1')}</li>
                  <li>{t('translation:roadmapQ8Li2')}</li>
                  <li>{t('translation:roadmapQ8Li3')}</li>
                  <li>{t('translation:roadmapQ8Li4')}</li>
                  <li>{t('translation:roadmapQ8Li5')}</li>
                  <li>{t('translation:roadmapQ8Li6')}</li>
                  <li>{t('translation:roadmapQ8Li7')}</li>
                  <li>{t('translation:roadmapQ8Li8')}</li>
                </ul>
              </Col>
              <Col xs={24}>
                <h1 style={titleStyle}>Q1 2024</h1>
                <ul style={ulStyle}>
                  <li>{t('translation:roadmapQ9Li1')}</li>
                  <li>{t('translation:roadmapQ9Li2')}</li>
                  <li>{t('translation:roadmapQ9Li3')}</li>
                  <li>{t('translation:roadmapQ9Li4')}</li>
                  <li>{t('translation:roadmapQ9Li5')}</li>
                  <li>{t('translation:roadmapQ9Li6')}</li>
                </ul>
              </Col>
              <Col xs={24}>
                <h1 style={titleStyle}>Q2 2024</h1>
                <ul style={ulStyle}>
                  <li>{t('translation:roadmapQ10Li1')}</li>
                  <li>{t('translation:roadmapQ10Li2')}</li>
                  <li>{t('translation:roadmapQ10Li3')}</li>
                  <li>{t('translation:roadmapQ10Li4')}</li>
                  <li>{t('translation:roadmapQ10Li5')}</li>
                  <li>{t('translation:roadmapQ10Li6')}</li>
                </ul>
              </Col>
              <Col xs={24}>
                <h1 style={titleStyle}>Q3 2024</h1>
                <ul style={ulStyle}>
                  <li>{t('translation:roadmapQ11Li1')}</li>
                  <li>{t('translation:roadmapQ11Li2')}</li>
                  <li>{t('translation:roadmapQ11Li3')}</li>
                  <li>{t('translation:roadmapQ11Li4')}</li>
                  <li>{t('translation:roadmapQ11Li5')}</li>
                  <li>{t('translation:roadmapQ11Li6')}</li>
                </ul>
              </Col>
              <Col xs={24}>
                <h1 style={titleStyle}>Q3 2024</h1>
                <ul style={ulStyle}>
                  <li>{t('translation:roadmapQ12Li1')}</li>
                  <li>{t('translation:roadmapQ12Li2')}</li>
                  <li>{t('translation:roadmapQ12Li3')}</li>
                  <li>{t('translation:roadmapQ12Li4')}</li>
                  <li>{t('translation:roadmapQ12Li5')}</li>
                  <li>{t('translation:roadmapQ12Li6')}</li>
                  <li>{t('translation:roadmapQ12Li7')}</li>
                </ul>
              </Col>
              <Col xs={24} style={{ marginTop: 30 }}>
                <h1 style={titleStyle}>Q1 2025</h1>
                <ul style={ulStyle}>
                  <li>{t('translation:roadmapQ13Li1')}</li>
                  <li>{t('translation:roadmapQ13Li2')}</li>
                  <li>{t('translation:roadmapQ13Li3')}</li>
                  <li>{t('translation:roadmapQ13Li4')}</li>
                  <li>{t('translation:roadmapQ13Li5')}</li>
                  <li>{t('translation:roadmapQ13Li6')}</li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default Roadmap