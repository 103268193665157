import { publicApi } from "../../services/axios";

// Register API
export const signUp = async (payload) => {
  try {
    const response = await publicApi.post('/pass/register/', payload);
    return response;
  } catch (error) {
    return error.response;
  }
}

// Login API
export const logIn = async (payload) => {
  try {
    const response = await publicApi.post('/management/login', payload);
    return response;
  } catch (error) {
    return error.response;
  }
}