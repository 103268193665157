import React, { useState, useEffect } from 'react';
import { Area } from '@ant-design/plots';

const ScheduleChart = () => {

  const advisorsData = [
    {
      xField: "0",
      tokenAmount: "0",
      category: "Advisors",
    },
    {
      xField: "5",
      tokenAmount: "100000",
      category: "Advisors",
    },
    {
      xField: "10",
      tokenAmount: "1000000",
      category: "Advisors",
    },
    {
      xField: "15",
      tokenAmount: "10000000",
      category: "Advisors",
    },
    {
      xField: "20",
      tokenAmount: "15000000",
      category: "Advisors",
    },
    {
      xField: "25",
      tokenAmount: "20000000",
      category: "Advisors",
    },
    {
      xField: "30",
      tokenAmount: "25000000",
      category: "Advisors",
    },
    {
      xField: "35",
      tokenAmount: "30000000",
      category: "Advisors",
    },
    {
      xField: "40",
      tokenAmount: "35000000",
      category: "Advisors",
    },
    {
      xField: "45",
      tokenAmount: "40000000",
      category: "Advisors",
    },
    {
      xField: "50",
      tokenAmount: "45000000",
      category: "Advisors",
    },
    {
      xField: "55",
      tokenAmount: "50000000",
      category: "Advisors",
    },
    {
      xField: "60",
      tokenAmount: "55000000",
      category: "Advisors",
    },
    {
      xField: "65",
      tokenAmount: "60000000",
      category: "Advisors",
    },
    {
      xField: "70",
      tokenAmount: "65000000",
      category: "Advisors",
    },
  ]
  const airdropData = [
    {
      xField: "0",
      tokenAmount: "0",
      category: "airdrop",
    },
    {
      xField: "5",
      tokenAmount: "1000000",
      category: "airdrop",
    },
    {
      xField: "10",
      tokenAmount: "5000000",
      category: "airdrop",
    },
    {
      xField: "15",
      tokenAmount: "13000000",
      category: "airdrop",
    },
    {
      xField: "20",
      tokenAmount: "20000000",
      category: "airdrop",
    },
    {
      xField: "25",
      tokenAmount: "25000000",
      category: "airdrop",
    },
    {
      xField: "30",
      tokenAmount: "30000000",
      category: "airdrop",
    },
    {
      xField: "35",
      tokenAmount: "35000000",
      category: "airdrop",
    },
    {
      xField: "40",
      tokenAmount: "40000000",
      category: "airdrop",
    },
    {
      xField: "45",
      tokenAmount: "45000000",
      category: "airdrop",
    },
    {
      xField: "50",
      tokenAmount: "50000000",
      category: "airdrop",
    },
    {
      xField: "55",
      tokenAmount: "55000000",
      category: "airdrop",
    },
    {
      xField: "60",
      tokenAmount: "60000000",
      category: "airdrop",
    },
    {
      xField: "65",
      tokenAmount: "65000000",
      category: "airdrop",
    },
    {
      xField: "70",
      tokenAmount: "70000000",
      category: "airdrop",
    },
  ]
  const inGameRewardAndStakingData = [
    {
      xField: "0",
      tokenAmount: "0",
      category: "In-game rewards and Staking",
    },
    {
      xField: "5",
      tokenAmount: "200000",
      category: "In-game rewards and Staking",
    },
    {
      xField: "10",
      tokenAmount: "110000",
      category: "In-game rewards and Staking",
    },
    {
      xField: "15",
      tokenAmount: "13000000",
      category: "In-game rewards and Staking",
    },
  ]

  const data = [
    ...advisorsData,
    ...airdropData,
  ]
  console.log('DATA: ', data)
  const config = {
    data,
    xField: 'xField',
    yField: 'tokenAmount',
    seriesField: 'category',
    color: ['#6897a7', '#8bc0d6', '#60d7a7', '#dedede', '#fedca9', '#fab36f', '#d96d6f'],
  };

  return <Area {...config} />;
}

export default ScheduleChart