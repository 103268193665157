import React, { useEffect } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../redux/byhorses/actions";
import { Button, Col, Image, Row } from 'antd'
import { useTranslation } from 'react-i18next';
import pointerIcon from "../../assets/images/icons/right-arrow.png"
import connectIcon from "../../assets/images/icons/connect-icon.png"
import buyIcon from "../../assets/images/icons/buy-icon.png"
import enterRaceIcon from "../../assets/images/icons/enter-race-icon.png"
import prepareIcon from "../../assets/images/icons/prepare-icon.png"
import raceIcon from "../../assets/images/icons/race-icon.png"
import connectIconWhite from "../../assets/images/icons/connect-icon-white.png"
import buyIconWhite from "../../assets/images/icons/buy-icon-white.png"
import enterRaceIconWhite from "../../assets/images/icons/enter-race-icon-white.png"
import prepareIconWhite from "../../assets/images/icons/prepare-icon-white.png"
import raceIconWhite from "../../assets/images/icons/race-icon-white.png"
import ReactPlayer from 'react-player';

const Game = (props) => {
  const { t } = useTranslation()

  useEffect(() => {
    document.body.classList.add('bg-black',)
    return () => {
      document.body.classList.remove('bg-black')
    }
  }, [])

  const handleDiscoverNft = () => {
    window.location.href="/nft-marketplace"
  }
  
  return (
    <Row>
      <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }} className="how-to-play-bg">
        <Row gutter={[48, 24]} align='middle' style={{ height: 500 }}>
          <Col xs={24} align='center'>
            <span style={{ fontSize: props.reduxState?.innerWidth > 768 ? 48 : 36, color: "#c6017f" }}>{t('translation:whatIsByhorsesGame')}</span>
            <br />
            <br />
            <p style={{ fontSize: props.reduxState?.innerWidth > 768 ? 22 : 16 }}>
              {t('translation:howToPlayParagraph1')}<br />
              {t('translation:howToPlayParagraph2')} <br />
              {t('translation:howToPlayParagraph3')}<br />
              {t('translation:howToPlayParagraph4')} <br />
              {t('translation:howToPlayParagraph5')}<br />
            </p>
          </Col>
          <Col xs={24} lg={{ span: 7, offset: 5 }} align='center' style={{ marginTop: "-100" }}>
            <div className='card-shadow font-1rem' style={{ width: '100%', padding: "10px 0px 10px 0px", backgroundColor: "#000", borderRadius: 10, border: "1px dashed #FFFFFF" }}>
              <span>{t('translation:howToPlayCard1Title1')}</span> <br />
              <span style={{ color: '#C3017D' }}>{t('translation:howToPlayCard1Title2')}</span> <br />
            </div>
          </Col>
          <Col xs={24} lg={{ span: 7, offset: 0 }} align='center' style={{ marginTop: "-100" }}>
            <div className='card-shadow font-1rem' style={{ width: '100%', padding: "10px 0px 10px 0px", backgroundColor: "#000", borderRadius: 10, border: "1px dashed #FFFFFF" }}>
              <span>{t('translation:howToPlayCard2Title1')}</span> <br />
              <span style={{ color: '#C3017D' }}>{t('translation:howToPlayCard2Title2')}</span> <br />
            </div>
          </Col>
        </Row>
      </Col>
      <Col xs={24} style={{
        backgroundColor: "#C6017F",
        textAlign: "center",
        fontSize: props.reduxState?.innerWidth > 768 ? 36 : 20,
        lineHeight: 2,
        fontWeight: 600,
        marginTop: 50
      }}>
        <span>{t('translation:p2eEcosystemBestNftPcGame')}</span>
      </Col>
      <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }} style={{ marginTop: 100 }}>
        <Row align='top' gutter={[96, 24]}>
          <Col xs={24} lg={9}>
            <ReactPlayer url={"https://byhorses-api.devtagon.com/media/uploadImage/NFT.mp4"} config={{ file: { attributes: { playsInline: true, }, }, }}
              style={{}} width={"100%"} height={"100%"} playing muted={true} loop={true} />
          </Col>
          <Col xs={24} lg={{ span: 15, offset: 0 }} style={{ fontSize: props.reduxState?.innerWidth > 768 ? 16 : 14, fontWeight: 500 }}>
            <Row gutter={[24, 0]}>
              <Col xs={1}>
                <Image preview={false} src={pointerIcon} width={17} style={{ marginTop: 6 }} />
              </Col>
              <Col xs={22}>
                <span>{t('translation:gameParagraph1P1')}</span><br />
                <span>{t('translation:gameParagraph1P2')}</span>
              </Col>
            </Row>
            <Row gutter={[24, 0]} style={{ marginTop: 14 }}>
              <Col xs={1}>
                <Image preview={false} src={pointerIcon} width={17} style={{ marginTop: 6 }} />
              </Col>
              <Col xs={22}>
                <span>{t('translation:gameParagraph2P1')}</span><br />
                <span>{t('translation:gameParagraph2P2')}</span><br />
                <span>{t('translation:gameParagraph2P3')}</span>
              </Col>
            </Row>
            <Row gutter={[24, 0]} style={{ marginTop: 14 }}>
              <Col xs={1}>
                <Image preview={false} src={pointerIcon} width={17} style={{ marginTop: 6 }} />
              </Col>
              <Col xs={22}>
                <span>{t('translation:gameParagraph3P1')}</span><br />
                <span>{t('translation:gameParagraph3P2')}</span><br />
                <span>{t('translation:gameParagraph3P3')}</span><br />
                <span>{t('translation:gameParagraph3P4')}</span><br />
                <span>{t('translation:gameParagraph3P5')}</span>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 22, offset: 1 }} style={{ marginTop: 20 }} >
                <Button className='game-colorful-btn' onClick={handleDiscoverNft}>NFT'leri Keşfet</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col xs={24} className="" style={{ marginTop: 100 }}>
        <ReactPlayer url={"https://byhorses-api.devtagon.com/media/uploadImage/At.mp4"} config={{ file: { attributes: { playsInline: true, }, }, }}
              style={{position: "absolute", left: 0}} width={"120vw"} height={"100%"} playing muted={true} loop={true} />
        <Row>
          <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }}>
            <Row>
              <Col xs={24}>
                <span style={{ color: "#C6017F", fontSize: 56, fontWeight: "bold" }}>{t('translation:howTo')}</span>&nbsp;&nbsp;
                <span style={{ fontSize: 36, fontWeight: "bold" }}>{t('translation:play')}?</span>
              </Col>
            </Row>
            <Row gutter={[0, 24]} style={{ marginTop: 20, paddingBottom: 20 }}>
              <Col xs={24}>
                <Row gutter={[24, 0]}>
                  <Col xs={1}>
                    <Image src={connectIcon} preview={false} width={30} style={{ marginTop: 10 }} />
                  </Col>
                  <Col xs={22}>
                    <span style={{ color: "#C6017F", fontSize: 22, fontWeight: 600 }}>{t('translation:connect')}</span><br />
                    <span style={{ color: "#FFFFFF", fontSize: 16, fontWeight: 500 }}>{t('translation:connectP')}</span>
                  </Col>
                </Row>
              </Col>
              <Col xs={24}>
                <Row gutter={[24, 0]}>
                  <Col xs={1}>
                    <Image src={buyIcon} preview={false} width={30} style={{ marginTop: 10 }} />
                  </Col>
                  <Col xs={22}>
                    <span style={{ color: "#C6017F", fontSize: 22, fontWeight: 600 }}>{t('translation:buy-title')}</span><br />
                    <span style={{ color: "#FFFFFF", fontSize: 16, fontWeight: 500 }}>{t('translation:buyP')}</span>
                  </Col>
                </Row>
              </Col>
              <Col xs={24}>
                <Row gutter={[24, 0]}>
                  <Col xs={1}>
                    <Image src={enterRaceIcon} preview={false} width={30} style={{ marginTop: 10 }} />
                  </Col>
                  <Col xs={22}>
                    <span style={{ color: "#C6017F", fontSize: 22, fontWeight: 600 }}>{t('translation:enterRace')}</span><br />
                    <span style={{ color: "#FFFFFF", fontSize: 16, fontWeight: 500 }}>{t('translation:enterRaceP1')}</span><br />
                    <span style={{ color: "#FFFFFF", fontSize: 16, fontWeight: 500 }}>{t('translation:enterRaceP2')}</span>
                  </Col>
                </Row>
              </Col>
              <Col xs={24}>
                <Row gutter={[24, 0]}>
                  <Col xs={1}>
                    <Image src={prepareIcon} preview={false} width={30} style={{ marginTop: 10 }} />
                  </Col>
                  <Col xs={22}>
                    <span style={{ color: "#C6017F", fontSize: 22, fontWeight: 600 }}>{t('translation:prepare')}</span><br />
                    <span style={{ color: "#FFFFFF", fontSize: 16, fontWeight: 500 }}>{t('translation:prepareP1')}</span><br />
                    <span style={{ color: "#FFFFFF", fontSize: 16, fontWeight: 500 }}>{t('translation:prepareP2')}</span><br />
                    <span style={{ color: "#FFFFFF", fontSize: 16, fontWeight: 500 }}>{t('translation:prepareP3')}</span><br />
                    <span style={{ color: "#FFFFFF", fontSize: 16, fontWeight: 500 }}>{t('translation:prepareP4')}</span>
                  </Col>
                </Row>
              </Col>
              <Col xs={24}>
                <Row gutter={[24, 0]}>
                  <Col xs={1}>
                    <Image src={raceIcon} preview={false} width={30} style={{ marginTop: 10 }} />
                  </Col>
                  <Col xs={22}>
                    <span style={{ color: "#C6017F", fontSize: 22, fontWeight: 600 }}>{t('translation:race_')}</span><br />
                    <span style={{ color: "#FFFFFF", fontSize: 16, fontWeight: 500 }}>{t('translation:raceP1')}</span><br />
                    <span style={{ color: "#FFFFFF", fontSize: 16, fontWeight: 500 }}>{t('translation:raceP2')}</span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col xs={24} style={{
        backgroundColor: "#C6017F",
        fontWeight: 500,
        fontSize: 16,
        paddingTop: 4,
        paddingBottom: 4
      }}>
        <Row align='middle' justify='center' gutter={[128, 0]}>
          <Col>
            <Image src={connectIconWhite} preview={false} width={18} />&nbsp;&nbsp;
            <span>{t('translation:connect')}</span>
          </Col>
          <Col>
            <Image src={buyIconWhite} preview={false} width={18} />&nbsp;&nbsp;
            <span>{t('translation:buy-title')}</span>
          </Col>
          <Col>
            <Image src={enterRaceIconWhite} preview={false} width={18} />&nbsp;&nbsp;
            <span>{t('translation:enterRace')}</span>
          </Col>
          <Col>
            <Image src={prepareIconWhite} preview={false} width={18} />&nbsp;&nbsp;
            <span>{t('translation:prepare')}</span>
          </Col>
          <Col>
            <Image src={raceIconWhite} preview={false} width={18} />&nbsp;&nbsp;
            <span>{t('translation:race_')}</span>
          </Col>
        </Row>
      </Col>
      <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }} style={{ marginTop: 0 }}>
        <Row align='bottom' gutter={[96, 24]}>
          <Col xs={24} lg={9}>
            <ReactPlayer url={"https://byhorses-api.devtagon.com/media/uploadImage/Hazine.mp4"} config={{ file: { attributes: { playsInline: true, }, }, }}
              style={{}} width={"100%"} height={"100%"} playing muted={true} loop={true} />
          </Col>
          <Col xs={24} lg={{ span: 15, offset: 0 }} style={{ fontSize: props.reduxState?.innerWidth > 768 ? 16 : 14, fontWeight: 500 }}>
            <div style={{ color: "#C6017F", fontSize: 30, fontWeight: 600, marginBottom: 40 }}>{t('translation:paymentSystem')}</div>
            <Row gutter={[24, 0]}>
              <Col xs={1}>
                <Image preview={false} src={pointerIcon} width={17} style={{ marginTop: 6 }} />
              </Col>
              <Col xs={22}>
                <span>{t('translation:gamePaymentSystemParagraph1P1')}</span><br />
                <span>{t('translation:gamePaymentSystemParagraph1P2')}</span><br />
                <span>{t('translation:gamePaymentSystemParagraph1P3')}</span><br />
                <span>{t('translation:gamePaymentSystemParagraph1P4')}</span>
              </Col>
            </Row>
            <Row gutter={[24, 0]} style={{ marginTop: 14 }}>
              <Col xs={1}>
                <Image preview={false} src={pointerIcon} width={17} style={{ marginTop: 6 }} />
              </Col>
              <Col xs={22}>
                <span>{t('translation:gamePaymentSystemParagraph2P1')}</span><br />
                <span>{t('translation:gamePaymentSystemParagraph2P2')}</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}


const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(Game));