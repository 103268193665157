import React, { useEffect, useState } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../redux/byhorses/actions";
import { Col, Image, Row, Tabs } from 'antd'
import profileIcon from '../../assets/images/icons/profile-icon.png';
import { useTranslation } from 'react-i18next';
import { SettingOutlined, StarFilled, UserOutlined } from '@ant-design/icons';
import { FiLogOut, FiLayers } from "react-icons/fi"
import MyProfile from './tabs/MyProfile';
import MyFavorites from './tabs/MyFavorites';
import MyCollection from './tabs/MyCollection';
import Settings from './tabs/Settings';
import { handleLogOut } from '../../utils/Authentication';

const Account = (props) => {
  const { t } = useTranslation()
  const [tab, setTab] = useState("1")


  useEffect(() => {
    if (!props.location.state) return
    setTab(props.location.state.accountTab)
  }, [props.location.state])

  

  return (
    <Row>
      <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }}>
        <Row align='middle' gutter={[24, 24]}>
          <Col xs={6}>
            <Image preview={false} src={profileIcon} />
          </Col>
          <Col xs={18}>
            <span style={{ fontWeight: 400, fontSize: 20 }}>{t('translation:myAccount')}</span><br />
            <span style={{ fontWeight: 600, fontSize: 28 }}>Ad Soyad</span><br />
            <span style={{ color: "#c4017e", fontSize: 18 }}>metamask account</span><br />
          </Col>
          <Col xs={24}>
            <Tabs defaultActiveKey="1" className='account-tab' onChange={setTab} activeKey={tab}>
              <Tabs.TabPane tab={<div><UserOutlined /> {t('translation:profile')}</div>} key="1">
                <MyProfile t={t} innerWidth={props.reduxState?.innerWidth} />
              </Tabs.TabPane>
              <Tabs.TabPane tab={<div><StarFilled /> {t('translation:favorites')}</div>} key="2">
                <MyFavorites t={t} innerWidth={props.reduxState?.innerWidth} />
              </Tabs.TabPane>
              <Tabs.TabPane tab={<div><FiLayers style={{verticalAlign: 'middle'}} /> &nbsp;{t('translation:collection')}</div>} key="3">
                <MyCollection t={t} innerWidth={props.reduxState?.innerWidth} />
              </Tabs.TabPane>
              <Tabs.TabPane tab={<div><SettingOutlined /> {t('translation:settings')}</div>} key="4">
                <Settings t={t} innerWidth={props.reduxState?.innerWidth} />
              </Tabs.TabPane>
              <Tabs.TabPane tab={<div onClick={handleLogOut}><FiLogOut style={{verticalAlign: 'middle'}} /> &nbsp;{t('translation:logOut')}</div>} key="5">
              </Tabs.TabPane>
            </Tabs>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}


const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(Account));