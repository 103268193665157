import { protectedApi } from '../../services/axios'

export const getProfile = async ({ id, cancelToken }) => {
  try {
    const response = await protectedApi.get(`management/profile/${id}`, { cancelToken: cancelToken });
    return response;
  } catch (error) {
    return error.response;
  }
}

export const uploadFile = async (payload) => {
  try {
    const response = await protectedApi.post(`management/images`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
}

export const updateProfile = async ({id, payload, cancelToken}) => {
  try {
    const response = await protectedApi.put(`management/profile/${id}`, payload, {cancelToken: cancelToken });
    return response;
  } catch (error) {
    return error.response;
  }
}
