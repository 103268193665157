import React from 'react'
import { Col, Image, Row } from 'antd'
import notFoundIcon from '../../../assets/images/icons/favorite-not-found-icon.png';

const MyFavorites = (props) => {

  if (localStorage.getItem('favoriteNFTS')) {
    return (
      <Row>
        <Col xs={24} align='center'>
          Geliştirme aşamasında
          {/* ALLNFTS SERVISINE ISTEK ÇIKILACAK, ISTEGI ÇIKARKEN 
          SADECE LOCAL STORAGEDAKI IDLERI GETIR DIYE ISTEK ÇIKMAMIZ GEREK
          BU SEBEPLE BACKEND DESTEGI GELENE KADAR BU KISMI BEKLEMEDE! */}
        </Col>
      </Row>
    )
  }
  return (
    <Row style={{ marginTop: 20 }}>
      <Col xs={24} align='center'>
        <Image preview={false} src={notFoundIcon} width={150} />
        <p style={{ fontWeight: 500, fontSize: 22, marginTop: 10 }}>{props.t('translation:favoriteNotFound')}</p>
      </Col>
    </Row>
  )
}

export default MyFavorites