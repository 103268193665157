import React from 'react'
import { Col, Row, Switch } from 'antd'

const SettingRow = (props) => {
  const switchStyle = {
    position: "absolute",
    top: "45%",
    "msTransform": "translateY(-50%)",
    transform: "translateY(-50%)"
  }

  return (
    <Row style={{ marginBottom: 16 }}>
      <Col xs={4} lg={1} style={{ borderBottom: "1px solid #F5F5F5", paddingBottom: 8 }}>
        <Switch onChange={props.onChange} style={switchStyle} />
      </Col>
      <Col xs={20} lg={14} style={{ borderBottom: "1px solid #F5F5F5", paddingBottom: 8, paddingLeft: 8 }}>
        <h4>{props.title}</h4>
        <span style={{fontSize: props.innerWidth > 768 ? undefined : 12}}>{props.description}</span>
      </Col>
    </Row>
  )
}

const Settings = (props) => {

  const handleNotificationChange = (name, status) => {
    console.log(name, status)
  }



  const notificationSettingsList = [
    {
      key: 0,
      onChange: (checked) => handleNotificationChange("productSale", checked),
      title: props.t('translation:setting0Title'),
      description: props.t('translation:setting0Description')
    },
    {
      key: 1,
      onChange: (checked) => handleNotificationChange("priceChange", checked),
      title: props.t('translation:setting1Title'),
      description: props.t('translation:setting1Description')
    },
    {
      key: 2,
      onChange: (checked) => handleNotificationChange("productUpdate", checked),
      title: props.t('translation:setting2Title'),
      description: props.t('translation:setting2Description')
    },
    {
      key: 3,
      onChange: (checked) => handleNotificationChange("buy", checked),
      title: props.t('translation:setting3Title'),
      description: props.t('translation:setting3Description')
    },
    {
      key: 4,
      onChange: (checked) => handleNotificationChange("bulletin", checked),
      title: props.t('translation:setting4Title'),
      description: props.t('translation:setting4Description')
    },
  ]
  const notificationSettingsRender = notificationSettingsList.map((data, index) => {
    return (
      <SettingRow {...data} innerWidth={props.innerWidth} />
    )
  })

  return (
    <Row gutter={[24, 24]}>
      <Col xs={24}>
        <span style={{ fontWeight: 600, fontSize: 20 }}>{props.t('translation:notificationSettings')}</span>
      </Col>
      <Col xs={24}>
        {notificationSettingsRender}
      </Col>
    </Row>
  )
}

export default Settings