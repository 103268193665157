import React from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../redux/byhorses/actions";
import MobileFooter from './MobileFooter';
import WebFooter from './WebFooter';


const Footer = (props) => {

  if(props.reduxState?.innerWidth < 768){
    return (
      <MobileFooter />
    )
  }
  return (
    <WebFooter />
  )
}


const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(Footer));