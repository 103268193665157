import React, { useEffect, useState } from 'react'
import { Col, Row } from 'antd'
import Policy from './Policy'


const policyTR = (
  <>
    <h2><strong>byHorses Gizlilik Politikası</strong></h2>
    <p>&nbsp;</p>
    <p><strong>Giriş</strong></p>
    <p>&nbsp;</p>
    <p><strong>byHorses</strong> (&ldquo; <strong>byHorses </strong>&rdquo; veya &ldquo; <strong>biz</strong> &rdquo;, &ldquo; <strong>biz</strong> &rdquo;) olarak oyunlar tasarlayan ve geliştiren bir teknoloji şirketiyiz. Bu Gizlilik Politikası ile kişisel verilerinizi nasıl ve neden topladığımızı, sakladığımızı, paylaştığımızı ve kullandığımızı, iş yaptığımız &uuml;lkelerin yasalarına uygun şekilde şeffaf bir şekilde a&ccedil;ıklamayı ama&ccedil;lıyoruz. Gizliliğiniz ve kişisel verilerinizin g&uuml;venliği bizim i&ccedil;in son derece &ouml;nemlidir ve her zaman &ouml;yle kalacaktır. Bu Politikanın &ldquo;kişisel verilere&rdquo; atıfta bulunduğu durumlarda, kimliği belirli veya belirlenebilir yaşayan bir bireyle ilgili her t&uuml;rl&uuml; bilgiye atıfta bulunulmaktadır.</p>
    <p>&nbsp;</p>
    <p>Bu Gizlilik Politikası, <strong>byHorses</strong>&rsquo;ın web siteleri, mobil uygulamaları, forumları ve her t&uuml;rl&uuml; blogları ve <strong>byHorses</strong> tarafından sağlanan diğer t&uuml;m &ccedil;evrimi&ccedil;i veya &ccedil;evrimdışı hizmetler i&ccedil;in ge&ccedil;erlidir.</p>
    <p>&nbsp;</p>
    <p>T&uuml;m platformlardaki pazarlama ve reklam faaliyetlerimiz ve zaman zaman size sunabileceğimiz diğer hizmetler i&ccedil;in de ge&ccedil;erlidir. Bu Gizlilik Politikasında oyunlarımızı, web sitelerimizi, pazarlama ve reklam faaliyetlerimizi ve diğer hizmetlerimizi toplu olarak &ldquo; <strong>Hizmetlerimiz</strong> &rdquo; olarak adlandırıyoruz.</p>
    <p>&nbsp;</p>
    <p>Mobil uygulamamızı indirerek, oyunlarımıza erişerek ve/veya oynayarak veya web sitelerimiz veya diğer Hizmetlerimizle etkileşimde bulunarak, kişisel verilerinizin bu Gizlilik Politikasına uygun olarak toplanmasını ve kullanılmasını kabul etmiş olursunuz. Bize veri sağlama veya bu Gizlilik Politikasında a&ccedil;ıklandığı şekilde kullanılmasıyla ilgili başka endişeleriniz varsa, oyunlarımızı veya diğer Hizmetleri kullanmamalısınız. Bu Gizlilik Politikası, Kuzey Amerika, Avrupa, T&uuml;rkiye, Asya-Pasifik &uuml;lkeleri ve diğer yargı b&ouml;lgelerindekiler de dahil olmak &uuml;zere k&uuml;resel gereksinimleri karşılamayı ama&ccedil;lamaktadır.</p>
    <p>&nbsp;</p>
    <p><strong>Kişisel Verilerinizi Nasıl Topluyoruz</strong></p>
    <p>&nbsp;</p>
    <p>Kişisel verileri doğrudan sizden veya onayınızı almış olan veya bu t&uuml;r kişisel bilgileri bizimle paylaşmak i&ccedil;in başka bir yasal hakkı olan diğer &uuml;&ccedil;&uuml;nc&uuml; taraf şirketlerden (reklam platformları ve ortaklar ve elde etmiş olan veri toplayıcılar dahil) toplayabiliriz. Bu, sizin ve ilgi alanlarınızla ilgili &ouml;zelliklerin yanı sıra kullandığınız diğer oyunlar ve hizmetler, demografik ve genel konum bilgilerini i&ccedil;erebilir. Kişisel verilerinizi bu Gizlilik Politikasında a&ccedil;ıklandığı şekilde kullanacağız.</p>
    <p>&nbsp;</p>
    <p><strong>Doğrudan Sizden Toplanan Kişisel Veriler</strong></p>
    <p>&nbsp;</p>
    <p>Oyunlarımızı indirerek, bunlara erişerek ve/veya oynayarak veya diğer Hizmetlerimizi kullanarak, <strong>byHorses</strong> tarafından kişisel verilerinizin toplanmasına izin vermiş olursunuz. Benzersiz cihaz kimliğiniz (kalıcı/kalıcı olmayan), cihazınızın işletim sistemi, s&uuml;r&uuml;m&uuml;, modeli, CPU&rsquo;su, RAM ve disk alanı miktarı, donanım t&uuml;r&uuml;, Reklam Tanımlayıcısı gibi bu verilerin &ccedil;oğu doğrudan sizden veya cihazınızdan gelir. (IDFA), reklam kimliği, işletim sisteminizin s&uuml;r&uuml;m&uuml; (&ldquo;OS&rdquo;), cihaz adınız, oyun merkezi kimliğiniz ve İnternet Protokol&uuml;n&uuml;ze (&ldquo;IP&rdquo;) dayalı olarak konumunuz. Bu verileri, optimizasyon, kişiselleştirme ve oyun i&ccedil;i teklifler ile satın alma işlemlerini doğrulama ama&ccedil;ları dahil olmak &uuml;zere size oyunlarımızı ve Hizmetlerimizi sağlamak i&ccedil;in kullanırız. Bu Gizlilik Politikasında belirtilen diğer ama&ccedil;lar i&ccedil;in de kullanabiliriz.</p>
    <p>&nbsp;</p>
    <p>Hizmetlerimizden hangileriyle etkileşimde bulunduğunuza bağlı olarak, aşağıdaki bilgi t&uuml;rlerini toplayabilir ve işleyebiliriz:</p>
    <p>&nbsp;</p>
    <p>Oyunlarımızı, reklamlarımızı ve diğer Hizmetleri nasıl kullandığınız ve bunlarla nasıl etkileşimde bulunduğunuzla ilgili ayrıntılar (&ouml;rneğin, oyunlarımızı nasıl ve ne zaman oynadığınız veya web sitemizi/sitelerimizi ziyaret ettiğiniz, oyunlarımıza ve Hizmetlerimize erişmek i&ccedil;in hangi cihazı kullandığınız veya profille ilgili ayrıntılar) ziyaretler;</p>
    <p>&nbsp;</p>
    <ul>
      <li>Bizimle olan etkileşimleriniz sosyal medya kanallarımızda.</li>
    </ul>
    <p>Facebook hesabınızı bağlayarak bir <strong>byHorses</strong> profili oluşturmamayı se&ccedil;erseniz, oyunlarımızı oynarken topladığımız kişisel veriler anonim olacaktır, ancak cihaz d&uuml;zeyinde tanımlayıcıları ve bu tanımlayıcılarla ilişkili diğer bilgileri toplayacağız:</p>
    <p>&nbsp;</p>
    <ul>
      <li>Oyunlarımızı oynamak veya Hizmetlerimize erişmek i&ccedil;in kullandığınız cihaz(lar)ın t&uuml;r&uuml;, IP adresi, cihaz tanımlayıcıları, reklam tanımlayıcıları, <strong>byHorses</strong> oyuncu kimliği ve oynadığınız &uuml;lke veya b&ouml;lge gibi tanımlayıcılar; ve</li>
    </ul>
    <p>&nbsp;</p>
    <ul>
      <li>oyunlarımızı nasıl oynadığınız ve web sitelerimizle nasıl etkileşim kurduğunuz (seviye başlangıcı, seviye sonu, oturum başlangıcı, g&uuml;&ccedil;lendirici kullanımı, harcama, &ouml;d&uuml;ller, oyunlardaki ilerlemeniz, oturum s&uuml;resi ve s&uuml;resi, her seviyede deneme sayısı ve yapılan satın almalar gibi).</li>
    </ul>
    <p>&nbsp;</p>
    <p><strong>Kişisel Verilerinizin İfşası</strong></p>
    <p>&nbsp;</p>
    <p>Bilgilerinizi bu Gizlilik Politikasında (Hizmetleri &Uuml;&ccedil;&uuml;nc&uuml; Taraf hizmet sağlayıcılarımızla sağlamak; yasal y&uuml;k&uuml;ml&uuml;l&uuml;klere uymak; haklarımızı ve m&uuml;lkiyetimizi korumak ve savunmak i&ccedil;in) a&ccedil;ıklandığı şekilde paylaşabiliriz.</p>
    <p>&nbsp;</p>
    <ul>
      <li><strong> Servis Sağlayıcılar</strong>. Kişisel verilerinizi hizmet sağlayıcılarımızla paylaşabiliriz. Kendilerine emanet ettiğimiz hizmet sağlayıcı (veri işlemciler) t&uuml;rleri arasında (i) BT ve ilgili hizmetleri sağlayan; (ii) talep ettiğiniz bilgi ve hizmetlerin sağlanması; (iii) m&uuml;şteri hizmetleri faaliyetleri; (iv) Hizmetlerin iyileştirilmesi ve optimizasyonunda bize yardımcı olan analitik ve arama motoru faaliyetleri; ve (v) Hizmetlerimizle bağlantılı destekleyici faaliyetler. <strong>byHorses</strong>, hizmet sağlayıcılarla, s&ouml;zleşmeli hizmetleri bizim adımıza yerine getirmek veya ge&ccedil;erli yasal gerekliliklere uymak i&ccedil;in gerekli olmadık&ccedil;a, kişisel verileri kullanmalarını veya paylaşmalarını yasaklayan uygun s&ouml;zleşmeler imzalamıştır.</li>
    </ul>
    <p>&nbsp;</p>
    <ul>
      <li><strong> Bizim, Sizin veya Başkalarının Haklarını Korumaya Y&ouml;nelik A&ccedil;ıklamalar (&ouml;rneğin, Yasaların Gerektirdiği ve Benzer A&ccedil;ıklamalar)</strong>.Gerekli veya uygun olduğuna inandığımız takdirde kişisel verilerinize, diğer hesap bilgilerinize ve i&ccedil;eriğinize erişebilir, bunları koruyabilir ve ifşa edebiliriz: (i) bir mahkeme emri veya mahkeme celbi gibi kanun yaptırımı veya ulusal g&uuml;venlik taleplerine ve yasal s&uuml;rece uymak ; (ii) isteklerinize yanıt vermek i&ccedil;in; (iii) sizin, bizim veya başkalarının haklarını, m&uuml;lkiyetini veya g&uuml;venliğini korumak i&ccedil;in; (iv) <strong>byHorses</strong>&rsquo;ın politikalarını veya s&ouml;zleşmelerini uygulamak; (v) <strong>byHorses</strong>&rsquo;a bor&ccedil;lu olunan tutarları tahsil etmek; (vi) ifşanın fiziksel zararı veya mali kaybı &ouml;nlemek i&ccedil;in veya ş&uuml;pheli veya fiili yasa dışı faaliyetin soruşturması veya kovuşturmasıyla bağlantılı olarak gerekli veya uygun olduğuna inandığımızda; veya (vii) iyi niyetle, a&ccedil;ıklamanın başka bir şekilde gerekli veya tavsiye edilebilir olduğuna inanırsak.</li>
    </ul>
    <p>&nbsp;</p>
    <p>Ek olarak, zaman zaman g&uuml;venlik amacıyla (&ouml;rneğin, Hizmetlerde yetkisiz etkinliği tespit etmek i&ccedil;in) sunucu g&uuml;nl&uuml;kleri g&ouml;zden ge&ccedil;irilebilir. Bu gibi durumlarda, IP adreslerini i&ccedil;eren sunucu g&uuml;nl&uuml;k verileri, yetkisiz faaliyetlerin araştırılmasıyla bağlantılı olarak kullanıcıları tanımlayabilmeleri i&ccedil;in kolluk kuvvetleriyle paylaşılabilir.</p>
    <p>&nbsp;</p>
    <ul>
      <li><strong> Varlıkların Satışı ve Kontrol Değişikliği. byHorses</strong>&rsquo;ın varlıklarının satışı veya kurumsal yeniden yapılanma veya <strong>byHorses</strong>&rsquo;ın ortaklık yapısındaki kontrol değişikliği veya bu etkinliklerden herhangi birinin hazırlanmasının bir sonucu olarak. <strong>byHorses</strong>&rsquo;ın varlıklarını devrettiği herhangi bir &uuml;&ccedil;&uuml;nc&uuml; taraf, bize sağladığınız kişisel ve diğer bilgileri bu Gizlilik Politikasında belirtilen şekilde kullanmaya devam etme hakkına sahip olacaktır.</li>
    </ul>
    <p>&nbsp;</p>
    <p><strong>Uluslararası Transfer</strong></p>
    <p>&nbsp;</p>
    <p><strong>byHorses</strong> tarafından toplanan veya saklanan t&uuml;m kişisel veriler, T&uuml;rkiye Cumhuriyeti, Amerika Birleşik Devletleri, Avrupa Birliği dahil ancak bunlarla sınırlı olmamak &uuml;zere d&uuml;nyanın herhangi bir yerinde, bulutta, sunucularımızda ve bizim sunucularımızda saklanabilir. Servis sağlayıcıları. Kişisel verilerinizi uluslararası olarak aktardığımızda, bunların g&uuml;venli, yasal ve burada a&ccedil;ıkladığımız şekilde ele alınmasını sağlamak i&ccedil;in makul adımları atacağız. L&uuml;tfen yasaların yargıdan yargıya değişiklik g&ouml;sterdiğini unutmayın; bu nedenle, kişisel bilgilerinizin aktarıldığı veya saklandığı, kullanıldığı veya işlendiği yerlerde ge&ccedil;erli olan gizlilik yasaları, ikamet ettiğiniz yer i&ccedil;in ge&ccedil;erli olan gizlilik yasalarından farklı olabilir.</p>
    <p>&nbsp;</p>
    <p>Kişisel verilerinizi bize sağlayarak, kişisel verilerinizin yukarıda belirtilen konumlarda saklanmasına izin vermiş olursunuz.</p>
    <p>&nbsp;</p>
    <p>Hizmetlerimizi Avrupa Birliği&rsquo;nden veya veri toplama ve kullanımını d&uuml;zenleyen yasalara sahip diğer b&ouml;lgelerden kullanıyorsanız, kişisel verilerinizin T&uuml;rkiye ve Amerika Birleşik Devletleri&rsquo;ne aktarılmasını ve verilerinizin k&uuml;resel olarak işlenmesini kabul ettiğinizi l&uuml;tfen unutmayın.</p>
    <p>&nbsp;</p>
    <p>Web Sitesini ziyaret etmeyi, Hizmetleri kullanmayı veya bize başka bir şekilde bilgi vermeyi se&ccedil;erek, gizlilik veya bu Gizlilik Politikasında yer alan şartlarla ilgili herhangi bir anlaşmazlığın T&uuml;rkiye Cumhuriyeti yasalarına tabi olacağını ve bu Anlaşmadan kaynaklanan herhangi bir anlaşmazlığın karara bağlanacağını kabul etmektesiniz. <strong>byHorses</strong> veya Web Sitesi ile bağlantı Hizmet Koşullarına uygun olacaktır.</p>
    <p>&nbsp;</p>
    <p><strong>Hizmetlerimizi Sağlamak ve Optimize Etmek</strong></p>
    <p>&nbsp;</p>
    <p>Kişisel verilerinizi, web sitelerimizi, reklamlarımızı ve diğer Hizmetleri size sunmamızı sağlamak ve sizin ve t&uuml;m oyuncularımız i&ccedil;in olabileceklerinin en iyisini yapabilmemiz i&ccedil;in hepsini optimize etmek i&ccedil;in kullanırız. Bu, web sitelerimizin t&uuml;m cihazlarda d&uuml;zg&uuml;n &ccedil;alıştığından ve t&uuml;m oyuncularımız i&ccedil;in olabildiğince eğlenceli olduğundan emin olmak i&ccedil;in toplu verilerin kullanımını ve analizini i&ccedil;erecektir.</p>
    <p>&nbsp;</p>
    <p><strong>M&uuml;şteri Hizmetleri</strong></p>
    <p>&nbsp;</p>
    <p>İletişimlerde doğrudan bize sağladığınız veriler de dahil olmak &uuml;zere kişisel verilerinizi, ihtiya&ccedil; duyduğunuzda m&uuml;şteri hizmetleri kanallarımız aracılığıyla size destek sağlamak i&ccedil;in kullanırız. Ayrıca, &ouml;rneğin m&uuml;şteri hizmetlerinin bir par&ccedil;ası olarak sizinle iletişim kurmak veya oyunlarımız ve Hizmetlerimiz hakkında size g&uuml;ncellemeler g&ouml;ndermek i&ccedil;in de kullanabiliriz. Son olarak, verilerinizi hesabınızı ve bizimle olan ilişkinizi y&ouml;netmek ve Hizmetlerimizi kullandığınızda deneyiminizi geliştirmek i&ccedil;in kullanacağız.</p>
    <p>&nbsp;</p>
    <p><strong>Kullanıcı İncelemeleri</strong></p>
    <p>&nbsp;</p>
    <p>Zaman zaman oyunlarımız hakkındaki yorumlarınızı paylaşabilir ve kişisel verileri i&ccedil;erebilecek bazı bilgiler verebilirsiniz. Bu t&uuml;r incelemelere katılımınızdan toplanan t&uuml;m kişisel veriler sizin tarafınızdan g&ouml;n&uuml;ll&uuml; olarak sağlanmaktadır. Bu t&uuml;r kişisel verileri &uuml;r&uuml;nlerimizi, web sitelerimizi ve/veya Hizmetlerimizi geliştirmek i&ccedil;in ve burada sağlanan politikalara uygun herhangi bir şekilde kullanabiliriz.</p>
    <p>&nbsp;</p>
    <p><strong>Sohbet</strong></p>
    <p>&nbsp;</p>
    <p>Hizmetlerimizden bazıları sohbet işlevselliği i&ccedil;erir. Sohbet servis(ler)imizin kullanıcıları diğer oyunculara mesaj g&ouml;nderebilir. Mesajlarınız tarafımızca kullanılabilir ve saklanabilir: (a) mesajlarınızı iletmek i&ccedil;in; ve (b) sizin ve alıcılarınızın mesaj ge&ccedil;mişinizi g&ouml;r&uuml;nt&uuml;lemesini sağlamak i&ccedil;in (ancak bizim tarafımızdan belirlenen ilgili s&uuml;reden sonra oyun i&ccedil;i sohbet mesajlarınıza artık erişilemeyeceğini l&uuml;tfen unutmayın). Hizmet şartlarımıza uyduğunuzdan emin olmak i&ccedil;in otomatik filtreler kullanmak da dahil olmak &uuml;zere mesajlarınızın i&ccedil;eriğini inceleme hakkımızı saklı tutuyoruz ve ayrıca sohbet hizmet(ler)imizi kullanmanızı engelleme veya herhangi bir nedenle herhangi bir mesaj g&ouml;ndermek.</p>
    <p>&nbsp;</p>
    <p><strong>Reklam Tanımlayıcılar, Yazılım Geliştirme Kitleri ve Benzer Teknolojiler</strong></p>
    <p>&nbsp;</p>
    <p>Oyunlarımızı, Web Sitelerimizi ve ilgi alanına dayalı reklamcılığı sağlamak i&ccedil;in reklam tanımlayıcıları, yazılım geliştirme kitleri ve benzer teknolojiler kullanıyoruz. Bu b&ouml;l&uuml;m, bu teknolojileri kullanımımızı a&ccedil;ıklamaktadır.</p>
    <p>&nbsp;</p>
    <p>Reklam tanımlayıcılar ve benzer teknolojiler tarafımızca Hizmetlerimizi size sunmak ve cihazınızdan veri toplamak i&ccedil;in kullanılmaktadır. Bu teknolojiler bize ve &uuml;&ccedil;&uuml;nc&uuml; taraf reklam ortaklarımıza şunları sağlar:</p>
    <p>&nbsp;</p>
    <ul>
      <li>sizi ve cihazınızı tanımak;</li>
      <li>size ilgi alanına dayalı reklamcılık sağlamak;</li>
      <li>Hizmetlerimizin bir &uuml;&ccedil;&uuml;nc&uuml; taraf sosyal ağ veya platformla (bu t&uuml;r bir etkileşime izin vermeyi se&ccedil;tiğiniz durumlarda) etkileşime girmesine izin vermek;</li>
      <li>&ouml;rneğin Hizmetlerimizi doğru dilde sağlamak gibi, bizim ve &uuml;&ccedil;&uuml;nc&uuml; tarafların size daha &ouml;zelleştirilmiş hizmetler sunmasını sağlamak.</li>
    </ul>
    <p>&nbsp;</p>
    <p><strong>Reklam Tanımlayıcıları ve Benzer Teknolojiler Nasıl &Ccedil;alışır?</strong></p>
    <p>&nbsp;</p>
    <p>Reklam tanımlayıcıları, Android reklam kimliği ve/veya Apple&rsquo;ın reklamcılık kimliği gibi cihazınızla benzersiz bir şekilde ilişkilendirilen kalıcı olmayan, kişisel olmayan tanımlayıcılardır. Benzer teknolojiler, reklamlardaki izleme piksellerini i&ccedil;erir. Bu teknolojiler, şirketlerin (pazarlamacılar ve reklamcılar dahil) Web Sitelerini ve uygulamaları kullandığınızda cihazınızı tanımasını sağlar.</p>
    <p>&nbsp;</p>
    <p><strong>Cihazınızda ve Facebook&rsquo;ta İlgi Alanına Dayalı Reklamlardan Nasıl Vazge&ccedil;ilir?</strong></p>
    <p>&nbsp;</p>
    <p>Cihaz d&uuml;zeyinde ilgi alanına dayalı reklamcılığı devre dışı bırakma: Cihazınızın reklam tanımlayıcısının ilgi alanına dayalı reklamcılık i&ccedil;in kullanılmasını engellemeyi se&ccedil;ebilir veya cihazınızın ayarlarını değiştirerek cihazınızın reklam tanımlayıcısını sıfırlayabilirsiniz. Ayarlar cihazdan cihaza değişir ancak genellikle reklam tanımlayıcı ayarlarını cihazınızın ayarlarında &ldquo;gizlilik&rdquo; veya &ldquo;reklamlar&rdquo; altında bulabilirsiniz.</p>
    <p>&nbsp;</p>
    <p>Facebook reklamlarından &ccedil;ıkmak: Facebook ile reklamcılık tercihlerinizi g&uuml;ncellemek i&ccedil;in Facebook&rsquo;ta hesabınıza giriş yapmanız ve oradaki ayarlarınızı g&uuml;ncellemeniz gerekir.</p>
    <p>&nbsp;</p>
    <p><strong>Analitik ve Araştırma</strong></p>
    <p>&nbsp;</p>
    <p>Oyunlarımızı nasıl oynadığınız veya Hizmetlerimizi nasıl kullandığınız hakkında bilgi toplamak i&ccedil;in &uuml;&ccedil;&uuml;nc&uuml; taraf analiz ara&ccedil;larını i&ccedil;erebilecek analiz ara&ccedil;larını kullanıyoruz. Bu ara&ccedil;lar &ccedil;erezleri veya diğer benzer izleme teknolojilerini kullanabilir. Verilerinizi araştırma yapmak, anketler yapmak veya sizinle doğrudan iletişim kurmak i&ccedil;in, &ouml;rneğin size iletişim (e-posta yoluyla dahil) g&ouml;ndererek, bu ama&ccedil;larla kullanabiliriz.</p>
    <p>&nbsp;</p>
    <p><strong>Promosyonlar ve Yarışmalar</strong></p>
    <p>&nbsp;</p>
    <p>Zaman zaman sizden adınız, adresiniz, posta kodunuz, e-posta adresiniz veya bir &ouml;d&uuml;l talep etmeniz i&ccedil;in g&ouml;nderdiğiniz diğer bilgiler veya i&ccedil;erik gibi belirli kişisel verileri toplayabileceğimiz promosyonlar veya yarışmalar d&uuml;zenleyebiliriz. Bu yarışmaların kendi &ouml;zel h&uuml;k&uuml;m ve koşulları olacaktır, ancak bu yarışmayla ilgili olarak sağladığınız kişisel verileri yarışmayı y&uuml;r&uuml;tmek amacıyla kullanacağız.</p>
    <p>&nbsp;</p>
    <p><strong>Hile, Su&ccedil; ve Dolandırıcılığı &Ouml;nleme</strong></p>
    <p>&nbsp;</p>
    <p>Aldatma, su&ccedil; veya dolandırıcılığı &ouml;nlemek i&ccedil;in elimizdeki kişisel verileri kullanabiliriz. Bilgilerinizi dolandırıcılık &ouml;nleme kurumlarına ve polis gibi su&ccedil; ve dolandırıcılığın &ouml;nlenmesiyle ilgili diğer kuruluşlara da iletmemiz gerekebilir.</p>
    <p>&nbsp;</p>
    <p><strong>Yasal Kullanımlar</strong></p>
    <p>&nbsp;</p>
    <p>Verilerinizi y&uuml;r&uuml;rl&uuml;kteki yasaların gerektirdiği veya izin verdiği şekilde kullanabiliriz.</p>
    <p>&nbsp;</p>
    <p><strong>Web Sitelerimizi Ziyaret Etme</strong></p>
    <p>&nbsp;</p>
    <p><strong>https://www.byhorses.com/</strong> (&ldquo;<strong> Web Sitesi </strong>&rdquo;) web sitemizi ziyaret ettiğinizde , sizinle ilgili aşağıda belirtilen kişisel verileri toplar ve işleriz. Web sitemizdeki iş başvuru formunu doldururken veya CV y&uuml;klediğinizde ve ek bağlantılar sağladığınızda bize sağlanan her t&uuml;rl&uuml; bilgi.</p>
    <p>&nbsp;</p>
    <p><strong>&Uuml;&ccedil;&uuml;nc&uuml; Kişilerden Toplanan Kişisel Veriler</strong></p>
    <p>&nbsp;</p>
    <p>Yukarıda belirtildiği gibi, hakkınızda topladığımız bilgilerin &ccedil;oğu, oyunlarımızı oynadığınızda veya Web Sitelerimiz, reklamlarımız veya diğer Hizmetlerimizle etkileşim kurduğunuzda doğrudan sizden gelir. Ancak, &uuml;&ccedil;&uuml;nc&uuml; şahıs şirketlerin kişisel verilerinize erişmesine izin verdiğinizde (&ouml;rneğin, mobil uygulamalarımızı App Store, Google Store &uuml;zerinden indirerek ve Facebook hesabınızı mobil uygulamalara bağlayarak), bu t&uuml;r &uuml;&ccedil;&uuml;nc&uuml; şahıslardan da bilgi toplarız.</p>
    <p>&nbsp;</p>
    <p><strong>Facebook Hesabının Bağlanması</strong></p>
    <p>&nbsp;</p>
    <p>Facebook hesap bilgilerinizi kullanarak <strong>byHorses</strong> profilinize giriş yaptığınızda, Facebook hesap bilgilerinizin bir kısmını alacağız. Oyunlarımızı &ccedil;evrimi&ccedil;i oynamayı se&ccedil;erseniz, temel Facebook hesap bilgileriniz otomatik olarak <strong>byHorses</strong> profilinizle ilişkilendirilecektir. Tam olarak aldığımız bilgiler Facebook kimliği, profil resmi ve adınızdır. Bunu yaparsanız, hesabınızı birden fazla cihazda oturum a&ccedil;mak ve bu cihazlar arasında ilerlemeyi senkronize etmek i&ccedil;in kullanabileceksiniz. M&uuml;mk&uuml;n olduğunda, hangi arkadaşlarınızın aynı oyunu oynadığını ve oyunlardaki ilerlemelerini g&ouml;rebileceksiniz (ve sizi ve ilerlemenizi g&ouml;rebilecekler) veya oyunlarımızda kullanıma sunduğumuz diğer işlevlere erişebileceksiniz ve Arkadaşlarınızı oynamaya davet etmek gibi diğer Hizmetler.</p>
    <p>&nbsp;</p>
    <p>Ayrıca Facebook hesabınızla ilişkili e-posta hesabını ve oyunlarımızı oynamakta olan Facebook arkadaşlarınızın adlarını ve avatarlarını da toplayacağız. Facebook hesabınızı <strong>byHorses</strong> profilinizle ilk ilişkilendirdiğinizde, Facebook iletişim kutusunda e-posta adresinize erişmemize izin vermemeyi se&ccedil;ebilirsiniz.</p>
    <p>&nbsp;</p>
    <p>Facebook ile giriş yapmak, izniniz olmadan sayfanıza herhangi bir şey g&ouml;ndermemize izin vermeyecek ve oyunumuzu oynamayan Facebook arkadaşlarınız hakkında hi&ccedil;bir bilgi almayacağız. Facebook arkadaşlarınızın sizi ve oyunumuzdaki ilerlemenizi g&ouml;rmesini istemiyorsanız, Facebook ayarlarınızdan Facebook ile bağlantınızı keserek ve uygulama izinlerinizi g&uuml;ncelleyerek bunu &ouml;nleyebilirsiniz.</p>
    <p>&nbsp;</p>
    <p>Facebook&rsquo;un Gizlilik Politikasını (https://www.facebook.com/privacy/explanation) okumanızı ve Facebook verilerinizin nasıl paylaşıldığını tam olarak anladığınızdan emin olmak i&ccedil;in zaman zaman gizlilik ayarlarınızı g&ouml;zden ge&ccedil;irmenizi tavsiye ederiz.</p>
    <p>&nbsp;</p>
    <p><strong>Mobil Uygulamalarımızın App Store ve Google Store &Uuml;zerinden Kurulumu</strong></p>
    <p>&nbsp;</p>
    <p>Mobil uygulamalarımızla ilgili &ouml;ğeleri (App Store veya Google Store &uuml;zerinden satın alabileceğiniz/indirebileceğinizler gibi) y&uuml;klediğinizde veya satın aldığınızda, <strong>byHorses</strong> kredi kartı veya banka hesabı verilerinize erişemez. Ancak, yukarıda belirtilen &uuml;&ccedil;&uuml;nc&uuml; taraf &ouml;deme işlemcileri, siparişinizi yerine getirmek i&ccedil;in satın alınan &uuml;r&uuml;nler gibi satın alımlarınızla ilgili finansal olmayan bilgileri bizimle paylaşabilir. &Uuml;&ccedil;&uuml;nc&uuml; taraf şirketlerden alınan kişisel veriler, ilgili &uuml;&ccedil;&uuml;nc&uuml; taraf uygulamalardaki gizlilik ayarlarına bağlı olacaktır.</p>
    <p>&nbsp;</p>
    <p><strong>Kişisel Verilerinizin İşlenme Ama&ccedil;ları</strong></p>
    <p><strong>&nbsp;</strong></p>
    <p>Kişisel verilerinizi aşağıdaki ama&ccedil;larla toplar ve kullanırız:</p>
    <p>&nbsp;</p>
    <ul>
      <li>oyunlarımızı sunmamızı sağlamak, oyunlarda satın almalarınızın etkinleştirilmesini sağlamak ve ihtiya&ccedil; duymanız halinde size oyuncu desteği sağlamak i&ccedil;in;</li>
      <li>oyunlarımızı sizin i&ccedil;in ve kullandığınız cihaz i&ccedil;in optimize etmemizi ve ayrıca oyun i&ccedil;i etkinlikler, teklifler ve promosyonlar i&ccedil;in &ouml;zelleştirilmiş sunmamızı sağlamak i&ccedil;in;</li>
      <li>Pazarlama ve reklam ama&ccedil;ları dahil olmak &uuml;zere bu Gizlilik Politikasında belirtilen diğer ama&ccedil;lar i&ccedil;in;</li>
      <li>oyunlarımızı ve diğer Hizmetleri işletmek, iyileştirmek ve optimize etmek;</li>
      <li>Hizmetlerimizi kişiselleştirmek, kullanıcı deneyimini ve Hizmetlerimizi kullanma keyfini geliştirmek ve kullanıcılarımızın deneyimini iyileştirmek i&ccedil;in kullanıcılarımızı ve tercihlerini anlamak;</li>
      <li>Hizmetlerimizin belirli alanlarına, işlevlerine ve &ouml;zelliklerine erişim sağlamak;</li>
      <li>oyun i&ccedil;i g&uuml;ncellemeler, yeni &uuml;r&uuml;nler veya promosyon teklifleri hakkında sizi bilgilendirmek;</li>
      <li>onaylar, teknik bildirimler, g&uuml;ncellemeler ve g&uuml;venlik uyarıları ile destek ve idari mesajlar dahil olmak &uuml;zere ilgili bilgileri g&ouml;ndermek;</li>
      <li>oyuncuların oyunlarda veya sosyal medya hesaplarımızda birbirleriyle iletişim kurmasını sağlamak;</li>
    </ul>
    <p>yorumlarınıza ve sorularınıza yanıt vermek ve m&uuml;şteri destek hizmetleri sağlamak;</p>
    <ul>
      <li>potansiyel olarak yasaklanmış veya yasa dışı faaliyetleri &ouml;nlemek ve oyuncularımızı korumak;</li>
      <li>Hizmetlerimize olan ilgiyi &ouml;l&ccedil;mek i&ccedil;in;</li>
      <li>i&ccedil; kalite kontrol&uuml;n&uuml; sağlamak;</li>
      <li>bizim i&ccedil;in ge&ccedil;erli olan yasalara uymamızı sağlamak, dolandırıcılığı &ouml;nlemek, hizmet koşullarımıza uygunluğu sağlamak veya gerektiğinde Hizmet Koşullarımız kapsamındaki haklarımız da dahil olmak &uuml;zere yasal haklarımızı savunmak, kullanmak veya tesis etmek</li>
      <li>işe alım s&uuml;re&ccedil;lerimizin bir par&ccedil;ası olarak sizinle iletişime ge&ccedil;mek; ve</li>
      <li>aksi bu Gizlilik Politikasında a&ccedil;ık&ccedil;a belirtildiği gibi veya yasaların gerektirdiği şekilde.</li>
    </ul>
    <p>&nbsp;</p>
    <p><strong>Pazarlama</strong></p>
    <p>&nbsp;</p>
    <p>Biz ve &uuml;&ccedil;&uuml;nc&uuml; taraf ortaklarımız, sizin hakkınızda toplanan kişisel verileri, ilgi alanlarınıza dayalı olarak mobil uygulamalarımız ve ilgili Hizmetlerimiz hakkında size pazarlama iletişimleri g&ouml;ndermek i&ccedil;in kullanırız. &Ouml;rneğin, Hizmetlerimiz i&ccedil;inde reklamlar sunabilir veya &uuml;&ccedil;&uuml;nc&uuml; taraf web sitelerine ve uygulamalarına reklam yerleştirebiliriz.</p>
    <p>&nbsp;</p>
    <p>Hakkınızda tuttuğumuz bilgileri <strong>byHorses</strong> Hizmetlerini &ccedil;eşitli şekillerde tanıtmak i&ccedil;in kullanabiliriz. Bu şunları i&ccedil;erebilir:</p>
    <p>&nbsp;</p>
    <ul>
      <li>&uuml;&ccedil;&uuml;nc&uuml; taraf web siteleri ve uygulamaları i&ccedil;inde reklam;</li>
      <li>oynadığınız oyunda hizmetlerimiz i&ccedil;in size &ouml;zel promosyonlar sunmak;</li>
      <li><strong> byHorses</strong> hizmetleri ve oyunlarıyla ilgili size oyun i&ccedil;i reklamlar ve &ouml;zel teklifler sunmak.</li>
    </ul>
    <p>&nbsp;</p>
    <p><strong>Pazarlama I&ccedil;in Hangi Bilgileri Kullanıyoruz?</strong></p>
    <p>&nbsp;</p>
    <p>Sizinle ilgili bilgileri, yalnızca bizden ilginizi &ccedil;ekebilecek pazarlamaları g&ouml;rd&uuml;ğ&uuml;n&uuml;zden emin olmak i&ccedil;in kullanırız. Bu, hakkınızda sahip olabileceğimiz bilgileri kullanmayı i&ccedil;erir, &ouml;rneğin:</p>
    <p>&nbsp;</p>
    <ul>
      <li>cihazınızdan toplanan reklam tanımlayıcıları ve diğer kişisel olmayan veriler;</li>
      <li>oynadığınız oyunlar ve oyunlarımız ve Hizmetlerimizle nasıl etkileşim kurduğunuz hakkında bilgiler;</li>
      <li>&uuml;lke ya da b&ouml;lge; ve</li>
      <li>&uuml;&ccedil;&uuml;nc&uuml; taraf pazarlama ortaklarımızdan veya izninizi almış olan veya bu bilgileri bizimle paylaşmak i&ccedil;in başka bir yasal hakkı olan diğer &uuml;&ccedil;&uuml;nc&uuml; taraflardan elde edebileceğimiz diğer bilgiler.</li>
    </ul>
    <p>&nbsp;</p>
    <p><strong>Pazarlama Ortakları</strong></p>
    <p>&nbsp;</p>
    <p>Oyunlarımızı başka şirketler tarafından yayınlanan medyada pazarladığımızda, bizim adımıza bize yardımcı olması i&ccedil;in &ccedil;eşitli &uuml;&ccedil;&uuml;nc&uuml; taraf pazarlama ortaklarını kullanırız ve bu ama&ccedil;larla sizin hakkınızda topladığımız verileri bu &uuml;&ccedil;&uuml;nc&uuml; taraflarla paylaşabiliriz.</p>
    <p>&nbsp;</p>
    <p>Pazarlama ortaklarımız, bu bilgileri başka bir yerde sizin hakkınızda topladıkları verilerle birleştirerek size reklam sunmamıza yardımcı olabilir. Hizmetlerini veya &uuml;&ccedil;&uuml;nc&uuml; tarafların web sitelerini ve hizmetlerini kullandığınızda bu bilgileri toplarlar. Ortaklarımız bu bilgileri, g&ouml;rmeyi tercih edeceğiniz reklam t&uuml;rleri hakkında varsayımlarda bulunmak i&ccedil;in kullanır. <strong>byHorses</strong> oyunları veya Hizmetleri i&ccedil;in bir reklam g&ouml;rmek isteyebileceğinizi belirlerlerse, siz diğer web sitelerini ve hizmetleri kullanırken size bir <strong>byHorses</strong> reklamı sunacaklar.</p>
    <p>&nbsp;</p>
    <p>Kişisel verilerinizin ilgi alanına dayalı reklamlar almak i&ccedil;in işlenmemesini tercih ederseniz, <strong>info@byhorses.com</strong> adresinden bizimle iletişime ge&ccedil;erek bir vazge&ccedil;me talebinde bulunabilirsiniz . İlgi alanına dayalı veya hedefli reklam almayı bırakırsanız, yine de reklam g&ouml;receğinizi ve <strong>byHorses</strong> &uuml;r&uuml;nleri i&ccedil;in reklamlar g&ouml;rebileceğinizi, ancak bu reklamların kişisel verileriniz kullanılarak hedeflenmediğini unutmamalısınız.</p>
    <p>&nbsp;</p>
    <p>Bu Gizlilik Politikasını kabul ederek ve oyunlarımızı indirerek veya oynamaya devam ederek ve bu b&ouml;l&uuml;mde a&ccedil;ıklandığı gibi ilgi alanına dayalı reklamcılığı devre dışı bırakmadığınız s&uuml;rece, pazarlama ortaklarımızın reklam, hedefleme ve &ouml;l&ccedil;&uuml;m sistemlerini iyileştirmek i&ccedil;in sizinle ilgili bilgileri toplamasına ve kullanmasına izin vermiş olursunuz. gizlilik politikalarında a&ccedil;ıklandığı gibi.</p>
    <p>Bu Gizlilik Politikası, verilerinizin pazarlama ortaklarımız tarafından toplanması i&ccedil;in ge&ccedil;erli değildir. Daha fazla bilgi i&ccedil;in ortaklarımızın gizlilik politikalarını incelemenizi &ouml;neririz.</p>
    <p>&nbsp;</p>
    <p><strong>&Uuml;&ccedil;&uuml;nc&uuml; Taraflar Tarafından Kullanılan &Ccedil;erezler</strong></p>
    <p>&nbsp;</p>
    <p>Bizim adımıza hareket eden hizmet sağlayıcılar, Hizmetler aracılığıyla otomatik olarak bilgi toplamak i&ccedil;in &ccedil;erezleri veya benzer teknolojileri kullanabilir. &Ccedil;erezler, tercihlerini saklamak i&ccedil;in kullanıcıların bilgisayar tarayıcılarına yerleştirilen k&uuml;&ccedil;&uuml;k metin dosyalarıdır. Bu t&uuml;r hizmet sağlayıcılara ait &ccedil;erezler, Web Sitemizi veya Hizmetlerimizi kullanırken cihazınıza yerleştirilebilir. Web Sitesi ile ilgili &ccedil;erezler, hizmet sağlayıcılar tarafından aşağıda belirtilen ama&ccedil;lar i&ccedil;in kullanılacaktır:</p>
    <p>&nbsp;</p>
    <ul>
      <li><strong> Operasyonel Olarak Gerekli.</strong> &Ccedil;erezler, Web Sitesinin işlevselliği i&ccedil;in kullanılabilir.</li>
      <li><strong> Performansla İlgili.</strong> &Ccedil;erezler, ziyaret&ccedil;ilerimizin Web Sitelerimizi nasıl kullandığını anlamak da dahil olmak &uuml;zere Web Sitemizin performansını değerlendirmek i&ccedil;in kullanılabilir.</li>
      <li><strong> İşlevsellikle İlgili.</strong> &Ccedil;erezler, Web Sitemize erişirken veya Web Sitemizi kullanırken işlevsellik sağlamak i&ccedil;in kullanılabilir.</li>
    </ul>
    <p>&nbsp;</p>
    <p>Web tarayıcınızı, bilgisayarınıza &ccedil;erez yerleştirme girişimleri hakkında sizi uyaracak veya izin verdiğiniz &ccedil;erez t&uuml;rlerini sınırlandıracak şekilde ayarlayabilirsiniz. Flash &Ccedil;erezler, tarayıcı &ccedil;erezlerinden farklı şekilde &ccedil;alışır ve bir web tarayıcısında bulunan &ccedil;erez y&ouml;netim ara&ccedil;ları, flash &ccedil;erezleri kaldıramayabilir.</p>
    <p>&nbsp;</p>
    <p>&Ccedil;erezleri devre dışı bırakırsanız, faaliyetlerinizi izlemek ve geliştirmek i&ccedil;in &ccedil;erezler gerekli olduğundan Hizmetlerimizin bazı &ouml;zelliklerini ve işlevlerini kaybedebileceğinizi l&uuml;tfen unutmayın.</p>
    <p>&nbsp;</p>
    <p><strong>Haklarınız</strong></p>
    <p>&nbsp;</p>
    <p>Kişisel verilerinizle ve bunları nasıl ele aldığımızla ilgili belirli haklara sahipsiniz. Aşağıdaki Bize Ulaşın b&ouml;l&uuml;m&uuml;nde belirtilen y&ouml;ntemlerden herhangi biriyle bizimle iletişime ge&ccedil;erek bu hakları istediğiniz zaman kullanabilirsiniz.</p>
    <p>&nbsp;</p>
    <p>Bu haklar şunları i&ccedil;erir:</p>
    <p>&nbsp;</p>
    <ul>
      <li><strong> Kişisel verilere erişim: byHorses</strong>&rsquo;ın kullandığı kişisel verilerinize erişme hakkına sahipsiniz.</li>
      <li><strong> Kişisel verilerin d&uuml;zeltilmesi veya silinmesi: </strong>Yanlış veya eksik olması durumunda<strong> byHorses</strong>&rsquo;dan kişisel verilerinizi d&uuml;zeltmesini veya tamamlamasını isteme hakkınız vardır. Belirli koşullar ge&ccedil;erli olduğunda <strong>byHorses</strong>&rsquo;dan kişisel verilerinizi silmesini isteme hakkınız da olabilir.</li>
      <li><strong> Hesabın devre dışı bırakılması:</strong> Hesabınızı tamamen devre dışı bırakmak isterseniz, Facebook hesabınızın mobil oyunlarımızdan bağlantısını kesmeyi ve oyunlarımızı mobil cihazlarınızdan silmeyi i&ccedil;erir. L&uuml;tfen hesabınızı tamamen devre dışı bırakırsanız, oyunlarımızdaki t&uuml;m ilerlemenizin ve harcanmamış sanal &ouml;ğelerin kaybolacağını ve bunları gelecekte geri y&uuml;kleyemeyebileceğimizi l&uuml;tfen unutmayın.</li>
      <li><strong> Kişisel verilerin işlenmesine ilişkin kısıtlama:</strong> Belirli koşullar ge&ccedil;erli olduğunda <strong>byHorses</strong>&rsquo;dan kişisel verilerinizin kullanımını kısıtlamasını isteme hakkınız olabilir.</li>
      <li><strong> Kişisel verilerin işlenmesine itiraz: </strong>Belirli koşulların ge&ccedil;erli olduğu durumlarda kişisel verilerinizin <strong>byHorses</strong> tarafından kullanılmasına itiraz etme hakkınız olabilir.</li>
      <li><strong> Kişisel verilerin taşınabilirliği: </strong>Belirli koşulların ge&ccedil;erli olduğu durumlarda, bu verileri başka bir şirkette kullanabilmeniz i&ccedil;in kişisel verilerinizi yapılandırılmış ve yaygın olarak kullanılan bir formatta alma hakkınız olabilir.</li>
      <li><strong> Vazge&ccedil;me hakkı (California, ABD): </strong>California&rsquo;da ikamet edenler, reklamları sizin i&ccedil;in kişiselleştirmek i&ccedil;in &uuml;&ccedil;&uuml;nc&uuml; taraflara veri aktarımını da i&ccedil;erebilecek şekilde tanımlanan kişisel verilerin satışından vazge&ccedil;me hakkına sahiptir. Verilerinizin &lsquo;satışını&rsquo; devre dışı bırakırsanız, reklam ortaklarımız da dahil olmak &uuml;zere verilerinizi satmayacağız, ancak yine de oyunda reklamlar g&ouml;rebilirsiniz ve bazı ortaklarımız, verdikleri bilgilere dayanarak size reklamlar sunabilir. sana tutun.</li>
    </ul>
    <p>&nbsp;</p>
    <p>Hakkınızda topladığımız kişisel verileri kontrol etmenin başka yolları da vardır. &Ouml;rneğin, Facebook veya diğer sosyal ağ hesabınızın oyunlarımızla bağlantısını kesebilir veya oyunlarımızı mobil cihazınızdan silebilirsiniz. Ayrıca, telefonunuzdaki ayarları kullanarak mobil reklam tanımlayıcınızı sıfırlayabilir veya reklam izlemeyi tamamen sınırlayabilirsiniz.</p>
    <p>&nbsp;</p>
    <p>Oyun g&uuml;ncellemeleri, y&uuml;ksek puanlar ve ilginizi &ccedil;ekebilecek diğer bildirimler ile size zaman zaman mobil uygulamalarımız aracılığıyla anlık bildirimler g&ouml;nderebiliriz. Mobil cihazınızdaki ayarları değiştirerek bu t&uuml;r iletişimleri almaktan istediğiniz zaman vazge&ccedil;ebilirsiniz.</p>
    <p>&nbsp;</p>
    <p><strong>Verilerinizi k&ouml;t&uuml;ye kullandığımızı veya herhangi bir veri koruma d&uuml;zenlemesini ihlal ettiğimizi d&uuml;ş&uuml;n&uuml;yorsanız, &ouml;rneğin ikamet ettiğiniz yerin Kurumu gibi bir Veri Koruma Kurumuna şikayette bulunabilirsiniz.</strong></p>
    <p><strong>&nbsp;</strong></p>
    <p><strong>&Ccedil;ocuklar</strong></p>
    <p>&nbsp;</p>
    <p>Oyunlarımızı oynamak ve Hizmetlerimizi kullanmak i&ccedil;in 13 yaşından b&uuml;y&uuml;k olmalısınız. 13 yaşından k&uuml;&ccedil;&uuml;k hi&ccedil; kimseden bilerek ve isteyerek kişisel veri toplamıyoruz. Ancak Hizmetler bu kişi tarafından bu Gizlilik Politikasına aykırı olarak kullanılırsa, bu kişiden kişisel veriler toplayabiliriz. 13 yaşından k&uuml;&ccedil;&uuml;k (veya Avrupa Birliği &uuml;lkeleri ve/veya b&ouml;lgelerindeki belirli yargı alanlarında 16 yaşından k&uuml;&ccedil;&uuml;k) bir &ccedil;ocuktan veya bu &ccedil;ocuk hakkında herhangi bir bilgiye sahip olabileceğimizi d&uuml;ş&uuml;n&uuml;yorsanız, l&uuml;tfen Bize Ulaşın.</p>
    <p>&nbsp;</p>
    <p><strong>G&uuml;venlik</strong></p>
    <p>&nbsp;</p>
    <p><strong>byHorses</strong>&rsquo;a sağladığınız kişisel veriler, g&uuml;venli tesislerde bulunan ve g&uuml;venlik duvarları ve veri şifreleme gibi bu t&uuml;r verilerin end&uuml;stri standardı g&uuml;venliğini sağlamak i&ccedil;in tasarlanmış protokoller ve prosed&uuml;rlerle korunan sunucularda depolanır. Ancak internet &uuml;zerinden hi&ccedil;bir sunucu, bilgisayar veya iletişim ağı veya sistem veya veri iletiminin %100 g&uuml;venli olduğu garanti edilemez. Sonu&ccedil; olarak, <strong>byHorses</strong> kişisel verilerinizi korumaya &ccedil;alışırken, <strong>byHorses</strong> veya Hizmetler aracılığıyla ilettiğiniz herhangi bir kişisel verinin g&uuml;venliğini garanti edemez veya garanti edemez.</p>
    <p>&nbsp;</p>
    <p>Hizmetleri kullanarak veya bize kişisel veriler sağlayarak, g&uuml;venlik gizliliği ve Hizmetleri kullanımınızla ilgili idari konular hakkında sizinle elektronik olarak iletişim kurabileceğimizi kabul etmektesiniz. Bir g&uuml;venlik sisteminin ihlalini &ouml;ğrenirsek, Hizmetlerde bir bildirim yayınlayarak veya size bir e-posta g&ouml;ndererek sizi elektronik olarak bilgilendirmeye &ccedil;alışabiliriz.</p>
    <p>&nbsp;</p>
    <p><strong>Yasal Dayanaklar (Avrupa Ekonomik Alanı kullanıcıları i&ccedil;in)</strong></p>
    <p>&nbsp;</p>
    <p>Avrupa Ekonomik Alanı&rsquo;nda (AEA) bir bireyseniz, sizinle ilgili kişisel verileri yalnızca ge&ccedil;erli AB yasaları uyarınca yasal dayanaklarımız olduğunda toplar ve işleriz. Dayandığımız yasal dayanaklar şunlardır:</p>
    <p>&nbsp;</p>
    <ul>
      <li><strong> S&ouml;zleşme Gerekliliği.</strong> Bu, oyunlarımız, m&uuml;şteri hizmetlerimiz, sohbet işlevlerimiz ve &uuml;&ccedil;&uuml;nc&uuml; taraf sosyal ağ bağlantılarımız gibi talep ettiğiniz &ndash; ve bizim kabul ettiğimiz &ndash; Hizmetleri size sağlamak i&ccedil;in tarafımızca kullanılan verileri kapsar;</li>
      <li><strong> Yasal zorunluluk.</strong> Bu, vergiyle ilgili kayıtları tutmak veya belirli veriler i&ccedil;in ge&ccedil;erli bir yasal talebe yanıt vermek gibi yasal bir y&uuml;k&uuml;ml&uuml;l&uuml;ğe uymak i&ccedil;in tarafımızca kullanılan verileri kapsar;</li>
      <li><strong> Meşru Menfaatler.</strong> Bu, pazarlama, reklamcılık, analitik, oyunlarımızın ve diğer Hizmetlerin araştırılması ve optimizasyonu gibi, gizliliğinize zarar vermeyen ve Hizmetlerimizi kullanımınız bağlamında makul olarak beklenebilecek ama&ccedil;lar i&ccedil;in tarafımızca kullanılan verileri kapsar; ve</li>
      <li>Bu, aşağıdaki bağlamlarda yasal dayanak olarak kullanılır: cihaz bilgileri, reklam tanımlayıcıları, IP adresi, &uuml;lke ve b&ouml;lge dahil olmak &uuml;zere bizim ve reklam ortaklarımız tarafından cihazınızda depolanan verilerin toplanması. Oyunumuzu y&uuml;klemeyi kabul ettiğinizde bu koleksiyona onay vermiş olursunuz ve oyunlarımızı kaldırarak buna ilişkin onayınızı geri &ccedil;ekebilirsiniz.</li>
    </ul>
    <p>&nbsp;</p>
    <p>Bu Gizlilik Politikasında, &uuml;stlendiğimiz belirli işleme t&uuml;rlerinden nasıl vazge&ccedil;ebileceğinizi ve herhangi bir işlemin sizin izninize bağlı olduğu durumlarda, bu izni istediğiniz zaman bu Gizlilik Politikasında a&ccedil;ıklanan şekillerde geri &ccedil;ekebileceğinizi a&ccedil;ıkladık. Bununla birlikte, oyunumuzu oynarsanız, sizinle ilgili bazı kişisel verileri toplamaya devam edeceğiz ve bunları bu Gizlilik Politikasında aksi şekilde a&ccedil;ıklandığı şekilde işleyeceğiz. Hakkınızda herhangi bir kişisel veri toplamamızı istemiyorsanız, oyunlarımızı oynamayı ve Hizmetlerimizi kullanmayı bırakmalısınız.</p>
    <p>&nbsp;</p>
    <p><strong>Tutulma</strong></p>
    <p>&nbsp;</p>
    <p>Verilerinizi, hesabınız aktif olduğu veya size Hizmeti sağlamak i&ccedil;in gerekli olduğu s&uuml;rece saklarız.</p>
    <p>Kişisel verilerinizi kaldırmamızı isterseniz, verilerinizi yasal y&uuml;k&uuml;ml&uuml;l&uuml;klerimize uymak, anlaşmazlıkları &ccedil;&ouml;zmek ve s&ouml;zleşmelerimizi uygulamak gibi meşru ticari &ccedil;ıkarlarımız i&ccedil;in gerektiği şekilde tutacağımızı unutmayın.</p>
    <p>&nbsp;</p>
    <p><strong>Bu Gizlilik Politikasındaki Değişiklikler</strong></p>
    <p>&nbsp;</p>
    <p>Bu Gizlilik Politikasını zaman zaman g&uuml;ncelleyebiliriz ve g&uuml;ncellenmiş Gizlilik Politikasını bu sayfada yayınlayacağız. Herhangi bir yeni politika, tarafımızca yayınlandığı tarihten itibaren ge&ccedil;erli olacaktır.</p>
    <p>&nbsp;</p>
    <p><strong>Bize Ulaşın</strong></p>
    <p>&nbsp;</p>
    <p>Bizimle iletişim kurmak ve/veya bu Gizlilik Politikasında belirtilen haklarınızı kullanmak i&ccedil;in l&uuml;tfen bizimle şu adresten iletişime ge&ccedil;in:</p>
    <p><strong>info@byhorses.com</strong></p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
  </>
)
const policyEN = (
  <>
    <h2><strong>byHorses PRIVACY POLICY</strong></h2>
    <p>&nbsp;</p>
    <p><strong>Intro</strong></p>
    <p>&nbsp;</p>
    <p>As <strong>BYHORSES</strong> (&ldquo;<strong>byHorses</strong>&rdquo; or &ldquo;<strong>we</strong>&rdquo;, &ldquo;<strong>us</strong>&rdquo;) we are a technology company that designs and develops games. With this Privacy Policy, we aim to transparently explain how and why we gather, store, share and use your personal data in a manner consistent with the laws of the countries in which we do business. Your privacy and the security of your personal data is, and will always be, enormously important to us. Where this Policy refers to &ldquo;personal data&rdquo; it is referring to any information that relates to an identified or identifiable living individual.</p>
    <p>&nbsp;</p>
    <p>This Privacy Policy applies to websites, mobile applications, forums and every kind of blogs of <strong>byHorses</strong> and any other online or offline services provided by <strong>byHorses</strong>. It also applies to our marketing and advertising activities on all platforms and other services that we may provide to you from time to time. In this Privacy Policy we refer to our games, websites, marketing and advertising activities and other services collectively as our &ldquo;<strong>Services</strong>&ldquo;.</p>
    <p>&nbsp;</p>
    <p>By downloading our mobile application, accessing and/or playing our games, or interacting with our websites or other Services, you agree to the collection and use of your personal data in accordance with this Privacy Policy. If you have any other concerns about providing data to us or it being used as described in this Privacy Policy you should not use our games or other Services. This Privacy Policy is intended to meet requirements globally, including those in North America, Europe, Turkey, Asia-Pacific countries and other jurisdictions.</p>
    <p>&nbsp;</p>
    <p><strong>How We Collect Your Personal Data</strong></p>
    <p>&nbsp;</p>
    <p>We may collect personal data directly from you or other third-party companies who have obtained your consent or have another legal right to share such personal with us (including advertising platforms and partners and data aggregators who have obtained). This may include attributes about you and your interests, as well as other games and services you use, demographic and general location information. We will use your personal data as described in this Privacy Policy.</p>
    <p>&nbsp;</p>
    <p><strong>Personal Data Collected Directly from You</strong></p>
    <p>&nbsp;</p>
    <p>By downloading, accessing and/or playing our games or using our other Services, you consent to the collection of your personal data by <strong>byHorses</strong>. Most of this data comes directly from you or your device, such as your unique device ID (persistent/non-persistent), your device operating system, version, model, CPU, amount of RAM and disk space, hardware type, Identifier For Advertising (IDFA), advertising ID, the version of your operating system (&ldquo;OS&rdquo;), your device name, game center ID, and your location based on your Internet Protocol (&ldquo;IP&rdquo;). We use this data to provide our games and Services to you, including for the purposes of optimization, customizing and in-game offers and verifying purchases. We may also use it for the other purposes set out in this Privacy Policy.</p>
    <p>&nbsp;</p>
    <p>Depending on which of our Services you interact with we may collect and process the following types of information:</p>
    <p>&nbsp;</p>
    <p>details about how you use and interact with our games, advertising and other Services (for example, information about how and when you play our games or visit our website(s), what device you use to access our games and Services or details regarding profile visits;</p>
    <p>&nbsp;</p>
    <ul>
      <li>your interactions with us on our social media channels.</li>
    </ul>
    <p>If you choose not to create a <strong>byHorses</strong> profile by connecting your Facebook account, the personal data that we collect when you play our games will be anonymous, but we will collect device level identifiers and other information associated with those identifiers including:</p>
    <p>&nbsp;</p>
    <ul>
      <li>the type of device(s) you are using to play our games or access our Services, identifiers such as IP address, device identifiers, ad identifiers, a <strong>byHorses</strong>- player ID, and the country or region that you are playing in; and</li>
    </ul>
    <p>&nbsp;</p>
    <ul>
      <li>how you play our games and interact with our websites (such as level start, level end, session start, booster use, spending, rewards, your progress through the games, session time and duration, number of attempts at each level and purchases made).</li>
    </ul>
    <p>&nbsp;</p>
    <p><strong>Disclosure of Your Personal Data</strong></p>
    <p>&nbsp;</p>
    <p>We may share your information as described in this Privacy Policy (to provide the Services with our Third-party service providers; to comply with legal obligations; to protect and defend our rights and property) with your consent.</p>
    <p>&nbsp;</p>
    <ul>
      <li><strong> Service Providers. </strong>We may share your personal data with our service providers. The types of service providers (data processors) to whom we entrust include companies that provides (i) provision IT and related services; (ii) provision of information and services you have requested; (iii) customer service activities; (iv) analytics and search engine activities that assist us in the improvement and optimization of the Services; and (v) any supporting activities in connection with our Services. <strong>byHorses</strong> has executed appropriate contracts with the service providers that prohibit them from using or sharing personal data except as necessary to perform the contracted services on our behalf or to comply with applicable legal requirements.</li>
    </ul>
    <p>&nbsp;</p>
    <ul>
      <li><strong> Disclosures to Protect Rights of Us, You or Others (e.g., as Required by Law and Similar Disclosures)</strong>. We may access, preserve, and disclose your personal data, other account information and content if we believe doing so is required or appropriate: (i) to comply with law enforcement or national security requests and legal process, such as a court order or subpoena; (ii) to respond to your requests; (iii) to protect yours, ours or others&rsquo; rights, property or safety; (iv) to enforce <strong>byHorses</strong>&rsquo; policies or contracts; (v) to collect amounts owed to <strong>byHorses</strong>; (vi) when we believe disclosure is necessary or appropriate to prevent physical harm or financial loss or in connection with an investigation or prosecution of suspected or actual illegal activity; or (vii) if we, in good faith, believe that disclosure is otherwise necessary or advisable.</li>
    </ul>
    <p>&nbsp;</p>
    <p>In addition, from time to time, server logs may be reviewed for security purposes (e.g., to detect unauthorized activity on the Services). In such cases, server log data containing IP addresses may be shared with law enforcement bodies, so they may identify users in connection with their investigation of the unauthorized activities.</p>
    <p>&nbsp;</p>
    <ul>
      <li><strong> Sale of Assets and Change of Control</strong>. If there is a sale of the assets of <strong>byHorses</strong> or corporate restructuring, or as a result of a change of control in shareholding structure of <strong>byHorses</strong>, or in preparation of any of these events. Any third party to which <strong>byHorses</strong> transfers of sells <strong>byHorses</strong>&rsquo; assets will have the right to continue to use the personal and other information that you provide to us in the manner set out in this Privacy Policy.</li>
    </ul>
    <p>&nbsp;</p>
    <p><strong>International Transfer</strong></p>
    <p>&nbsp;</p>
    <p>All personal data collected or stored by <strong>byHorses</strong> may be stored anywhere in the world, including, but not limited to, in Republic of Turkey, the United States, the European Union, in the cloud, on our servers and on the servers of our service providers. When we transfer your personal data internationally, we will take reasonable steps to ensure that it is treated securely, lawfully and in the manner, we describe here. Please note that laws vary from jurisdiction to jurisdiction, so the privacy laws applicable to the places where your personal is transferred to or stored, used or processed in may be different from the privacy laws applicable to the place where you are resident.</p>
    <p>&nbsp;</p>
    <p>By providing your personal data to us, you consent to the storage of your personal data in above-mentioned locations.</p>
    <p>&nbsp;</p>
    <p>If you are using our Services from the European Union or other regions with laws governing data collection and use, please note that you are agreeing to the transfer of your personal data to Turkey and the United States and to processing of your data globally.</p>
    <p>&nbsp;</p>
    <p>By choosing to visit the Website, utilize the Services or otherwise provide information to us, you agree that any dispute over privacy or the terms contained in this Privacy Policy will be governed by the laws of Republic of Turkey and the adjudication of any disputes arising in connection with <strong>byHorses</strong> or the Website will be in accordance with the terms of Terms of Service.</p>
    <p>&nbsp;</p>
    <p><strong>Providing and optimizing our Services</strong></p>
    <p>&nbsp;</p>
    <p>We use your personal data to enable us to provide our websites, advertising and other Services to you and to optimize all of them so that we can make them the best they can be for you and all our players. This will include use and analysis of aggregated data to make sure that our websites work properly on all devices and are as enjoyable as they can be for all our players.</p>
    <p>&nbsp;</p>
    <p><strong>Customer Services</strong></p>
    <p>&nbsp;</p>
    <p>We use your personal data, including data that you provide directly to us in communications, to provide you with support through our customer service channels when you need it. We may also use it to contact you, for example as part of customer service or to send you updates about our games and Services. Finally, we will use your data to manage your account and relationship with us and improve your experience when you use our Services.</p>
    <p>&nbsp;</p>
    <p><strong>User Reviews</strong></p>
    <p>&nbsp;</p>
    <p>From time to time, you may share your comments about our games and provide certain information that may include personal data. All personal data collected from your participation in such reviews is provided by you voluntarily. We may use such personal data to improve our products, websites and/or Services and in any manner consistent with the policies provided herein.</p>
    <p>&nbsp;</p>
    <p><strong>Chat</strong></p>
    <p>&nbsp;</p>
    <p>Some of our Services include chat functionality. Users of our chat service(s) can send messages to other players. Your messages may be used and stored by us: (a) to convey your messages; and (b) to enable you and your recipients to view your message history (although please note that after the relevant period of time which is determined by us, your in-game chat messages will no longer be accessible). We reserve the right to review the content of your messages, including by using automatic filters, to ensure that you are complying with our terms of service and we also reserve the right to prevent your use of our chat service(s) or to block the sending of any message for any reason.</p>
    <p>&nbsp;</p>
    <p><strong>Ad identifiers, Software Development Kits and Similar Technologies</strong></p>
    <p>&nbsp;</p>
    <p>To provide our games, Websites and interest-based advertising, we use ad identifiers, software development kits and similar technologies. This section describes our use of these technologies.</p>
    <p>&nbsp;</p>
    <p>Ad identifiers and similar technologies are used by us to provide our Services to you and in order to collect data from your device. These technologies allow us and our third-party advertising partners to:</p>
    <p>&nbsp;</p>
    <ul>
      <li>recognize you and your device;</li>
      <li>provide interest-based advertising to you;</li>
      <li>allow our Services to interact with a third-party social network or platform (where you have chosen to allow such interaction);</li>
      <li>enable us and third parties to provide you with more customized services, for example to provide our Services in the correct language.</li>
    </ul>
    <p>&nbsp;</p>
    <p><strong>How do ad identifiers and similar technologies work?</strong></p>
    <p>&nbsp;</p>
    <p>Ad identifiers are non-permanent, non-personal identifiers such as the Android advertising ID and/or Apple&rsquo;s ID for advertising, which are uniquely associated with your device. Similar technologies include tracking pixels within ads. These technologies allow companies (including marketers and advertisers) to recognize your device when you use Websites and applications.</p>
    <p>&nbsp;</p>
    <p><strong>How to opt out from interest-based advertising on your device and Facebook</strong></p>
    <p>&nbsp;</p>
    <p>Opting out of interest-based advertising at a device level: You can choose to prevent your device&rsquo;s ad identifier being used for interest-based advertising, or you can reset your device&rsquo;s ad identifier, by changing the settings of your device. Settings vary from device to device but you will usually find the ad identifier settings under &ldquo;privacy&rdquo; or &ldquo;ads&rdquo; in your device&rsquo;s settings.</p>
    <p>&nbsp;</p>
    <p>Opting out of Facebook advertising: To update your advertising preferences with Facebook, you will need to log in to your account on Facebook and update your settings there.</p>
    <p>&nbsp;</p>
    <p><strong>Analytics and Research</strong></p>
    <p>&nbsp;</p>
    <p>We use analytics tools, which may include third party analytics tools, to collect information about how you play our games or use our Services. Those tools may use cookies or other similar tracking technologies. We may use your data to carry out research, surveys or to engage directly with you, for example by sending you communications (including by email), for these purposes.</p>
    <p>&nbsp;</p>
    <p><strong>Promotions and Competitions</strong></p>
    <p>&nbsp;</p>
    <p>From time to time we may run promotions or competitions during which we may collect certain personal data from you, such as your name, address, post code, email address or other information or content submitted in order for you to claim a prize. Those competitions will have their own specific terms and conditions, but we will use personal data that you provide in relation to that competition for the purposes of running the competition.</p>
    <p>&nbsp;</p>
    <p><strong>Cheating, Crime and Fraud Prevention</strong></p>
    <p>&nbsp;</p>
    <p>We may use personal data that we hold to prevent cheating, crime or fraud. We may also need to pass your information to fraud prevention agencies and other organizations involved in crime and fraud prevention, such as the police.</p>
    <p>&nbsp;</p>
    <p><strong>Legal Uses</strong></p>
    <p>&nbsp;</p>
    <p>We may use your data as required or permitted by any applicable law.</p>
    <p>&nbsp;</p>
    <p><strong>Visiting Our Websites</strong></p>
    <p>&nbsp;</p>
    <p>When you visit our website <strong>https://www.byhorses.com/</strong>&nbsp; (&ldquo;<strong>Website</strong>&rdquo;), we collect and process below-mentioned personal data about you. Any information provided to us when filling out the job application form on our Website, or when you upload a CV and provide additional links.</p>
    <p>&nbsp;</p>
    <p><strong>Personal Data Collected from Third Parties</strong></p>
    <p>&nbsp;</p>
    <p>As mentioned above, most of the information that we collect about you comes directly from you when you play our games, or interact with our Websites, advertising or other Services. However, when you allow third party companies to access to your personal data (e.g., downloading our mobile applications through App Store, Google Store and linking your Facebook account to mobile applications), we also collect information from such third parties.</p>
    <p>&nbsp;</p>
    <p><strong>Connection of Facebook Account</strong></p>
    <p>&nbsp;</p>
    <p>When you log in to your <strong>byHorses</strong> profile using your Facebook account details, we will receive some of your Facebook account information. If you choose to play our games online, your basic Facebook account information will automatically be associated with your <strong>byHorses</strong> profile. Exactly what information we receive is Facebook ID, profile picture and your name. If you do this, you will be able to use your account to log in on multiple devices and synchronize progress across those devices. Where available, you will also be able to see which of your friends are playing the same game and their progress through the games (and they will be able to see you and your progress), or access other functionality we make available in our games and other Services, such as inviting friends to play.</p>
    <p>&nbsp;</p>
    <p>We will also collect the email account associated with your Facebook account and the name and avatar of those of your Facebook friends that are already playing our games. You can choose not to allow us to access your email address in the Facebook dialog box the first time at which you associate your Facebook account with your <strong>byHorses</strong> profile.</p>
    <p>&nbsp;</p>
    <p>Logging in with Facebook will not allow us to post anything to your page without your permission and we will not receive any information about your Facebook friends who do not play our game. If you do not wish your Facebook friends to be able to see you and your progression in our game, you can prevent this by disconnecting from Facebook from our game and updating your app permissions from within your Facebook settings.</p>
    <p>&nbsp;</p>
    <p>We would recommend that you read Facebook&rsquo;s Privacy Policy (https://www.facebook.com/privacy/explanation) and review your privacy settings from time to time to make sure that you understand fully how your Facebook data is shared.</p>
    <p>&nbsp;</p>
    <p><strong>Installation of our Mobile Applications via App Store and Google Store</strong></p>
    <p>&nbsp;</p>
    <p>When you install or purchase items related to our mobile apps (such as those you can purchase/download via App Store or Google Store), <strong>byHorses</strong> does not have access to your credit card or bank account data. However, third party payment processors stated above may share non-financial information with us related to your purchases, like the items purchased to fulfill your order. The personal data received from the third-party companies will depend on privacy settings on relevant third-party applications.</p>
    <p>&nbsp;</p>
    <p><strong>Purposes for Processing of Your Personal Data</strong></p>
    <p>&nbsp;</p>
    <p>We collect and use your personal data for the following purposes:</p>
    <p>&nbsp;</p>
    <ul>
      <li>to enable us to provide our games, to ensure that your purchases are activated in the games, and to provide you with player support if you need it;</li>
      <li>to enable us to optimize our games for you and for the device that you are using, as well as to deliver customized in game events, offers and promotions;</li>
      <li>For the other purposes as set out in this Privacy Policy, including for marketing and advertising purposes;</li>
      <li>to operate, improve and optimize our games and other Services;</li>
      <li>to personalize our Services, understand our users and their preferences to enhance user experience and enjoyment using our Services and improve our users&rsquo; experience;</li>
      <li>to provide access to certain areas, functionalities and features of our Services;</li>
      <li>to notify you about in-game updates, new products or promotional offers;</li>
      <li>to send related information, including confirmations, technical notices, updates, and security alerts and support and administrative messages;</li>
      <li>to enable players to communicate with each other in games or on our social media accounts;</li>
      <li>to respond to your comments and inquiries and provide customer support services;</li>
      <li>to prevent potentially prohibited or illegal activities and protect of our players;</li>
      <li>to measure interest in our Services;</li>
      <li>to ensure internal quality control;</li>
      <li>to enable us to comply with laws that apply to us, to prevent fraud, to ensure compliance with our terms of service or where necessary to defend, exercise or establish our legal rights including our rights under our Terms of Service (https://byhorses.com/terms).</li>
      <li>to contact you as a part of our recruitment processes; and</li>
      <li>as otherwise expressly set out in this Privacy Policy or as required by law.</li>
    </ul>
    <p>&nbsp;</p>
    <p><strong>Marketing</strong></p>
    <p>&nbsp;</p>
    <p>We and our third-party partners use personal data collected about you to send you marketing communications about our mobile applications and related Services based on your interests. For example, we may serve ads within our Services or place ads on third party websites and apps.</p>
    <p>&nbsp;</p>
    <p>We may use the information that we hold about you to promote <strong>byHorses</strong>&rsquo; Services in a number of ways. This might include:</p>
    <p>&nbsp;</p>
    <ul>
      <li>advertising within third party websites and apps;</li>
      <li>offering you customized promotions for our services in the game you play;</li>
      <li>offering you in-game advertisements and special offers regarding the <strong>byHorses</strong> services and games.</li>
    </ul>
    <p>&nbsp;</p>
    <p><strong>What information do we use for marketing?</strong></p>
    <p>&nbsp;</p>
    <p>We use information about you to try to make sure you only see marketing from us that might be of interest to you. This includes using information that we may have about you, such as:</p>
    <p>&nbsp;</p>
    <ul>
      <li>ad identifiers and other non-personal data collected from your device;</li>
      <li>the games you play and information about how you interact with our games and Services;</li>
      <li>country or region; and</li>
      <li>other information that we might acquire from our third-party marketing partners or other third parties who have obtained your consent or have another legal right to share that information with us.</li>
    </ul>
    <p>&nbsp;</p>
    <p><strong>Marketing Partners</strong></p>
    <p>&nbsp;</p>
    <p>When we market our games in media published by other companies, we use various third-party marketing partners to assist us on our behalf, and we may share data which we have collected about you with such third parties for these purposes.</p>
    <p>&nbsp;</p>
    <p>Our marketing partners may help us to serve ads to you by combining this information with data which they have collected about you elsewhere. They collect this information when you use their services or the websites and services of third parties. Our partners use this information to make assumptions about the kinds of advertising you would prefer to see. If they determine that you would be interested to see an advert for <strong>byHorses</strong>&rsquo; games or Services, they will serve you with a <strong>byHorses</strong> ad while you are using other websites and services.</p>
    <p>&nbsp;</p>
    <p>If you would prefer that your personal data is not processed to receive interest-based ads, you can submit an opt-out request by contacting us via <strong>info@byhorses.com</strong>. You should note that if you opt out of receiving interest based or targeted advertising, you will still see advertising and you may see advertising for <strong>byHorses</strong> products, but that advertising will not be targeted using your personal data.</p>
    <p>&nbsp;</p>
    <p>By accepting this Privacy Policy and downloading or continuing to play our games, and unless you opt out of interest-based advertising as explained in this section, you consent to our marketing partners collecting and using information about you to improve advertising, targeting and measurement systems as described in their privacy policies.</p>
    <p>This Privacy Policy does not apply to the collection of your data by our marketing partners. We recommend that you review our partners&rsquo; privacy policies for more information.</p>
    <p>&nbsp;</p>
    <p><strong>Cookies Used by the Third Parties</strong></p>
    <p>&nbsp;</p>
    <p>Service providers acting on our behalf, may use cookies or similar technologies to automatically collect information through the Services. Cookies are small text files placed in users&rsquo; computer browsers to store their preferences. Cookies belonging to such service providers may be set on your device when using our Website or Services. Cookies relating to the Website will be used by service providers for the purposes set forth below:</p>
    <p>&nbsp;</p>
    <ul>
      <li><strong> Operationally Necessary</strong>. Cookies may be used for the functionality of the Website.</li>
      <li><strong> Performance Related</strong>. Cookies may be used to assess the performance of our Website, including as part of to understand how our visitors use our Websites.</li>
      <li><strong> Functionality Related</strong>. Cookies may be used to provide functionality when accessing or using our Website.</li>
    </ul>
    <p>&nbsp;</p>
    <p>You can set your web browser to warn you about attempts to place cookies on your computer or limit the type of cookies you allow. Flash Cookies operate differently than browser cookies, and cookie management tools available in a web browser may not remove flash cookies.</p>
    <p>&nbsp;</p>
    <p>Please be advised that if you disable cookies, you may lose some of the features and functionality of our Services because the cookies are necessary to track and enhance your activities.</p>
    <p>&nbsp;</p>
    <p><strong>Your Rights</strong></p>
    <p>&nbsp;</p>
    <p>You have certain rights in connection with your personal data and how we handle it. You can exercise these rights at any time by contacting us via any of the methods set out in the Contact Us section below.</p>
    <p>&nbsp;</p>
    <p>Those rights include:</p>
    <p>&nbsp;</p>
    <ul>
      <li><strong> Access to personal data:</strong> You have the right to access your personal data that <strong>byHorses</strong> is using.</li>
      <li><strong> Correction or deletion of personal data: </strong>You have the right to ask <strong>byHorses</strong> to correct or complete your personal data if it is wrong or incomplete. You may also have the right to ask <strong>byHorses</strong> to delete your personal data, when certain conditions apply.</li>
      <li><strong> Account deactivation:</strong> Should you wish to completely deactivate your account include disassociating your Facebook account from our mobile games and deleting our games from your mobile devices. Please note that if you completely deactivate your account all your progress in our games and any unspent virtual items will be lost and we may not be able to restore them in the future.</li>
      <li><strong> Restriction on processing of personal data:</strong> You may have the right to ask <strong>byHorses</strong> to restrict the use of your personal data, when certain conditions apply.</li>
      <li><strong> Object to processing of personal data:</strong> You may have the right to object to the use of your personal data by <strong>byHorses</strong>, when certain conditions apply.</li>
      <li><strong> Portability of personal data: </strong>You may have the right to receive your personal data in a structured and commonly used format so you can use this data with another company, when certain conditions apply.</li>
      <li><strong> Right to opt-out (California, USA): </strong>California residents have the right to opt out of the sale of personal data, which is defined in a way that may include the transfer of data to third parties to personalize ads for you. If you opt out of the &lsquo;sale&rsquo; of your data, we will not sell your data, including to our advertising partners, but you may still see ads in the game and some of our partners may deliver ads to you, based on information they hold on you.</li>
    </ul>
    <p>&nbsp;</p>
    <p>There are other ways in which you can control the personal data that we collect about you. For instance, you could disconnect your Facebook or other social network account from our games, or delete our games from your mobile device. You could also reset your mobile advertising identifier or limit ad tracking altogether using the settings in your phone.</p>
    <p>&nbsp;</p>
    <p>We may occasionally send you push notifications through our mobile applications with game updates, high scores and other notices that may be of interest to you. You may at any time opt out from receiving these types of communications by changing the settings on your mobile device.</p>
    <p>&nbsp;</p>
    <p><strong>If you believe we have misused your data or violated any data protection regulations, you may file a complaint with a Data Protection Authority, for example, the Authority of your place of residence.</strong></p>
    <p>&nbsp;</p>
    <p><strong>Children</strong></p>
    <p>&nbsp;</p>
    <p>You must be over 13 years old to play our games and use our Services. We do not collect personal data from anyone under 13 years old person knowingly and willfully. However if the Services are used by such person in contradiction to this Privacy Policy, we may collect personal data from such person. If you believe that we might have any information from or about a child under 13 years old (or under 16 years old in certain jurisdictions in the European Union countries and/or districts), please Contact Us.</p>
    <p>&nbsp;</p>
    <p><strong>Security</strong></p>
    <p>&nbsp;</p>
    <p>The personal data that you provide to <strong>byHorses</strong> is stored on servers that are located in secured facilities and protected by protocols and procedures designed to ensure industry-standard security of such data such as firewalls and data encryption. However, no server, computer or communications network or system or data transmission over the internet can be guaranteed to be 100% secure. As a result, while <strong>byHorses</strong> strives to protect your personal data, <strong>byHorses</strong> cannot ensure or warrant the security of any personal data you transmit to <strong>byHorses</strong> or through the Services.</p>
    <p>&nbsp;</p>
    <p>By using the Services or providing personal data to us, you agree that we may communicate with you electronically regarding security privacy, and administrative issues related to your use of the Services. If we learn of a security system&rsquo;s breach, we may attempt to notify you electronically by posting a notice on the Services or sending an e-mail to you.</p>
    <p>&nbsp;</p>
    <p><strong>Legal Bases (for European Economic Area users)</strong></p>
    <p>&nbsp;</p>
    <p>If you are an individual in the European Economic Area (EEA), we collect and process personal data about you only where we have legal bases for doing so under applicable EU laws. The legal bases which we rely upon are the following:</p>
    <p>&nbsp;</p>
    <ul>
      <li><strong> Contractual Necessity</strong>.This covers data used by us in order to provide you with Services that you have requested &ndash; and that we have agreed &ndash; to provide to you, such as our games, customer services, chat functionality and third-party social network connectivity;</li>
      <li><strong> Legal Obligation</strong>.This covers data used by us to comply with a legal obligation, such as to maintain records relating to tax or to respond to a valid legal request for certain data;</li>
      <li><strong> Legitimate Interests</strong>.This covers data used by us for purposes that are not harmful to your privacy and can be reasonably expected within the context of your use of our Services, such as marketing, advertising, analytics, research and optimization of our games and other Services; and</li>
      <li>This is used as a legal basis in the following contexts: the collection of data stored on your device by us and our advertising partners, including device information, ad identifiers, IP address, country and region. You consent to this collection when you agree to install our game and you can withdraw your consent to this by uninstalling our games.</li>
    </ul>
    <p>&nbsp;</p>
    <p>In this Privacy Policy we have explained how you can opt out of certain types of processing that we undertake and where any processing is based on your consent, you can withdraw that consent at any time in the ways described in this Privacy Policy. Nonetheless, if you play our game we will still collect some personal data about you and process it as otherwise described in this Privacy Policy. If you do not wish us to collect any personal data about you at all, you should cease playing our games and stop using our Services.</p>
    <p>&nbsp;</p>
    <p><strong>Retention</strong></p>
    <p>&nbsp;</p>
    <p>We retain your data for as long as your account is active or as needed to provide you the Service.</p>
    <p>Note that if you ask us to remove your personal data, we will retain your data as necessary for our legitimate business interests, such as to comply with our legal obligations, resolve disputes, and enforce our agreements.</p>
    <p>&nbsp;</p>
    <p><strong>Changes to this Privacy Policy</strong></p>
    <p>&nbsp;</p>
    <p>We may update this Privacy Policy from time to time and we will post the updated Privacy Policy on this page. Any new policy will be effective from the date it is published by us.</p>
    <p>&nbsp;</p>
    <p><strong>Contact Us</strong></p>
    <p>&nbsp;</p>
    <p>To communicate with us and/or use your rights specified under this Privacy Policy please contact us at:</p>
    <p>&nbsp;</p>
    <p><strong>info@byhorses.com</strong></p>
  </>
)

const PrivacyPolicy = () => {
  const [language, setLanguage] = useState('tr')

  useEffect(() => {
    let lang = localStorage.getItem('multilang')
    if (!lang) lang = 'tr'
    setLanguage(lang)
  }, [])

  if (language === "tr") {
    return (
      <Row>
        <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }}>
          <Policy policyName={"Gizlilik Politikası"} policy={policyTR} />
        </Col>
      </Row>
    )
  }

  return (
    <Row>
      <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }}>
        <Policy policyName={"Privacy Policy"} policy={policyEN} />
      </Col>
    </Row>
  )
}
export default PrivacyPolicy