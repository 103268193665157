import React from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../redux/byhorses/actions";
import { Col, Row } from 'antd'

const HomeGame = (props) => {
  return (
    <Row className='reveal' gutter={[48, 96]} align='middle' style={{marginTop: 100, marginBottom: 100}}>
      <Col xs={24} align='center'>
        <span style={{fontSize: props.innerWidth > 768 ? 48 : 36}}>{props.t('translation:howToPlay')}</span>
        <br />
        <br />
        <br />
        <p style={{fontSize: props.innerWidth > 768 ? 22 : 16}}>
          {props.t('translation:howToPlayParagraph1')}<br />
          {props.t('translation:howToPlayParagraph2')} <br />
          {props.t('translation:howToPlayParagraph3')}<br />
          {props.t('translation:howToPlayParagraph4')} <br />
          {props.t('translation:howToPlayParagraph5')}<br />
          </p>
      </Col>
      <Col xs={24} lg={{span: 7, offset: 5}} align='center'>
        <div className='card-shadow font-1rem' style={{ width: '100%', padding: "10px 0px 10px 0px", backgroundColor: "#231340", borderRadius: 10 }}>
          <span>{props.t('translation:howToPlayCard1Title1')}</span> <br />
          <span style={{ color: '#C3017D' }}>{props.t('translation:howToPlayCard1Title2')}</span> <br />
        </div>
      </Col>
      <Col xs={24} lg={{span: 7, offset: 0}} align='center'>
        <div className='card-shadow font-1rem' style={{ width: '100%', padding: "10px 0px 10px 0px", backgroundColor: "#231340", borderRadius: 10 }}>
          <span>{props.t('translation:howToPlayCard2Title1')}</span> <br />
          <span style={{ color: '#C3017D' }}>{props.t('translation:howToPlayCard2Title2')}</span> <br />
        </div>
      </Col>
    </Row>
  )
}


const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(HomeGame));