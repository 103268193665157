import React, { useEffect, useState } from 'react'
import { Col, Row } from 'antd'
import Policy from './Policy'


const policyTR = (
  <>
    <ol>
      <li><strong> Giriş</strong></li>
    </ol>
    <p>&nbsp;</p>
    <p>İnternet sitemizin <strong>(www.byhorses.com)</strong> d&uuml;zg&uuml;n &ccedil;alışmasını sağlamak, ziyaret&ccedil;i ve &uuml;yelerimize (<strong>&ldquo;kullanıcı(lar)&rdquo;</strong> veya <strong>&ldquo;siz&rdquo;</strong>) en alakalı hizmetleri sunmak amacıyla birtakım &ccedil;erezler kullanmaktayız.</p>
    <p>&nbsp;</p>
    <p>İşbu &Ccedil;erez Politikası, size &ccedil;erezler ve &ccedil;erezlerin nasıl kontrol edileceği hakkında bilgi vermek amacıyla hazırlanmıştır.</p>
    <p>&nbsp;</p>
    <ol start="2">
      <li><strong> &Ccedil;erez nedir?</strong></li>
    </ol>
    <p>&nbsp;</p>
    <p>&Ccedil;erezler, internet sitemizi ziyaret ettiğinizde bilgisayarınıza ya da mobil cihazınıza kaydedilen k&uuml;&ccedil;&uuml;k metin dosyalarıdır. Bu metin dosyaları ile beraber web işaret&ccedil;ileri, pikseller veya etiketler gibi diğer takip&ccedil;iler de işbu &Ccedil;erez Politikası kapsamında &ccedil;erez olarak kabul edilecektir.</p>
    <p>&nbsp;</p>
    <ol start="3">
      <li><strong> &Ccedil;erezlerin kullanım ama&ccedil;ları nelerdir?</strong></li>
    </ol>
    <p>&nbsp;</p>
    <p>&Ccedil;erezleri aşağıdaki ama&ccedil;larla kullanmaktayız:</p>
    <p>&nbsp;</p>
    <ul>
      <li>Sitemizin g&uuml;venli bir şekilde &ccedil;alışmasını sağlamak;</li>
      <li>Sitemizde gezinmenizi ve sitemizdeki hizmet ve &ouml;zelliklerden yararlanmanızı sağlamak;</li>
      <li>Sizlere daha iyi bir deneyim sunmak;</li>
      <li>Sitemizin performansını artırmak ve internet sitemizi daha kullanıcı dostu hale getirmek;</li>
      <li>Kullanıcılar (kullanıcı cihazları ve tarayıcı &ouml;nbellekleri &uuml;zerinden) ve site kullanımı hakkında bilgi toplamak;</li>
      <li>Sitemizi daha fonksiyonel hale getirmek;</li>
      <li>Kullanıcı davranışlarınızı analiz etmek ve sitemiz &uuml;zerindeki genel kullanıcı eğilimini tespit etmek;</li>
      <li>Ziyaret edilen her sayfanın g&ouml;r&uuml;nt&uuml;lenme sayısı dikkate alınarak bir değer belirlemek;</li>
      <li>Reklam ve pazarlama faaliyetlerimizi geliştirmek;</li>
      <li>Mevcut ve potansiyel kullanıcılarımızın sitemize eklenen i&ccedil;erikleri sosyal medya ağlarında paylaşmasına imkan vermek;</li>
      <li>Sitemizin farklı s&uuml;r&uuml;mlerinin performansını &ouml;l&ccedil;mek ve Kullanıcılar&rsquo;ın devamlı aynı s&uuml;r&uuml;m&uuml; g&ouml;r&uuml;nt&uuml;lemesini sağlamak.</li>
    </ul>
    <p>&nbsp;</p>
    <ol start="4">
      <li><strong> &Ccedil;erezlerin genel &ouml;zellikleri</strong></li>
    </ol>
    <p>&nbsp;</p>
    <p>&Ccedil;erezler, internet sitemizde ve mobil uygulamamızda oturum &ccedil;erezleri ve kalıcı &ccedil;erezler olarak işlev g&ouml;stermektedir. Oturum &ccedil;erezleri, tarayıcınızı kapatmanız ile birlikte &ccedil;alışmayı durdurmaktadır. Kalıcı &ccedil;erezler ise, internet tarayıcınızdaki ayarlara bağlı olarak, hard diskinizde uzun s&uuml;re boyunca kalabilmektedir.</p>
    <p>&nbsp;</p>
    <ol start="5">
      <li><strong> Kullanılan &ccedil;erezlerin t&uuml;rleri</strong></li>
    </ol>
    <p>&nbsp;</p>
    <p>İnternet sitemizde kullanılan &ccedil;erezlerin t&uuml;rleri aşağıda listelenmiştir. Bu &ccedil;erezler aracılığıyla topladığımız verilerin kişisel veri niteliğinde olması halinde, işbu &Ccedil;erez Politikası&rsquo;nı tamamlar nitelikteki Aydınlatma Metni uygulanacaktır. Konuya ilişkin daha fazla bilgiye sahip olmak i&ccedil;in <strong> <a className='link-on-white' href='/kvkk' target={"_blank"} rel="noreferrer">Aydınlatma Metnimizi</a></strong> inceleyebilirsiniz.</p>
    <p>&nbsp;</p>
    <p><strong>5.1 Gerekli &Ccedil;erezler</strong></p>
    <p>&nbsp;</p>
    <p>Bu t&uuml;r &ccedil;erezler, internet sitemizin g&uuml;venli bir şekilde &ccedil;alışabilmesi ve kullanıcıların internet sitemiz &uuml;zerinde hareket edebilmesi ve sağlanan hizmetlerden ve &ouml;zelliklerden faydalanabilmesi i&ccedil;in kesinlikle gerekli olan &ccedil;erezlerdir. Bu t&uuml;r &ccedil;erezler, internet sitemizin &ccedil;alışabilmesi i&ccedil;in gereklidir ve kullanıcılar tarafından devre dışı bırakılamazlar.</p>
    <p>&nbsp;</p>
    <p><strong>5.2 Tercih ve İşlevsellik &Ccedil;erezleri</strong></p>
    <p>&nbsp;</p>
    <p>Tercih &ccedil;erezleri, kullanıcıların tercihlerine ilişkin bilgileri toplar ve dil veya kullanıcıların diğer yerel ayarlarını hatırlamamızı ve sitemizi kullanıcılara uygun şekilde kişiselleştirmemizi sağlar. Bu t&uuml;r &ccedil;erezler, tarafımızca veya sayfalarınıza hizmetlerini eklediğiniz &uuml;&ccedil;&uuml;nc&uuml; taraf sağlayıcılar tarafından koyulabilir. Kullanıcılar bu t&uuml;r &ccedil;erezleri devre dışı bıraktığı takdirde, bu işlevlerin bazıları veya t&uuml;m&uuml; d&uuml;zg&uuml;n &ccedil;alışmayabilir.</p>
    <p>&nbsp;</p>
    <p><strong>5.3 Analitik &Ccedil;erezler</strong></p>
    <p>&nbsp;</p>
    <p>Analitik &ccedil;erezler, kullanıcıların internet sitemizi nasıl kullandıklarını anlamamızı sağlar. Bu &ccedil;erezler, toplu bir şekilde bilgi toplayarak sitemizin nasıl kullanıldığına dair bize fikir vermekte ve internet sitemizi geliştirmemize yardımcı olmaktadır. &Ouml;rneğin, bu tip &ccedil;erezler, site &uuml;zerinde en &ccedil;ok hangi sayfaların ziyaret edildiğini g&ouml;stermekte ve site i&ccedil;inde yaşanılan zorlukların kaydedilmesine yardımcı olmaktadır.</p>
    <p>&nbsp;</p>
    <p><strong>5.4 Reklam/Pazarlama &Ccedil;erezleri</strong></p>
    <p>&nbsp;</p>
    <p>Reklam/pazarlama &ccedil;erezleri reklam ve pazarlama ama&ccedil;ları ile kullanılmaktadır. Bu &ccedil;erezler, kullanıcıların tarayıcılarını ve cihazlarını tanımlayarak &ccedil;alışırlar. Bu &ccedil;erezleri, kullanıcıların ilgi alanlarının profillerini oluşturmak ve kullanıcılara diğer internet sitelerinde &uuml;r&uuml;n&uuml;m&uuml;zle alakalı reklamlar g&ouml;stermek amacıyla kullanabilmekteyiz. Kullanıcılar, bu &ccedil;erezlere izin vermedikleri takdirde, kullanıcılara hedefli reklamlar g&ouml;sterilemeyecektir.</p>
    <p>&nbsp;</p>
    <ol start="6">
      <li><strong> &Uuml;&ccedil;&uuml;nc&uuml; parti (third party) &ccedil;erezler</strong></li>
    </ol>
    <p>&nbsp;</p>
    <p>&Uuml;&ccedil;&uuml;nc&uuml; parti &ccedil;erezler, bu &ccedil;erezleri sağlayan ilgili &uuml;&ccedil;&uuml;nc&uuml; parti tarafından değiştirilebilecektir. Bu &ccedil;erezlerle ilgili daha g&uuml;ncel bilgi almak i&ccedil;in, ilgili &uuml;&ccedil;&uuml;nc&uuml; parti &ccedil;erez sağlayıcıları ile iletişime ge&ccedil;ebilirsiniz.</p>
    <p>&nbsp;</p>
    <ol start="7">
      <li><strong> &Ccedil;erezler nasıl devre dışı bırakılır?</strong></li>
    </ol>
    <p>&nbsp;</p>
    <p>&Ccedil;erez tercihlerinizi, tarayıcı ayarlarınızı değiştirerek isteğinize g&ouml;re uyarlayabilirsiniz. Tarayıcınızın sunmuş olduğu imkanlara g&ouml;re, &ccedil;erezlerin kullanılmasını engelleyebilir, &ccedil;erez kullanılmadan &ouml;nce uyarı alabilir veya sadece bazı &ccedil;erezleri devre dışı bırakabilir ya da silebilirsiniz. &Ccedil;erezlerle alakalı tercihlerin, sitemize erişim sağladığınız her bir cihaz (tarayıcı ayarları) &ouml;zelinde ayrı olarak silinmesi gerekmektedir.</p>
    <p>&nbsp;</p>
    <p>&Ccedil;erezleri nasıl etkinleştireceğiniz, devre dışı bırakacağınız ya da kaldıracağınıza ilişkin bilgiler, internet tarayıcı sağlayıcısının sitesindeki &lsquo;&lsquo;Yardım&rsquo;&rsquo; ekranında yer almaktadır. Aşağıdaki bağlantılar yardımıyla ilgili sayfalara ulaşabilirsiniz:</p>
    <p>&nbsp;</p>
    <ul>
      <li><strong><a className='link-on-white' href='https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=tr' target={"_blank"} rel="noreferrer">Google Chrome</a></strong></li>
      <li><strong><a className='link-on-white' href='https://support.mozilla.org/tr/kb/%C3%87erezleri%20engellemek' target={"_blank"} rel="noreferrer" >Mozilla Firefox</a></strong></li>
      <li><strong><a className='link-on-white' href='https://support.microsoft.com/tr-tr/help/17442/windows-internet-explorer-delete-manage-cookies' target={"_blank"} rel="noreferrer" >Internet Explorer</a></strong></li>
      <li><strong><a className='link-on-white' href='https://support.apple.com/kb/PH5042?locale=en-GB' target={"_blank"} rel="noreferrer" >Safari</a></strong></li>
      <li><strong><a className='link-on-white' href='http://www.opera.com/help/tutorials/' target={"_blank"} rel="noreferrer" >Opera</a></strong></li>
    </ul>
    <p>&nbsp;</p>
    <p>Bazı &ccedil;erezleri devre dışı bırakmanız halinde sitemizin &ccedil;eşitli fonksiyonlarının &ccedil;alışmayabileceğini hatırlatmak isteriz.</p>
    <p>&nbsp;</p>
    <ol start="8">
      <li><strong> Bize ulaşın</strong></li>
    </ol>
    <p>&nbsp;</p>
    <p>byHorses, &Ccedil;erez Politikası&rsquo;nın h&uuml;k&uuml;mlerini dilediği zaman değiştirebilecektir. &Ccedil;erez Politikasında yaptığımız değişikliklerden veya g&uuml;ncellemelerden haberdar olabilmek i&ccedil;in aralıklı olarak bu sayfayı kontrol edebilirsiniz.</p>
    <p>&nbsp;</p>
    <p>&Ccedil;erez Politikası veya internet sitemizdeki &ccedil;erez kullanımı hakkında soru, g&ouml;r&uuml;ş ve &ouml;nerileriniz i&ccedil;in bizimle iletişim sayfası &uuml;zerinden iletişime ge&ccedil;ebilirsiniz.</p>
    <p>&nbsp;</p>
  </>
)
const policyEN = (
  <>
    <ol>
      <li><strong> Intro</strong></li>
    </ol>
    <p>&nbsp;</p>
    <p>We use a number of cookies to ensure that our website (<strong>www.byhorses.com</strong>) works properly and to provide the most relevant services to our visitors and members (&ldquo;<strong>user(s)</strong>&rdquo; or &ldquo;<strong>you</strong>&rdquo;).</p>
    <p>&nbsp;</p>
    <p>This Cookie Policy has been prepared to inform you about cookies and how to control cookies.</p>
    <p>&nbsp;</p>
    <ol start="2">
      <li><strong> What is a cookie?</strong></li>
    </ol>
    <p>&nbsp;</p>
    <p>Cookies are small text files that are saved on your computer or mobile device when you visit our website. Along with these text files, other trackers such as web beacons, pixels or tags will also be considered cookies under this Cookie Policy.</p>
    <p>&nbsp;</p>
    <ol start="3">
      <li><strong> What are the uses of cookies?</strong></li>
    </ol>
    <p><strong>&nbsp;</strong></p>
    <p>We use cookies for the following purposes:</p>
    <p>&nbsp;</p>
    <ul>
      <li>To ensure the safe operation of our site;</li>
      <li>To enable you to browse our site and benefit from the services and features on our site;</li>
      <li>To provide you with a better experience;</li>
      <li>To improve the performance of our site and to make our website more user-friendly;</li>
      <li>Collecting information about users (via user devices and browser caches) and site usage;</li>
      <li>To make our site more functional;</li>
      <li>To analyze your user behavior and to determine the general user trend on our site;</li>
      <li>To set a value taking into account the number of views of each page visited;</li>
      <li>To improve our advertising and marketing activities;</li>
      <li>To allow our current and potential users to share the content added to our site on social media networks;</li>
      <li>To measure the performance of different versions of our site and to ensure that users constantly view the same version.</li>
    </ul>
    <p>&nbsp;</p>
    <ol start="4">
      <li><strong> General characteristics of cookies</strong></li>
    </ol>
    <p>&nbsp;</p>
    <p>Cookies function as session cookies and persistent cookies on our website and mobile application. Session cookies stop working when you close your browser. Persistent cookies, on the other hand, may remain on your hard disk for a long time, depending on the settings in your internet browser.</p>
    <p>&nbsp;</p>
    <ol start="5">
      <li><strong> Types of cookies used</strong></li>
    </ol>
    <p>&nbsp;</p>
    <p>The types of cookies used on our website are listed below. If the data we collect through these cookies is of the nature of personal data, the Clarification Text that complements this Cookie Policy will be applied. You can review our <a className='link-on-white' href='/kvkk' target={"_blank"} rel="noreferrer">Privacy Policy</a>  to have more information on the subject.</p>
    <p>&nbsp;</p>
    <p><strong>5.1 Necessary Cookies</strong></p>
    <p>&nbsp;</p>
    <p>These types of cookies are strictly necessary for our website to work securely and for users to be able to act on our website and benefit from the services and features provided. These types of cookies are necessary for our website to work and cannot be disabled by users.</p>
    <p>&nbsp;</p>
    <p><strong>5.2 Preference and Functionality Cookies</strong></p>
    <p>&nbsp;</p>
    <p>Preference cookies collect information about users&rsquo; preferences and allow us to remember language or other locale settings of users and customize our site to suit users. Such cookies may be set by us or by third-party providers whose services you have added to your pages. Some or all of these functions may not work properly if users disable such cookies.</p>
    <p>&nbsp;</p>
    <p><strong>5.3 Analytical Cookies</strong></p>
    <p>&nbsp;</p>
    <p>Analytical cookies allow us to understand how users use our website. These cookies collect information in aggregate, give us an idea of how our site is used and help us improve our website. For example, these types of cookies show which pages are visited the most on the site and help to record the difficulties experienced within the site.</p>
    <p>&nbsp;</p>
    <p><strong>5.4 Advertising/Marketing Cookies</strong></p>
    <p>&nbsp;</p>
    <p>Advertising/marketing cookies are used for advertising and marketing purposes. These cookies work by identifying users&rsquo; browsers and devices. We may use these cookies to create profiles of users&rsquo; interests and to show users advertisements related to our product on other websites. If users do not allow these cookies, targeted ads will not be shown to users.</p>
    <p>&nbsp;</p>
    <ol start="6">
      <li><strong> Third party cookies</strong></li>
    </ol>
    <p>&nbsp;</p>
    <p>Third party cookies may be changed by the relevant third party providing these cookies. For more up-to-date information about these cookies, you can contact the relevant third-party cookie providers.</p>
    <p>&nbsp;</p>
    <ol start="7">
      <li><strong> How to disable cookies?</strong></li>
    </ol>
    <p>&nbsp;</p>
    <p>You can adapt your cookie preferences according to your wishes by changing your browser settings. Depending on the possibilities offered by your browser, you can prevent the use of cookies, receive a warning before cookies are used, or disable or delete only some cookies. Cookies-related preferences must be deleted separately for each device (browser settings) from which you access our site.</p>
    <p>&nbsp;</p>
    <p>Information on how to enable, disable or remove cookies is available on the &ldquo;Help&rdquo; screen on the internet browser provider&rsquo;s site. You can reach the relevant pages with the help of the following links:</p>
    <p>&nbsp;</p>
    <ul>
      <li><a className='link-on-white' href='https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=tr' target={"_blank"} rel="noreferrer">Google Chrome</a></li>
      <li><a className='link-on-white' href='https://support.mozilla.org/tr/kb/%C3%87erezleri%20engellemek' target={"_blank"} rel="noreferrer" >Mozilla Firefox</a></li>
      <li><a className='link-on-white' href='https://support.microsoft.com/tr-tr/help/17442/windows-internet-explorer-delete-manage-cookies' target={"_blank"} rel="noreferrer" >Internet Explorer</a></li>
      <li><a className='link-on-white' href='https://support.apple.com/kb/PH5042?locale=en-GB' target={"_blank"} rel="noreferrer" >Safari</a></li>
      <li><a className='link-on-white' href='http://www.opera.com/help/tutorials/' target={"_blank"} rel="noreferrer" >Opera</a></li>
    </ul>
    <p>&nbsp;</p>
    <p>We would like to remind you that if you disable some cookies, various functions of our site may not work.</p>
    <p>&nbsp;</p>
    <ol start="8">
      <li><strong> Contact us</strong></li>
    </ol>
    <p>&nbsp;</p>
    <p><strong>byHorses</strong> may change the provisions of the Cookie Policy at any time. You can check this page periodically to be aware of any changes or updates we make to the Cookie Policy.</p>
    <p>&nbsp;</p>
    <p>For questions, comments and suggestions about the Cookie Policy or the use of cookies on our website, you can contact us via the contact page.</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
  </>
)

const CookiePolicy = () => {
  const [language, setLanguage] = useState('tr')

  useEffect(() => {
    let lang = localStorage.getItem('multilang')
    if (!lang) lang = 'tr'
    setLanguage(lang)
  }, [])

  if (language === "tr") {
    return (
      <Row>
        <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }}>
          <Policy policyName={"Çerez Politikası"} policy={policyTR} />
        </Col>
      </Row>
    )
  }

  return (
    <Row>
      <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }}>
        <Policy policyName={"Coockie Policy"} policy={policyEN} />
      </Col>
    </Row>
  )
}
export default CookiePolicy