import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { setRedux } from "../../redux/byhorses/actions";
import { Image } from 'antd'
import favIcon from "../../assets/images/icons/fav-icon.png"
import favSelectedIcon from "../../assets/images/icons/fav-selected-icon.png"
import { updateNft } from './api'

const Fav = (props) => {
  const [isFavorite, setIsFavorite] = useState(false)



  const addToLocalStorage = ({ storage, type }) => {
    storage.push({ type: type, nftId: props.data.id })
    window.localStorage.setItem("favoriteNFTS", JSON.stringify(storage))
  }
  const removeFromLocalStorage = ({ storage, type }) => {
    const newStorage = storage.filter((dt) => (dt.type === type && props.data.id !== dt.nftId))
    window.localStorage.setItem("favoriteNFTS", JSON.stringify(newStorage))
  }

  const handleFavorite = async ({ isFavorite_, nftType }) => {
    let count;
    const storage = JSON.parse(window.localStorage.getItem("favoriteNFTS")) || []
    if (isFavorite_ === true) {
      count = props.data.favoriteCount ? props.data.favoriteCount + 1 : 1
      addToLocalStorage({ storage: storage, type: nftType })

    } else {
      count = props.data.favoriteCount ? props.data.favoriteCount - 1 : 0
      removeFromLocalStorage({ storage: storage, type: nftType })
    }
    try {
      await updateNft({ payload: { nftType: nftType, nftId: props.data.id, favoriteCount: count } })
    } catch (error) {
      console.error(error)
    }
  }

  const handleClickToFav = () => {
    setIsFavorite(!isFavorite)

    if ((props.data.type_of_nft || props.data.nftType) === 1) {
      // Update the horse fav count
      handleFavorite({ isFavorite_: !isFavorite, nftType: 1 })
    } else if ((props.data.type_of_nft || props.data.nftType) === 2) {
      // Update the jockey 
      handleFavorite({ isFavorite_: !isFavorite, nftType: 2 })
    } else {
      return
    }
  }

  useEffect(() => {
    const favs = JSON.parse(window.localStorage.getItem("favoriteNFTS"))
    if (favs === null) return
    const favorite = favs.filter((data) => (data.type === (props.data.type_of_nft || props.data.nftType) && data.nftId === props.data.id))
    if (favorite.length > 0) {
      setIsFavorite(true)
    } else {
      setIsFavorite(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data])


  return (
    <div style={props.isCard === true ? { position: 'absolute', right: props.reduxState?.innerWidth > 768 ? 90 : 15, top: 10 } : {}} onClick={handleClickToFav}>
      <Image src={isFavorite ? favSelectedIcon : favIcon} preview={false} width={props.reduxState?.innerWidth > 768 ? 30 : 20}
        style={{ cursor: 'pointer' }} />
    </div>
  )
}

const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(Fav));