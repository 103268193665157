import React, { useEffect, useState } from 'react'
import { Col, Row } from 'antd'
import Policy from './Policy'


const policyTR = (
  <>
    <h2><strong>Kişisel Verilerin Korunması Kanunu</strong></h2>
    <p>&nbsp;</p>
    <p><strong>KİŞİSEL VERİLERIN KORUNMASI, İŞLENMESİNE DAİR S&Ouml;ZLEŞME VE AYDINLATMA METNİ</strong></p>
    <p>&nbsp;</p>
    <ol>
      <li>İşbu kişisel verilerin işlenmesi s&ouml;zleşmesi ve aydınlatıcı bilgi metni,</li>
    </ol>
    <p>&nbsp;</p>
    <p><strong>BYHORSES</strong> (bundan sonra &ldquo;<strong>byHorses</strong>&rdquo; olarak anılacaktır) ile bir takım kişisel verilerini paylaşan kullanıcılar arasında tanzim edilmiştir. İşbu s&ouml;zleşme ve aydınlatma metni, -&uuml;yelik tarihine bakılmaksızın- ileride tanzim edilecek olan kullanım s&ouml;zleşmesinin ayrılmaz bir par&ccedil;asıdır.</p>
    <p>&nbsp;</p>
    <ol start="2">
      <li><strong>byHorses</strong>, T.C Anayasası, Kişisel Verilerin İşlenmesi Kanunu kapsamında uymak zorunda olduğu ve diğer kanunlarda da yer alan h&uuml;k&uuml;mler doğrultusunda kişisel verileri işlemektedir.</li>
    </ol>
    <p>&nbsp;</p>
    <ol start="3">
      <li>Kişisel Verilerin İşlenmesi, kişisel verilerin tamamen veya kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin par&ccedil;ası olmak kaydıyla otomatik olmayan yollarla elde edilmesi, kaydedilmesi, depolanması, muhafaza edilmesi, değiştirilmesi, yeniden d&uuml;zenlenmesi, a&ccedil;ıklanması, aktarılması, devralınması, elde edilebilir h&acirc;le getirilmesi, sınıflandırılması ya da kullanılmasının engellenmesi gibi veriler &uuml;zerinde ger&ccedil;ekleştirilen her t&uuml;rl&uuml; işlem anlamına gelmektedir.</li>
    </ol>
    <p>&nbsp;</p>
    <ol start="4">
      <li>Kişisel veri sahipleri haklarına ilişkin taleplerini <strong>byHorses</strong>&rsquo;a iletmeleri durumunda talepler kanunun aradığı azami s&uuml;reyi ge&ccedil;irmemek kaydıyla en kısa s&uuml;rede &uuml;cretsiz olarak sonu&ccedil;landırmaktadır. Ancak, işlemin ayrıca bir maliyeti gerektirmesi h&acirc;linde, &ldquo;<strong>byHorses</strong>&rdquo; tarafından &uuml;cret alınabilecektir.</li>
    </ol>
    <p>&nbsp;</p>
    <p>Kişisel veri sahipleri;</p>
    <p>&nbsp;</p>
    <ul>
      <li>Kişisel verilerinin işlenip işlenmediğini &ouml;ğrenme</li>
      <li>Kişisel veri işlenmişse bilgi talep etme</li>
      <li>Kişisel veri işlenme amacını ve amacına uygun kullanılıp kullanılmadığını &ouml;ğrenme</li>
      <li>Kişisel verinin Yurt i&ccedil;inde/yurt dışında aktarıldığı 3. kişileri bilme</li>
      <li>Kişisel veri eksik/yanlış işlenmişse d&uuml;zeltilmesini isteme</li>
      <li>Kişisel verilerin işlenmesini gerektiren sebeplerin ortadan kalkması halinde kişisel verilerin silinmesini veya yok edilmesini isteme</li>
      <li>Kişisel verinin aktarıldığı 3. kişilere yapılan işlemlerin bildirilmesini isteme,</li>
      <li>Kişisel verinin otomatik sistemler ile analiz edilmesi nedeniyle aleyhine bir sonucun ortaya &ccedil;ıkmasına itiraz etme</li>
      <li>Kişisel verinin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması h&acirc;linde zararın giderilmesini talep etme hakkına sahiptir.</li>
    </ul>
    <p>&nbsp;</p>
    <ol start="5">
      <li>Kişisel Verilerin Korunması Kanunu&rsquo;nun 11. maddesinde kişisel veri sahibinin hakları arasında &ldquo;bilgi talep etme&rdquo; sayılmıştır. <strong>byHorses</strong> bu kapsamda, Anayasa ve KVK Kanunu&rsquo;nun 11. maddelerine uygun olarak kişisel veri sahibinin bilgi talep etmesi durumunda gerekli bilgilendirmeyi yapmaktadır.</li>
    </ol>
    <p>Bu bağlamda, Kişisel Verilerin Korunması Kanunu&rsquo;nun 10. maddesine uygun olarak, kişisel verilerin elde edilmesi sırasında kişisel veri sahiplerine, kişisel verilerinin elde edilmesi sırasında <strong>byHorses</strong>&rsquo;ın kimliği, kişisel verilerin hangi ama&ccedil;la işleneceği, işlenen kişisel verilerin kimlere ve hangi ama&ccedil;la aktarılabileceği, kişisel veri toplamanın y&ouml;ntemi ve hukuki sebebi ile kişisel veri sahibinin KVK Kanunu&rsquo;nun 11. maddesi kapsamında sahip olduğu haklarla ilgili aydınlatma yapmaktadır. Sitede bilgileri <strong>byHorses</strong> ile paylaşan t&uuml;m kullanıcılar aydınlatmanın kendisine yapıldığını kabul ve beyan etmektedir.</p>
    <p>&nbsp;</p>
    <ol start="6">
      <li>Kişisel Verilerin Korunması Kanunu&rsquo;nun 12. maddesi uyarınca &ldquo;veri g&uuml;venliğini&rdquo; sağlamaya y&ouml;nelik, <strong>byHorses</strong>, veri g&uuml;venliğinin sağlanması konusuna azami dikkat ve &ouml;zeni g&ouml;stermektedir ve aşağıda belirtilen hususlar ile ilgili gerekli tedbirler alınmaktadır.</li>
    </ol>
    <p>&nbsp;</p>
    <ul>
      <li>Kişisel verilerin hukuka aykırı olarak işlenmesini &ouml;nlenmesi</li>
      <li>Kişisel verilere hukuka aykırı olarak erişilmesini &ouml;nlenmesi</li>
      <li>Kişisel verilerin muhafazasını sağlamak, amacıyla uygun g&uuml;venlik d&uuml;zeyini temin etmeye y&ouml;nelik gerekli her t&uuml;rl&uuml; teknik ve idari tedbirlerin alınmasını temin eder.</li>
    </ul>
    <p>&nbsp;</p>
    <ol start="7">
      <li><strong>byHorses</strong>, &ccedil;alışanları ve/veya g&ouml;revleri dolayısıyla kişisel bilgileri &ouml;ğrenen kişiler, bahse konu bilgileri Kişisel Verilerin Korunması Kanunu&rsquo;nda yer alan h&uuml;k&uuml;mlere aykırı olarak başkasına a&ccedil;ıklayamaz ve amacı dışında kullanamazlar.</li>
    </ol>
    <p>&nbsp;</p>
    <p><strong>byHorses</strong>, işlenen kişisel verilerin işbu metinde belirtilmeyen ve/veya kanuni olmayan yollarla başkaları tarafından elde edilmesi h&acirc;linde, bahse konu durumun en kısa s&uuml;rede ilgilisine bildirir.</p>
    <p>&nbsp;</p>
    <ol start="8">
      <li><strong>byHorses</strong>, kişisel verilerin işlenmesinde hukuksal d&uuml;zenlemelerle getirilen ilkeler ile hakkaniyet ve d&uuml;r&uuml;stl&uuml;k kuralına uygun hareket etmektedir.</li>
    </ol>
    <p>&nbsp;</p>
    <ol start="9">
      <li><strong>byHorses</strong>, kişisel veri sahiplerinin temel haklarını ve kendi meşru menfaatlerini dikkate alarak işlediği kişisel verilerin doğru ve g&uuml;ncel olmasını sağlamaktadır.</li>
    </ol>
    <p>&nbsp;</p>
    <ol start="10">
      <li><strong>byHorses</strong>, yasalara uygun olan kişisel veri işleme amacını a&ccedil;ık ve kesin olarak belirlemektedir. Bu kapsamda kişisel veriler sunulmakta olan ya da sunulacak hizmetlerle ve yasal y&uuml;k&uuml;ml&uuml;l&uuml;kleri ile sınırlı olarak işlenmektedir. Bu kapsamda, kişisel verilerin hangi ama&ccedil;la işleneceği hen&uuml;z kişisel veri işleme faaliyeti baslamadan ortaya konulmaktadır.</li>
    </ol>
    <p>&nbsp;</p>
    <ol start="11">
      <li><strong>byHorses</strong>, kişisel verileri belirlenen ama&ccedil;ların ger&ccedil;ekleştirilebilmesine elverişli bir bi&ccedil;imde işlemekte ve amacın ger&ccedil;ekleştirilmesiyle ilgili olmayan veya ihtiya&ccedil; duyulmayan kişisel verilerin işlenmesinden ka&ccedil;ınmaktadır.</li>
    </ol>
    <p>&nbsp;</p>
    <ol start="12">
      <li>Kişisel verilerin korunması Anayasada d&uuml;zenlenmiş bir haktır. Anayasa gereğince, kişisel veriler ancak kanunda &ouml;ng&ouml;r&uuml;len hallerde veya kişinin rızasıyla işlenebilecektir. <strong>byHorses</strong>, bu doğrultuda ve Anayasa&rsquo;ya uygun bir bi&ccedil;imde; kişisel verileri, ancak kanunda &ouml;ng&ouml;r&uuml;len hallerde veya kişinin a&ccedil;ık rızasıyla işlemektedir.</li>
    </ol>
    <p>&nbsp;</p>
    <p>Kişisel veri sahibinin a&ccedil;ık rızası belirli bir konuya ilişkin, bilgilendirilmeye dayalı olarak ve &ouml;zg&uuml;r iradeyle a&ccedil;ıklanmalıdır. &Uuml;ye olan herkes kişisel verilerin işlenmesine dair s&ouml;zleşme ve aydınlatıcı bilgi metnini okuduğunu ve peşinen kabul ettiğini beyan ve taahh&uuml;t eder.</p>
    <p>&nbsp;</p>
    <p>Kullanıcı bu doğrultuda kişisel verilerin işlenmesine dair t&uuml;m itiraz, dava, şikayet ve talep haklarından peşinen feragat edilmiş sayılmaktadır.</p>
    <p>&nbsp;</p>
    <p>Kişisel veri sahibinin a&ccedil;ık rıza vermesi dışında, aşağıda yazan diğer şartlardan birinin varlığı durumunda da kişisel veriler işlenebilir. Kişisel veri işleme faaliyetinin dayanağı aşağıda belirtilen şartlardan yalnızca biri olabildiği gibi bu şartlardan birden fazlası da aynı kişisel veri işleme faaliyetinin dayanağı olabilir.</p>
    <p>&nbsp;</p>
    <p>Kanunlarda A&ccedil;ık&ccedil;a &Ouml;ng&ouml;r&uuml;lmesi, Fiili imkansızlık Sebebiyle İlgilinin A&ccedil;ık Rızasının Alınamaması, S&ouml;zleşmenin Kurulması veya İfasıyla Doğrudan İlgi Olması, Hukuki Y&uuml;k&uuml;ml&uuml;l&uuml;k, Kişisel Veri Sahibinin Kişisel Verisini Alenileştirmesi, Bir Hakkın Tesisi veya Korunması i&ccedil;in Veri İşlemenin Zorunlu Olması, <strong>byHorses</strong>&rsquo;ın Meşru Menfaati i&ccedil;in Veri İşlemenin Zorunlu Olması hallerinde de kişisel veriler işlenebilmektedir.</p>
    <p>&nbsp;</p>
    <ol start="13">
      <li>Irk, etnik k&ouml;ken, siyasi d&uuml;ş&uuml;nce, felsefi inan&ccedil;, din, mezhep veya diğer inan&ccedil;lar, kılık ve kıyafet, dernek, vakıf ya da sendika &uuml;yeliği, sağlık, cinsel hayat, ceza mahk&ucirc;miyeti ve g&uuml;venlik tedbirleriyle ilgili veriler ile biyometrik ve genetik veriler, Kişisel Verilerin Korunması Kanunu&rsquo;nun 6. maddesinde, &ldquo;&ouml;zel nitelikli&rdquo; veri olarak belirlenmiştir.</li>
    </ol>
    <p>&nbsp;</p>
    <p><strong>byHorses</strong> tarafından, Kişisel Verilerin Korunması Kanunu&rsquo;na uygun bir bi&ccedil;imde; &ouml;zel nitelikli kişisel veriler, KVK Kurulu tarafından belirlenecek olan yeterli &ouml;nlemlerin alınması kaydıyla işlenmekte ve bu konuda y&uuml;ksek hassasiyet g&ouml;sterilmektedir.&nbsp;</p>
    <p>&nbsp;</p>
    <ol start="14">
      <li>Kişisel Verilerin Korunması Kanunu ve ilgili diğer kanun h&uuml;k&uuml;mlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması h&acirc;linde kişisel veriler resen veya ilgili kişinin talebi &uuml;zerine veri sorumlusu tarafından silinir, yok edilir veya anonim h&acirc;le getirilir.</li>
    </ol>
    <p>&nbsp;</p>
    <p>Ancak <strong>byHorses</strong> kişisel verileri ancak ilgili uymakla y&uuml;k&uuml;ml&uuml; olduğu mevzuatta belirtildiği veya yasalara uygun olarak işlendikleri ama&ccedil; i&ccedil;in gerekli olan s&uuml;re kadar muhafaza etme hakkına sahiptir.</p>
    <p>&nbsp;</p>
    <ol start="15">
      <li><strong>byHorses</strong> kişisel verilerin yurt i&ccedil;i ve/veya yurt dışı ile paylaşılması konusunda azami &ouml;zen ve dikkat g&ouml;stermekte olup, bu kapsamda mevcut d&uuml;zenlemelere uygun olarak faaliyetlerini s&uuml;rd&uuml;rmektedir.</li>
    </ol>
    <p>&nbsp;</p>
    <ol start="16">
      <li><strong>byHorses</strong>, 7 Nisan 2016 tarihli Resmi Gazete&rsquo;de yayımlanan 6698 sayılı Kişisel Verilerin Korunması Kanunu&rsquo;nun (&ldquo;Kanun&rdquo;) 8. ve 9. maddelerinin ama&ccedil;ları doğrultusunda, işbu maddede yer alan aşağıdaki hususlara uygun davranacağını beyan etmektedir.</li>
    </ol>
    <p>&nbsp;</p>
    <p>(a) <strong>byHorses</strong> ile aranızdaki ilişki kapsamında temin edilebilecek ve ger&ccedil;ek kişilerin kimliğini belirli veya belirlenebilir kılan, şifre numarasına g&ouml;re ya da psikolojik, fiziksel, ekonomik, k&uuml;lt&uuml;rel veya sosyal benliğini ifade eden bir veya birden fazla unsura, aidiyeti aracılığıyla doğrudan veya dolaylı olarak teşhis edilebilmesine yarayan her t&uuml;rl&uuml; ad-soyad veya unvan, kimlik/vergi numarası/Mersis numarası, yaş, cinsiyet, meslek/ticari faaliyet, sağlık, genetik, etnik, dini, ailevi veya siyasi bilgilerinin ancak bunlarla sınırlı olmaksızın t&uuml;m kişisel verilerin bir ya da birden fazla unsuruna dayanarak tanımlanabilen ger&ccedil;ek kişilere ilişkin her t&uuml;rl&uuml; bilginin kişisel veri olduğunu ve evleviyetle Gizli Bilgi olarak kabul edilmektedir.</p>
    <p>&nbsp;</p>
    <p>(b) <strong>byHorses</strong>, başta T.C. Anayasası olmak &uuml;zere, 5237 sayılı T&uuml;rk Ceza Kanunu, 4721 sayılı T&uuml;rk Medeni Kanunu, 6698 sayılı Kişisel Verilerin Korunması Kanunu ve ismen belirtilmeyen ancak y&uuml;r&uuml;rl&uuml;kte bulunan birincil ve/veya ikincil mevzuat ile koruma altına alınmış kişisel verilerin korunması hususunda azami &ccedil;abayı g&ouml;stermek ve kanuni m&uuml;kellefiyetlerini yerine getirmekle y&uuml;k&uuml;ml&uuml;d&uuml;r.</p>
    <p>&nbsp;</p>
    <p>Yetkili kurum, kuruluş ve yargı makamları tarafından s&ouml;z konusu bilgilerin istenmesi halinde bu bilgilerin paylaşılmasının yasal bir zorunluluk olduğu, bu nedenle paylaşılmasının hukuki bir gereklilik olduğu ve bu sebeple meydana gelebilecek zararlardan <strong>byHorses</strong>&rsquo;ın sorumlu tutulamayacağı işbu s&ouml;zleşmelerin taraflarınca kabul, beyan ve taahh&uuml;t edilmektedir.</p>
    <p>&nbsp;</p>
    <p>(c) <strong>byHorses</strong>&rsquo;a aktarılmış olan Kişisel Veriler&rsquo;in ait olduğu Veri Sahipleri&rsquo;ne karşı Kanun kapsamındaki gerekli aydınlatma y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;n&uuml;n yerine getirilmesi ve kanuni haklar saklı kalmak kaydıyla veri aktarımına dair sorumluluk <strong>byHorses</strong>&rsquo;a aittir.</p>
    <p>&nbsp;</p>
    <p>Ancak kullanıcılara ait kişisel veriler; yasal olarak aktarılması gereken idari ve resmi makamlara, faaliyetleri y&uuml;r&uuml;tmek &uuml;zere dışarıdan hizmet alınan &uuml;&ccedil;&uuml;nc&uuml; taraflara, işbirliği yapılan, program ortağı kurum, kuruluş, işbirliği yapılan yurti&ccedil;i/yurtdışı kuruluşlara, hizmet/destek/danışmanlık alınan ya da proje/program ortağı olunan yurti&ccedil;i/yurtdışı/uluslararası kuruluşlar, g&uuml;venlik, &ccedil;ağrı merkezi gibi alanlarda destek alınan &uuml;&ccedil;&uuml;nc&uuml; kişilerle, alt y&uuml;klenicilere, bağımsız denetim ve destek hizmeti alınan kuruluşlar gibi t&uuml;zel kişilere ve işbirliği i&ccedil;erisinde olan &uuml;&ccedil;&uuml;nc&uuml; tarafların hukuki ve ticari g&uuml;venliğinin teminen ve/veya elektronik ve fiziksel ortamların g&uuml;venliğinin sağlanması ile hukuki zorunluluklar nedeniyle ve yasal sınırlamalar &ccedil;er&ccedil;evesinde aktarılabilecektir.</p>
    <p>&nbsp;</p>
    <p><strong>byHorses</strong>, kişisel verileri, gerekli g&uuml;venlik &ouml;nlemlerini almak kaydıyla T&uuml;rkiye i&ccedil;inde veya yurtdışında bulunan dışında bulunan depolama, arşivleme, bilişim teknolojileri desteği alınan sunucular, hosting şirketleri, program, bulut vb. diğer elektronik ortamlara aktarabilir işleyebilir ve saklayabilecektir. Kullanıcı t&uuml;m bu iş ve işlemlere a&ccedil;ık rıza g&ouml;stermiştir. Bu kapsamda yapılacak olan işlemler sebebi ile <strong>byHorses</strong>&rsquo;ın sorumluluğuna gidilemez.</p>
    <p>&nbsp;</p>
    <ol start="17">
      <li>Kullanıcı, siteye &uuml;ye olduğunda kişisel verilerin korunması, işlenmesine dair s&ouml;zleşme ve aydınlatıcı metninin t&uuml;m maddelerini ayrı ayrı okuyup anladığını, s&ouml;zleşmenin b&uuml;t&uuml;n i&ccedil;eriğini ve b&uuml;t&uuml;n h&uuml;k&uuml;mlerini onayladığını kabul, beyan ve taahh&uuml;t eder.</li>
    </ol>
    <p>&nbsp;</p>
    <ol start="18">
      <li><strong>byHorses</strong>&rsquo;ın, Kişisel Verilerin Korunması Kanunu&rsquo;nda olabilecek değişiklikler ve Kişisel Verileri Koruma Kurulu tarafından belirlenecek y&ouml;ntemler nedeni ile bu aydınlatma bildiriminde değişiklik yapma hakkı saklıdır.</li>
    </ol>
    <p>&nbsp;</p>
    <p>Yukarıda belirtilen haklarınızı kullanmak i&ccedil;in kimliğinizi tespit edici gerekli bilgiler ile KVK Kanunu&rsquo;nun 11. maddesinde belirtilen haklardan kullanmayı talep ettiğiniz hakkınıza y&ouml;nelik a&ccedil;ıklamalarınızı i&ccedil;eren talebinizi; noter kanalıyla veya KVK Kanunu&rsquo;nda belirtilen diğer y&ouml;ntemler ile g&ouml;nderebilir veya ilgili formu <strong>info@byhorses.com</strong> adresine iletebilirsiniz.</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
  </>
)

const ClarificationText = () => {
  const [language, setLanguage] = useState('tr')

  useEffect(() => {
    let lang = localStorage.getItem('multilang')
    if (!lang) lang = 'tr'
    setLanguage(lang)
  }, [])

  if (language === "tr") {
    return (
      <Row>
        <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }}>
          <Policy policyName={"KVKK Aydınlatma Metni"} policy={policyTR} />
        </Col>
      </Row>
    )
  }

  return (
    <Row>
      <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }}>
        <Policy policyName={"Use of Personal Data Clarification Text"} policy={policyTR} />
      </Col>
    </Row>
  )
}
export default ClarificationText