import React, { useEffect, useState } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../../redux/byhorses/actions";
import { Button, Col, Divider, Image, Input, Row } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import { getHorseDetail } from '../api';
import { LeftOutlined } from '@ant-design/icons';
import Info from '../../../utils/Info';
import { useTranslation } from 'react-i18next';
import { FaEthereum } from 'react-icons/fa';
import { CREATOR_FEE } from '../../../utils/Constants';
import SaleModal from './SaleModal';

const Sale = (props) => {
  const { t } = useTranslation()
  const [nftData, setNftData] = useState({})
  const [isSaleModalVisible, setIsSaleModalVisible] = useState()

  const history = useHistory()
  const params = useParams()


  const openSaleModal = () => setIsSaleModalVisible(true)
  const closeSaleModal = () => setIsSaleModalVisible(false)

  useEffect(() => {
    // nft Types -> 1 - Horse | 2 - Jockey | 3 - Stud farm
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source()

    const getHorseDetail_ = async ({ id, source }) => {
      try {
        const response = await getHorseDetail({ id: id, cancelToken: source.cancelToken })
        response.data.nftType = 1
        setNftData(response.data)
      } catch (error) {
        console.log('ERROR: ', error)
      }
    }

    const getJockeyDetail_ = async ({ id, source }) => {

    }

    if (params.id.slice(0, 5) === "horse") {
      let id = params.id.slice(6)
      getHorseDetail_({ id: id, source: source })
    } if (params.id.slice(0, 6) === "jockey") {
      let id = params.id.slice(7)
      getJockeyDetail_({ id: id, source: source })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id])

  return (
    <Row>
      <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }}>
        <Row>
          {props.reduxState?.innerWidth > 768 ?
            <Col xs={2} lg={2}>
              <LeftOutlined style={{ fontSize: 24 }} onClick={() => history.push(`/nft-marketplace/${params.id}`)} />
            </Col> : null
          }
          <Col xs={24} lg={10}>
            {props.reduxState?.innerWidth <= 768 ?
              <>
                <LeftOutlined style={{ fontSize: 24 }} onClick={() => history.push(`/nft-marketplace/${params.id}`)} />
                &nbsp;&nbsp;&nbsp;&nbsp;
              </>
              : null
            }
            <span style={{ fontSize: 18, fontWeight: 500 }}>Satılık Liste Ürünü</span>
            <br /><br />
            <Image preview={false} src={"https://dummyimage.com/400x400/c756c7/0011ff.png&text=."}
              width={350} style={{ borderRadius: 8, marginBottom: 24 }} />
            <br />
            <span style={{ fontSize: 22 }}>{nftData.name}</span><br />
            <span style={{ color: "#c6017f" }}>Koleksiyon Adı</span>
          </Col>
          <Col xs={24} lg={8}>
            <Row gutter={[24, 24]}>
              <Col xs={12}>
                <span style={{ fontSize: 18, fontWeight: 500 }}>Fiyat</span>
              </Col>
              <Col xs={12} align='right'>
                <Info placement={"right"} title={t('translation:sellInfo1')}
                  iconStyle={{ fontSize: 24, color: "#707070", cursor: "pointer" }} />
              </Col>
              <Col xs={24}>
                <Input className='purple-input' placeholder='Tutar...' type='number' prefix={<><FaEthereum />&nbsp;&nbsp; ETH&nbsp;</>} />
              </Col>
              <Col xs={24}>
                <Divider style={{ borderColor: "#FFFFFF" }} />
              </Col>
              <Col xs={24}>
                <Row gutter={[16, 16]} style={{ fontSize: 18 }}>
                  <Col xs={12}>{t('translation:fees')}</Col>
                  <Col xs={12} align='right'>
                    <Info placement={"right"} title={t('translation:sellInfo2')}
                      iconStyle={{ fontSize: 24, color: "#707070", cursor: "pointer" }} />
                  </Col>
                  <Col xs={12}>{t('translation:serviceFee')}</Col>
                  <Col xs={12} align='right'>0.5%</Col>
                  <Col xs={12}>{t('translation:creatorFee')}</Col>
                  <Col xs={12} align='right'>{CREATOR_FEE}%</Col>
                  <Col xs={24}>
                    <Button type="primary" block size='large' style={{ fontSize: 24, paddingTop: 0, paddingBottom: 0 }} onClick={openSaleModal}>{t('translation:completeListing')}</Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* {isSaleModalVisible &&

        } */}
        <SaleModal t={t} visible={isSaleModalVisible} onOk={openSaleModal} onCancel={closeSaleModal} />


      </Col>
    </Row>
  )
}

const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(Sale));