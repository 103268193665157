import React from 'react'
import { Col, Image, Row } from 'antd';
import notFoundIcon from '../../../assets/images/icons/collection-not-found-icon.png';

const MyCollection = (props) => {
  return (
    <Row style={{ marginTop: 20 }}>
      <Col xs={24} align='center'>
        <Image preview={false} src={notFoundIcon} width={150} />
        <p style={{ fontWeight: 500, fontSize: 22, marginTop: 10 }}>{props.t('translation:collectionNotFound')}</p>
      </Col>
    </Row>
  )
}

export default MyCollection