import { publicApi } from '../../services/axios'


export const updateNft = async ({ payload, cancelToken }) => {
  try {
    const response = await publicApi.post(`management/updatefavoritecount`, payload, { cancelToken: cancelToken });
    return response;
  } catch (error) {
    return error.response;
  }
}