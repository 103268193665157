export const CREATOR_FEE = 10

// This regex used to make the password strong
export const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%.,:;+-/^&*])(?=.{8,})");


export const skinColour = ["chestnut", "liverChestnut", "flaxenChestnut", "gray", "doppleGray", "roseGray", "bay", "darkBay", "black", "roan"]
export const breed = ["thorougbred", "arabian"]
export const sex = ["filly", "colt", "mare", "stallion", "gelding"]

