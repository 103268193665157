import React, { useEffect, useState } from 'react'
import { Button, Col, Image, Modal, Row } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { getHorseDetail } from './api'
import moment from 'moment'
// import img1_ from "../../assets/images/byhorse/animHorse.gif"
import solana from "../../assets/images/icons/solana.png"
// import NftMarketplaceCard from '../../components/nftMarketplace/NftMarketplaceCard'
import ReactPlayer from 'react-player'
// import horse_ from "../../assets/images/nft-horse/at.png"
import familyTreeIcon from "../../assets/images/icons/family-tree.png"
import { breed, sex, skinColour } from '../../utils/Constants'
import { FamilyTree } from '../../utils/FamilyTree'
import Purchase from './purchase/Purchase'
import { isConnected } from '../../contract/utils'
import Fav from '../../components/nftMarketplace/Fav'

// const img1 = "https://dummyimage.com/400x400/000/asfd"
// const img2 = "https://dummyimage.com/400x400/4f354f/444bab"
// const img3 = "https://dummyimage.com/400x400/631763/c2c2c4"

// const imgWidth = 120

const labelStyle = {
  color: '#FFFFFF',
}
const textStyle = {
  color: '#FFFFFF',
}

const LABEL_XS = 12


const NftDetail = (props) => {
  const { t } = useTranslation()
  // const [focusedImage, setFocusedImage] = useState(img1)
  const [nftData, setNftData] = useState({})
  const [familyNames, setFamilyNames] = useState({})

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isFullScreenModalVisible, setIsFullScreenModalVisible] = useState(false)
  const [isPurchaseModalVisible, setIsPurchaseModalVisible] = useState(false)

  const history = useHistory()
  const params = useParams()


  const routeBack = () => {
    history.push('/nft-marketplace')
  }

  const closePurchaseModal = () => setIsPurchaseModalVisible(false)
  const openPurchaseModal = () => setIsPurchaseModalVisible(true)

  const closeModal = () => setIsModalVisible(false)
  const openModal = () => setIsModalVisible(true)


  const handleBuyNft = async () => {
    const isConnected_ = await isConnected()
    if (isConnected_) {
      openPurchaseModal()
    } else {
      const metamaskBtn = document.getElementById("metamask-btn")
      metamaskBtn.click()
    }
  }

  useEffect(() => {
    // nft Types -> 1 - Horse | 2 - Jockey | 3 - Stud farm
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source()

    const getHorseDetail_ = async ({ id, source }) => {
      try {
        const response = await getHorseDetail({ id: id, cancelToken: source.cancelToken })
        response.data.nftType = 1
        setNftData(response.data)
        setFamilyNames({
          sex: (response.data.sex === 2 || response.data.sex === 4) ? "male" : "female",
          main: response.data.name,
          mother: response.data.mother.name || "",
          mothersMother: response.data.mothersMother.name || "",
          mothersFather: response.data.mothersFather.name || "",
          father: response.data.father.name || "",
          fathersMother: response.data.fathersFather.name || "",
          fathersFather: response.data.fathersMother.name || "",
        })
      } catch (error) {
        console.log('ERROR: ', error)
      }
    }

    const getJockeyDetail_ = async ({ id, source }) => {

    }

    if (params.id.slice(0, 5) === "horse") {
      let id = params.id.slice(6)
      getHorseDetail_({ id: id, source: source })
    } if (params.id.slice(0, 6) === "jockey") {
      let id = params.id.slice(7)
      getJockeyDetail_({ id: id, source: source })
    }

  }, [params.id])

  // const handleRouting = (id) => {
  //   history.push(`/nft-marketplace/${id}`)
  // }
  // const similarHorses = nftData?.similarHorses?.map((horse, index) => {
  //   horse.image = horse_
  //   const data = {
  //     key: index,
  //     id: horse.id,
  //     data: horse,
  //     onClick: () => handleRouting("horse-" + data.id)
  //   }
  //   return (
  //     <Col xs={{ span: 22, offset: 1 }} lg={{ span: 8, offset: 0 }} key={index}>
  //       <NftMarketplaceCard {...data} />
  //     </Col>
  //   )
  // })

  useEffect(() => {
    document.body.classList.add('bg-black',)
    return () => {
      document.body.classList.remove('bg-black')
    }
  }, [])

  const closeFullScrenModal = () => setIsFullScreenModalVisible(false)
  const openFullScrenModal = () => setIsFullScreenModalVisible(true)

  return (
    <Row>
      <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }}>
        <Row gutter={[0, 24]}>
          <Col xs={24} lg={11}>
            <ReactPlayer
              url={"https://byhorses-api.devtagon.com/media/uploadImage/nft_video.mp4"}
              config={{ file: { attributes: { playsInline: true, }, }, }}
              style={{ cursor: "pointer" }} onClick={openFullScrenModal}
              playing height={430} muted={true} loop={true} />
            {/* <Image preview={false} src={focusedImage} width={370} /> */}
            {/* <div style={{ display: 'flex', marginTop: 10 }}>
              <Image className='hover-image' preview={false} src={img1} width={imgWidth} onClick={() => setFocusedImage(img1)} />
              <Image className='hover-image' preview={false} src={img2} width={imgWidth} onClick={() => setFocusedImage(img2)} style={{ marginLeft: 5 }} />
              <Image className='hover-image' preview={false} src={img3} width={imgWidth} onClick={() => setFocusedImage(img3)} style={{ marginLeft: 10 }} />
            </div> */}
          </Col>
          <Col xs={24} lg={{ span: 12, offset: 1 }}>
            <h1>{nftData.name}</h1>
            <h3 style={{ marginTop: "-15px", fontWeight: 300 }}>ID#{nftData.id} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <Image src={familyTreeIcon} width={24} preview={false} className="cursor-p" onClick={openModal} /></h3>
            <Row style={{ marginTop: 20 }} gutter={[16, 8]}>
              <Col xs={LABEL_XS}>
                <span style={labelStyle}>{t('translation:kind')}:</span>
              </Col>
              <Col xs={12}>
                <span style={{ color: '#C4017E', fontSize: 16 }}>{t('translation:rare')}</span>
              </Col>
              <Col xs={LABEL_XS}>
                <span className='nft-detail-responsive-text' style={labelStyle}>{t('translation:birthDate')}:</span>
              </Col>
              <Col xs={12}>
                <span className='nft-detail-responsive-text' style={textStyle}>{moment(nftData.birthDate).format('DD.MM.YYYY')}</span>
              </Col>
              <Col xs={LABEL_XS}>
                <span className='nft-detail-responsive-text' style={labelStyle}>{t('translation:sex')}:</span>
              </Col>
              <Col xs={12}>
                <span className='nft-detail-responsive-text' style={textStyle}>{nftData.sex ? t(`translation:${sex[nftData.sex - 1]}`) : ""}</span>
              </Col>
              <Col xs={LABEL_XS}>
                <span className='nft-detail-responsive-text' style={labelStyle}>{t('translation:colour')}:</span>
              </Col>
              <Col xs={12}>
                <span className='nft-detail-responsive-text' style={textStyle}>{nftData.colour ? t(`translation:${skinColour[nftData.colour - 1]}`) : ""}</span>
              </Col>
              <Col xs={LABEL_XS}>
                <span className='nft-detail-responsive-text' style={labelStyle}>{t('translation:breed')}:</span>
              </Col>
              <Col xs={12}>
                <span className='nft-detail-responsive-text' style={textStyle}>{nftData.breed ? t(`translation:${breed[nftData.breed - 1]}`) : ""}</span>
              </Col>
              <Col xs={LABEL_XS}>
                <span className='nft-detail-responsive-text' style={labelStyle}>{t('translation:owner')}:</span>
              </Col>
              <Col xs={12}>
                <Row>
                  {nftData?.horseOwnersWithPercentage?.map((owner, index) => (
                    <Col xs={24} key={index}><span className='nft-detail-responsive-text' style={textStyle}>{owner.horsePerson__fullName}&nbsp;&nbsp; %{owner.ownerPercentage}</span></Col>
                  ))}
                </Row>
              </Col>
              <Col xs={LABEL_XS}>
                <span className='nft-detail-responsive-text' style={labelStyle}>{t('translation:breeder')}:</span>
              </Col>
              <Col xs={12}>
                <Row>
                  {nftData?.breeder?.map((breeder, index) => (
                    <Col xs={24} key={index}><span className='nft-detail-responsive-text' style={textStyle}>{breeder.fullName}</span></Col>
                  ))}
                </Row>
              </Col>
              <Col xs={24} style={{ marginTop: 20 }}>
                <Row align='middle'>
                  <Col xs={2}>
                    <Image preview={false} src={solana} width={30} />
                  </Col>
                  <Col xs={10}>
                    <span style={{ fontSize: 24 }}>0.064 &nbsp;&nbsp;&nbsp;</span><span style={{ fontWeight: 300 }}> ($68.86)</span>
                  </Col>
                  <Col xs={10}>
                    <Row>
                      <Col xs={8}>
                        <Button type='primary' style={{ marginLeft: 6 }} onClick={handleBuyNft}>{t('translation:buy')}</Button>
                      </Col>
                      <Col xs={8}>
                        <Button type='primary' style={{ marginLeft: 6 }} onClick={() => history.push(`/nft-marketplace/sell/${params.id}`)}>Sell</Button>
                      </Col>
                      <Col xs={8}>
                        <Fav data={nftData} isCard={false} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[24, 24]} style={{ marginTop: 50 }}>
          <Col xs={24}>
            <Button type='primary' style={{ marginTop: 10, marginBottom: 10 }} icon={<ArrowLeftOutlined />} onClick={routeBack}>{t('translation:back')}</Button>
          </Col>
        </Row>


        <Modal title={''} width={"80%"} className='family-tree-modal'
          visible={isModalVisible} onOk={closeModal} onCancel={closeModal} footer={false}>
          <Row gutter={[24, 24]}>
            <Col xs={24} align='center'>
              {FamilyTree(familyNames)}
            </Col>
          </Row>
        </Modal>

        <Modal title={''} width={"52%"} className='nft-fulscreen-modal'
          visible={isFullScreenModalVisible} onOk={closeFullScrenModal} onCancel={closeFullScrenModal} footer={false}>
          <Row>
            <Col xs={24} align='center'>
              <ReactPlayer
                url={"https://byhorses-api.devtagon.com/media/uploadImage/nft_video.mp4"}
                config={{ file: { attributes: { playsInline: true, }, }, }}
                style={{ transform: "scale(1.8)" }}
                playing muted={true} loop={true} />
            </Col>
          </Row>
        </Modal>


        <Purchase visible={isPurchaseModalVisible} onOk={closePurchaseModal} onCancel={closePurchaseModal} t={t} params={params} nftData={nftData} />

      </Col>
    </Row>
  )
}

export default NftDetail