import React from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../../redux/byhorses/actions";
import { Button, Col, Image, Modal, Row, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { CREATOR_FEE } from '../../../utils/Constants'
import { FaEthereum } from 'react-icons/fa';
import { useState } from 'react';
import favSelectedIcon from "../../../assets/images/icons/fav-selected-icon.png"
import Info from '../../../utils/Info';
import { updateNft } from '../../../components/nftMarketplace/api';


const spanStyle = {
  color: "#231340",
  fontWeight: 500,
  fontSize: 16
}

// const badgeStyle = {
//   padding: "8px 16px",
//   fontSize: 20,
//   fontWeight: 600,
//   borderRadius: "50%",
//   border: "1px solid #C6097F",
//   color: "#000"
// }

const Purchase = (props) => {
  const [isTrashCanModalVisible, setIsTrashCanModalVisible] = useState()
  const [step, setStep] = useState(1)

  const openTrashCanModal = () => setIsTrashCanModalVisible(true)
  const closeTrashCanModal = () => setIsTrashCanModalVisible(false)

  const handleApprove = () => {
    setStep(2)
    setTimeout(() => {
      setStep(3)
    }, 3000)
  }
  const handleCancelPurchase = () => {
    const favs = JSON.parse(window.localStorage.getItem("favoriteNFTS"))
    if (favs === null) {
      openTrashCanModal()
      return
    }
    const favorite = favs.filter((data) => (data.type === props.nftData.nftType && data.nftId === props.nftData.id))
    if (favorite.length > 0) {
      closeTrashCanModal()
      props.onCancel()
    } else {
      openTrashCanModal()
    }
  }

  const handleAddToFav = () => {
    const addToLocalStorage = ({ storage, type }) => {
      storage.push({ type: type, nftId: props.nftData.id })
      window.localStorage.setItem("favoriteNFTS", JSON.stringify(storage))
    }
    const handleFavorite = async ({ isFavorite_, nftType }) => {
      let count;
      const storage = JSON.parse(window.localStorage.getItem("favoriteNFTS")) || []
      if (isFavorite_ === true) {
        count = props.nftData.favoriteCount ? props.nftData.favoriteCount + 1 : 1
        addToLocalStorage({ storage: storage, type: nftType })
      }
      try {
        await updateNft({ payload: { nftType: nftType, nftId: props.nftData.id, favoriteCount: count } })
      } catch (error) {
        console.error(error)
      }
    }

    handleFavorite({isFavorite_: true, nftType: props.nftData.nftType})
    closeTrashCanModal()
    props.onCancel()
    window.location.reload()
  }

  return (
    <>
      <Modal title={''} width={props.reduxState?.innerWidth > 768 ? (step !== 3 ? "50%" : "35%") : "99%"} className='purchase-modal'
        visible={props.visible} onOk={props.onOk} onCancel={handleCancelPurchase} footer={false}>
        {step !== 3 ?
          <>
            <Row align='middle'>
              <Col xs={24} align='center'>
                <span style={{ fontSize: 22, fontWeight: 500, color: "#231340" }}>{props.t('translation:completePayment')}</span>
              </Col>
            </Row>
            <Row style={{ marginTop: 30, paddingBottom: 8, borderBottom: "1px solid #231340" }}>
              <Col xs={12} align='left'>
                <span style={spanStyle}>{props.t('translation:product')}</span>
              </Col>
              <Col xs={12} align='right'>
                <span style={spanStyle}>{props.t('translation:price')}</span>
              </Col>
            </Row>
            <Row gutter={[24, 24]} align='middle' style={{ marginTop: 8, paddingBottom: 8, borderBottom: "1px solid #231340" }}>
              <Col xs={6} lg={4}>
                <Image preview={false} style={{ borderRadius: 8 }} width={props.reduxState?.innerWidth > 768 ? undefined : 75} 
                src={props.image || "https://dummyimage.com/400x400/c756c7/0011ff.png&text=."} />
              </Col>
              <Col xs={12} lg={16}>
                <h1 style={{ fontSize: props.reduxState?.innerWidth > 768 ? 28 : 22, color: "#231340", fontWeight: 500, lineHeight: 0.5 }}>JIGGLYPUFF</h1>
                <h4 style={{ fontSize: 14, color: "#C6017F", fontWeight: 500, lineHeight: 1 }}>Koleksiyon Adı</h4>
                <h6 style={{ fontSize: 10, color: "#231340", fontWeight: 400, lineHeight: 1 }}>{props.t('translation:creatorFee')} %{CREATOR_FEE} &nbsp;&nbsp;&nbsp;
                  <Info iconStyle={{ fontSize: 12, color: "#707070", cursor: "pointer", verticalAlign: "text-bottom" }}
                    title={props.t('translation:creatorFeeDesc')} placement="right" />
                </h6>
              </Col>
              <Col xs={6} lg={4} align='right'>
                <h1 style={{ fontSize: 14, color: "#231340", fontWeight: 600, lineHeight: 0.5 }}><FaEthereum /> 0.064</h1>
                <h4 style={{ fontSize: 14, color: "#231340", fontWeight: 400, lineHeight: 1 }}>$68.86</h4>
              </Col>
            </Row>
            {step === 1 ?
              <Row style={{ marginTop: 20 }}>
                <Col xs={24} align='center'>
                  <Button type="primary" size={"large"} onClick={handleApprove}>{props.t('translation:confirmPayment')}</Button>
                </Col>
              </Row> : step === 2 ?
                <Row gutter={[24, 24]} style={{ marginTop: 20 }} align='top'>
                  <Col xs={24}>
                    <h1 style={{ fontSize: 22, fontWeight: 600, color: "#231340" }}>{props.t('translation:currencyConfirmation')}</h1>
                    <p style={{ color: "#231340" }}>{props.t('translation:currencyConfirmationParagraph1')}&nbsp;<b>ETH</b>&nbsp;
                      {props.t('translation:currencyConfirmationParagraph2')}</p>
                    <div style={{
                      borderRadius: 8,
                      padding: 8,
                      display: "flex",
                      justifyContent: "space-between",
                      width: 190,
                      backgroundColor: "#D9D8D9",
                    }}>
                      <span style={{ color: "#453F4E", fontWeight: 600, fontSize: 16 }}>{props.t('translation:awaitingApproval')}</span>
                      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                    </div>
                  </Col>
                </Row>
                : null
            }
          </>
          :
          <>
            <Row align='middle'>
              <Col xs={24} align='center'>
                <span style={{ fontSize: 22, fontWeight: 500, color: "#231340" }}>{props.t('translation:completePayment')}</span>
              </Col>
            </Row>
            <Row gutter={[24, 24]} style={{ marginTop: 24 }} >
              <Col xs={24} align='center'>
                <Image preview={false} style={{ borderRadius: 8 }} width={180} src={props.image || "https://dummyimage.com/400x400/c756c7/0011ff.png&text=."} />
              </Col>
              <Col xs={24} align='center'>
                <span style={{ color: "#C6017F", fontWeight: 500 }}>Koleksiyon Adı </span>
                <span style={{ color: "#000" }}>koleksiyonundan satın aldığın </span>
                <span style={{ color: "#C6017F", fontWeight: 500 }}>JIGGLYPUFF</span>
              </Col>
              <Col xs={24} align='center'>
                <span style={{ color: "#000", fontWeight: 600 }}>{props.t('translation:transactionId')}</span><br />
                <span style={{ color: "#C6017F", fontWeight: 500 }}>TX HASH</span>
              </Col>
              <Col xs={24} align='center'>
                <Button type="primary" size='large' style={{ fontSize: 22, padding: "0px 24px 0px 24px" }}>İncele</Button>
              </Col>
            </Row>
          </>
        }

      </Modal>

      <Modal title={''} width={props.reduxState?.innerWidth > 768 ? "30%" : "98%"} className="trash-modal"
        visible={isTrashCanModalVisible} onOk={openTrashCanModal} onCancel={closeTrashCanModal} footer={false}>
        <Row gutter={[24, 24]}>
          <Col xs={24} align='center'>
            <Image src={favSelectedIcon} preview={false} width={40} style={{ borderRadius: "50%", border: "1px solid #000000" }} />
          </Col>
          <Col xs={24} align='center'>
            <h1 style={{ fontSize: 18, color: "#C6017F", fontWeight: 500, lineHeight: 1 }}>SİL VE FAVORİLERE EKLE</h1>
            <p style={{ fontSize: 14, color: "#000" }}>Bu ürünü sepetinden çıkardıktan sonra favoriye eklemek ister misin?</p>
          </Col>
          <Col xs={12} align='left'>
            <Button onClick={() => {
              props.onCancel()
              closeTrashCanModal()
            }}><span style={{ color: "#000" }}>Sil</span></Button>
          </Col>
          <Col xs={12} align='right'>
            <Button type='primary' onClick={handleAddToFav}>Sil ve Favorilere Ekle</Button>
          </Col>
        </Row>
      </Modal>
    </>
  )
}

const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(Purchase));