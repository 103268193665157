import React, { useEffect } from 'react'
import { Button, Col, Image, Input, Popover, Row, Upload } from 'antd'
import { CopyOutlined, InfoCircleOutlined } from '@ant-design/icons'
import placeholderImg from '../../../assets/images/byhorse/team/staff-placeholder.png';
import { useState } from 'react';
import { showNotification } from '../../../utils/showNotification';
import getBase64 from '../../../utils/GetBase64';
import axios from 'axios';
import { getProfile, updateProfile } from '../api';
import validator from 'validator';

const labelStyle = {
  fontWeight: 700,
  color: "#FFFFFF",
  marginLeft: 2,
  lineHeight: 3,
  fontSize: 16
}

const MyProfile = (props) => {
  const [previewImage, setPreviewImage] = useState(undefined)
  const [info, setInfo] = useState({})

  const copyAccount = () => {
    navigator.clipboard.writeText(window.localStorage.getItem('account'))
  }

  const uploadConfig = {
    beforeUpload(file) { return true },
    customRequest(info) {
      const isImage = info.file.type.includes("image")
      if (!isImage) {
        showNotification({
          type: 'error',
          title: props.t('translation:notImageErrorTitle'),
          description: props.t('translation:notImageErrorDesc'),
        })
        return
      }
      const isLt2M = info.file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        showNotification({
          type: 'error',
          title: props.t('translation:fileSizeErrorTitle'),
          description: props.t('translation:fileSizeErrorDesc'),
        })
        return
      }
      updateInfo("photo",info.file)
      getBase64(info.file).then(res => {
        setPreviewImage(res)
      })
    },
    onChange(info) { return },
    showUploadList: false,
    maxCount: 1,
  };

  const updateInfo = (name, value) => {
    setInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  useEffect(() => {
    const getUserInfo = async ({source}) => {
      const user = JSON.parse(window.localStorage.getItem('user'))
      const response = await getProfile({id: user.id, cancelToken: source.cancelToken})
      setInfo({
        username: response.data.userName,
        email: response.data.eMail,
        photo: response.data.photo,
      })
    }

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source()
  
    getUserInfo({source: source})
  }, [])

  const submitProfile = async () => {
    if(!info.username && !info.email && !info.photo) return
    if (info.email && !validator.isEmail(info.email)) {
      showNotification({
        type: 'error',
        title: props.t('translation:emailErrorTitle'),
        description: props.t('translation:emailErrorDesc')
      })
      return
    }
    const user = JSON.parse(window.localStorage.getItem('user'))
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source()


    const fData = new FormData()
    fData.append("userName", info.username)
    fData.append("eMail", info.email)
    if(typeof info.photo !== "string") {
      fData.append("photo", info.photo)
    }
    const res = await updateProfile({id: user.id, payload: fData, cancelToken: source.cancelToken});
    if(res.status === 200) {
      showNotification({
        type: 'success',
        title: props.t('translation:profileUpdateSuccessTitle'),
        description: props.t('translation:profileUpdateSuccessDesc')
      })
    }
  }
  

  return (
    <Row gutter={[24,24]}>
      <Col xs={24} lg={12}>
        <Row>
          <Col xs={24} lg={12}>
            <Row gutter={[24, 8]}>
              <Col xs={24}>
                <span style={labelStyle}>{props.t('translation:username')}</span>
                <Input className='purple-input' allowClear value={info.username}
                  placeholder={""}
                  onChange={(event) => updateInfo("username", event.target.value)} />
              </Col>
              <Col xs={24}>
                <span style={labelStyle}>{props.t('translation:email')}</span>
                <Input className='purple-input' allowClear value={info.email}
                  placeholder={""}
                  onChange={(event) => updateInfo("email", event.target.value)} />
              </Col>
              <Col xs={24}>
                <span style={labelStyle}>{props.t('translation:walletAddress')}</span>
                <Input className='purple-input' value={window.localStorage.getItem('account')}
                  suffix={<CopyOutlined onClick={copyAccount} />} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col xs={24} lg={12}>
        <Row>
          <Col xs={24} lg={12} align='center'>
            <Row gutter={[24, 24]}>
              <Col xs={24}>
                <Upload {...uploadConfig}>
                  {/* 
                    Servis bağlantıları sonrası User image öncelikli olacak şekilde bir ayarlama yapılacak.!
                    bunun için previewImage ? previewImage : user.image ? user.img : placeholderImg gibi bir condition yazılmalı.!
                  */}
                  <Image src={previewImage ? previewImage : info.photo ? info.photo : placeholderImg} preview={false} width={150} style={{ cursor: "pointer", borderRadius: "50%" }} />
                </Upload>
              </Col>
              <Col xs={24}>
                <span style={{ fontSize: 16 }}>{props.t('translation:profilePicture')}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                <Popover placement="right" title={""} content={<div style={{ textAlign: 'center' }}>{props.t('translation:profileImageDesc1')}<br />{props.t('translation:profileImageDesc2')}</div>}>
                  <InfoCircleOutlined style={{ fontSize: 20, color: "#707070", cursor: "pointer", verticalAlign: "text-bottom" }} />
                </Popover>
              </Col>
              <Col xs={24}>
                <Button className='' type='primary'
                  onClick={submitProfile} size="large"
                  style={{
                    border: 'none',
                    backgroundColor: '#C6017F',
                    width: "40%"
                  }}>
                  {props.t('translation:save')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default MyProfile