import React, { useEffect } from 'react'
import { Col, Image, Row } from 'antd'
import img from "../../assets/images/byhorse/coming-soon.png"
import { useState } from 'react'
const ComingSoonPage = () => {
  const [language, setLanguage] = useState('tr')

  useEffect(() => {
    let lang = localStorage.getItem('multilang')
    if (!lang) lang = 'tr'
    setLanguage(lang)
  }, [])

  if (language === 'tr') {
    return (
      <Row align='middle'>
        <Col xs={8} align='center'>
          <span style={{ fontSize: 98, color: "#c6017f", fontWeight: 600 }}>ÇOK</span>
        </Col>
        <Col xs={8} align='center'>
          <Image preview={false} src={img} />
        </Col>
        <Col xs={8} align='left'>
          <span style={{ fontSize: 78, color: "#FFFFFF", fontWeight: 600 }}>YAKINDA!</span>
        </Col>
      </Row>
    )
  } else {
    return (
      <Row align='middle'>
        <Col xs={8} align='center'>
          <span style={{ fontSize: 78, color: "#FFFFFF", fontWeight: 600 }}>COMING</span>
        </Col>
        <Col xs={8} align='center'>
          <Image preview={false} src={img} />
        </Col>
        <Col xs={8} align='center'>
          <span style={{ fontSize: 78, color: "#c6017f", fontWeight: 600 }}>SOON!</span>
        </Col>
      </Row>
    )

  }
}

export default ComingSoonPage