import React from 'react'
import { Col, Image, Row, Space } from 'antd'
import coin from '../../assets/images/byhorse/HorseCoin.png'
import twitter from '../../assets/images/icons/twitter.png'
import telegram from '../../assets/images/icons/telegram.png'
import instagram from '../../assets/images/icons/instagram.png'
import facebook from '../../assets/images/icons/facebook.png'
import medium from '../../assets/images/icons/medium.png'
import tiktok from '../../assets/images/icons/tiktok.png'
import { useTranslation } from 'react-i18next'

const WebFooter = (props) => {
  const { t } = useTranslation()


  const iconWidth = 20

  const titleStyle = {
    color: '#c6017f'
  }

  return (
    <>
      <div className='p-20' style={{ paddingBottom: 10, marginTop: 30 }}>
        <Row>
          <Col xs={24} lg={{ span: 20, offset: 2 }}>
            <Row gutter={[24, 24]} align='top'>
              <Col xs={12} lg={5} align='left'>
                <h4 style={titleStyle}>{t('translation:aboutUs')}</h4>
                <a href='/aboutus'>{t('translation:team')}</a><br />
                <a href='/faq'>{t('translation:faq')}</a><br />
                <a href='/horse-and-horse-history'>{t('translation:horseAndHorseHistory')}</a><br />
                <a href='/contact'>{t('translation:contactUs')}</a><br />
              </Col>
              <Col xs={12} lg={5} align='left'>
                <h4 style={titleStyle}>byHorses</h4>
                <a href='/whitepaper'>{t('translation:whitepaper')}</a><br />
                <a href='/roadmap'>{t('translation:roadmap')}</a><br />
                <a href='/nft-marketplace'>{t('translation:marketplace')}</a><br />
                <a href='/tokenomics'>{t('translation:tokenomics')}</a>
              </Col>
              <Col xs={12} lg={5} align='left'>
                <h4 style={titleStyle}>{t('translation:smartContracts')}</h4>
                <a href='/'>Github</a><br />
              </Col>
              <Col xs={12} lg={8} align={'right'}>
                <Image preview={false} src={coin} width={80} style={{ marginTop: '10%' }} />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <Row style={{ backgroundColor: '#FFFFFF', lineHeight: '3.1', fontSize: 12, fontWeight: 600 }} align='middle'>
        <Col xs={24} lg={{ span: 20, offset: 2 }}>
          <Row gutter={[24, 24]}>
            <Col xs={12} lg={5} align='left'>
              <a className='link-on-white' style={{ marginLeft: 15 }} href="terms-of-service">{t('translation:confidentialityAgreement')}</a>
            </Col>
            <Col xs={12} lg={5} align='left'>
              <a className='link-on-white' style={{ marginLeft: 10 }} href="privacy-policy">{t('translation:privacyPolicy')}</a>
            </Col>
            <Col xs={12} lg={4} align='left'>
              <a className='link-on-white' href={"kvkk"}>{t('translation:clarificationText')}</a>
            </Col>
            <Col xs={24} lg={5} align='center'>
              <a className='link-on-white' href={"cookie-policy"}>{t('translation:cookiePolicy')}</a>
            </Col>
            <Col xs={24} lg={5} align='right' style={{ marginTop: 5 }}>
              <Space>
                <a target={"_blank"} rel="noreferrer" href='https://t.me/byHorsesTurkey'><Image preview={false} src={telegram} width={iconWidth} /></a>
                <a target={"_blank"} rel="noreferrer" href='https://medium.com/@byHorsesgame'><Image preview={false} src={medium} width={25} /></a>
                <a target={"_blank"} rel="noreferrer" href='https://twitter.com/byhorsesgame?s=21&t=FUAGW8MgvQedJ6-YB0N87Q'><Image preview={false} src={twitter} width={iconWidth} /></a>
                <a target={"_blank"} rel="noreferrer" href='https://www.facebook.com/byHorsesgame'><Image preview={false} src={facebook} width={iconWidth} /></a>
                <a target={"_blank"} rel="noreferrer" href='https://www.instagram.com/byhorsesofficial/'><Image preview={false} src={instagram} width={iconWidth} /></a>
                <a target={"_blank"} rel="noreferrer" href='https://vt.tiktok.com/ZSR1Nmeaq/'><Image preview={false} src={tiktok} width={iconWidth} /></a>
              </Space>
            </Col>
          </Row>
        </Col>

      </Row>
    </>
  )
}

export default WebFooter