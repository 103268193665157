import React, { useEffect, useState } from 'react'
import { useHistory, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../redux/byhorses/actions";
import { Col, Divider, Drawer, Image, Popover, Row, Space } from 'antd'
import logo from "../../assets/images/byhorses-logo/logowhite.png"
import profileIcon from "../../assets/images/icons/header-profile-icon.png";
import { Link } from 'react-router-dom'
import { MenuOutlined, SettingOutlined, StarFilled, UserOutlined } from '@ant-design/icons';
import ConnectWallet from './ConnectWallet';
import SelectLanguage from './SelectLanguage';
import { useTranslation } from 'react-i18next';
import { FiLayers, FiLogOut } from 'react-icons/fi';
import ProfileButton from './ProfileButton';
import { handleLogOut } from '../../utils/Authentication';

const Header = (props) => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false);

  const closeMobileHeader = () => setVisible(false)
  const openMobileHeader = () => setVisible(true)

  const history = useHistory()

  useEffect(() => {
    props.setRedux({ innerWidth: window.innerWidth })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const routeAccount = (tab) => {
    history.push(`/account`, { accountTab: tab })
  }

  return (
    <>
      {
        props.reduxState && props.reduxState.innerWidth > 768 ?
          <div style={{ marginTop: 10, marginBottom: 0 }}>
            <Row>
              <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>
                <Row align='middle'>
                  <Col xs={3} align='left'>
                    <Link to='/'><Image preview={false} src={logo} width={60} /></Link>
                  </Col>
                  <Col xs={13} align='left'>
                    <Space size={'large'}>
                      <Link to='/nft-marketplace' className="link-text">{t('translation:nftMarketplace')}</Link>
                      <Link to='/game' className="link-text">{t('translation:game')}</Link>
                      <Link to='/byh' className="link-text">$BYH</Link>
                      <Link to='/roadmap' className="link-text">{t('translation:roadmap')}</Link>
                      <Link to='/aboutus' className="link-text">{t('translation:aboutUs')}</Link>
                    </Space>
                  </Col>
                  <Col xs={8} align='right'>
                    <Space size={'large'}>
                      <ProfileButton />
                      <ConnectWallet mobile={false} />
                      <SelectLanguage />
                    </Space>
                  </Col>
                </Row>
              </Col>
              <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }} style={{ marginTop: '-18px' }}>
                <Divider style={{ backgroundColor: 'rgb(176, 176, 176)' }} />
              </Col>
            </Row>
          </div>
          :
          <div style={{}}>
            <Row style={{ borderBottom: "1px solid #707070", padding: 8, marginTop: 10, marginBottom: 10 }}>
              <Col xs={8} align='left'>
                <MenuOutlined style={{ fontSize: 24 }} onClick={openMobileHeader} />
              </Col>
              <Col xs={8} align='center'>
                <Link to='/'><Image preview={false} src={logo} width={30} /></Link>
              </Col>
              <Col xs={8} align='right'>
                <Popover placement="bottomRight" title={null}
                  content={
                    <Space>
                      <UserOutlined style={{ fontSize: 22, color: "#341C61" }} onClick={() => routeAccount("1")} />
                      <StarFilled style={{ fontSize: 22, color: "#341C61" }} onClick={() => routeAccount("2")} />
                      <FiLayers style={{ fontSize: 22, color: "#341C61" }} onClick={() => routeAccount("3")} />
                      <SettingOutlined style={{ fontSize: 22, color: "#341C61" }} onClick={() => routeAccount("4")} />
                      <FiLogOut style={{ fontSize: 22, color: "#341C61" }} onClick={handleLogOut} />
                    </Space>
                  } trigger="click">
                  <Image preview={false} src={profileIcon} width={30} />
                </Popover>
              </Col>
            </Row>

            <Drawer title={""} placement="left" onClose={closeMobileHeader} visible={visible}
              className='mobile-menu'>
              <Row gutter={[0, 42]}>
                <Col xs={24} align='center'>
                  <Link to='/'><Image preview={false} src={logo} width={60} /></Link>
                </Col>
                <Col xs={24} align='center'>
                  <Link to='/nft-marketplace' className="link-text font-1rem">{t('translation:nftMarketplace')}</Link>
                </Col>
                <Col xs={24} align='center'>
                  <Link to='/game' className="link-text font-1rem">{t('translation:game')}</Link>
                </Col>
                <Col xs={24} align='center'>
                  <Link to='/byh' className="link-text font-1rem">$BYH</Link>
                </Col>
                <Col xs={24} align='center'>
                  <Link to='/roadmap' className="link-text font-1rem">{t('translation:roadmap')}</Link>
                </Col>
                <Col xs={24} align='center'>
                  <Link to='/aboutus' className="link-text font-1rem">{t('translation:aboutUs')}</Link>
                </Col>
                <Col xs={24} align='center'>
                  <SelectLanguage />
                </Col>
                <Col xs={24} align='center'>
                  <ConnectWallet mobile={true} />
                </Col>
              </Row>
            </Drawer>
          </div>
      }
    </>
  )
}


const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(Header));