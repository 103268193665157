import React, { useEffect, useState } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../redux/byhorses/actions";
import { Col, Image, Row } from 'antd'
import defiImg from "../../assets/images/byhorse/byh-defi-square.png"
import donutChart from "../../assets/images/byhorse/byh-donut-chart.png"
import donutChartEng from "../../assets/images/byhorse/byh-donut-chart-eng.png"
import coinEarn from "../../assets/images/byhorse/byh-coin-earn.png"
import { useTranslation } from 'react-i18next';
import ScheduleChart from './ScheduleChart';

const Byh = () => {
  const [language, setLanguage] = useState('tr')
  const { t } = useTranslation()

  useEffect(() => {
    let lang = localStorage.getItem('multilang')
    if (!lang) lang = 'tr'
    setLanguage(lang)
  }, [])
  return (
    <>
      <Row>
        <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }} >
          <Row style={{marginTop: 20}} align='bottom'>
            <Col xs={24} lg={10}>
              <Image preview={false} src={defiImg} />
            </Col>
            <Col xs={24} lg={14}>
              <Image preview={false} src={language === 'tr' ? donutChart : donutChartEng} />
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }} style={{ marginTop: 120 }}>
          <Row gutter={[16, 16]}>
            <Col xs={24} lg={{ span: 4, offset: 2 }}>
              <div style={{
                borderRight: "1px solid #FFFFFF",
                fontSize: 16,
              }}>
                <span style={{ color: "#FFFFFF" }}>{t('translation:vestingSchedule')}</span><br />
                <span style={{ color: "#C6017F" }}>{t('translation:publicSale')}</span><br />
                <span style={{ color: "#C6017F" }}>{t('translation:preSale')}</span><br />
                <span style={{ color: "#C6017F" }}>{t('translation:seedSale')}</span><br />
                <span style={{ color: "#C6017F" }}>{t('translation:team/')}</span><br />
                <span style={{ color: "#C6017F" }}>{t('translation:development')}</span><br />
                <span style={{ color: "#C6017F" }}>{t('translation:marketing')}</span><br />
                <span style={{ color: "#C6017F" }}>{t('translation:advisors')}</span><br />
                <span style={{ color: "#C6017F" }}>{t('translation:airdrop')}</span><br />
              </div>
            </Col>
            <Col xs={24} lg={{ span: 12, offset: 0 }} style={{
              fontSize: 16,
            }}>
              <span style={{ color: "#C6017F" }}>{t('translation:atTge')}</span><br />
              <span>{t('translation:vestingScheduleP1')}</span><br />
              <span>{t('translation:vestingScheduleP2')}</span><br />
              <span>{t('translation:vestingScheduleP3')}</span><br />
              <span>{t('translation:vestingScheduleP4')}</span><br />
              <span>{t('translation:vestingScheduleP5')}</span><br />
              <span>{t('translation:vestingScheduleP6')}</span><br />
              <span>{t('translation:vestingScheduleP7')}</span><br />
              <span>{t('translation:vestingScheduleP8')}</span><br />
            </Col>
            <Col xs={24} lg={{ span: 6 }}>
              <Image preview={false} src={coinEarn} />
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }} style={{ marginTop: 120 }}>
          {/* CHART BURADA OLACAK */}
          <ScheduleChart />
        </Col>
        <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }} style={{ marginTop: 120 }} align='center'>
          <h1>{t('translation:saleStages')}</h1>
          <div className='byh-table-container'>
            <table className="byh-table">
              <thead>
                <tr>
                  <th scope="col" className="pink-to-white">{t('translation:tokens')}</th>
                  <th scope="col" className="white-to-purple">{t('translation:allocation')}</th>
                  <th scope="col" className="white-to-purple">{t('translation:salesOfTokens')}</th>
                  <th scope="col" className="white-to-purple">{t('translation:tokensPrice')}</th>
                  <th scope="col" className="white-to-purple">{t('translation:amountRaised')}</th>
                  <th scope="col" className="white-to-purple">{t('translation:marketCap')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row" className="white-to-pink">{t('translation:seedSale')}</th>
                  <td className="white-to-purple">5%</td>
                  <td className="white-to-purple">2.500.000.000</td>
                  <td className="white-to-purple">0.0004</td>
                  <td className="white-to-purple">1.000.000</td>
                  <td className="white-to-purple">720.000</td>
                </tr>
                <tr>
                  <th scope="row" className="pink-to-white">{t('translation:publicSale')}</th>
                  <td className="white-to-purple">6%</td>
                  <td className="white-to-purple">3.000.000.000</td>
                  <td className="white-to-purple">0.001</td>
                  <td className="white-to-purple">3.000.000</td>
                  <td className="white-to-purple">2.450.000</td>
                </tr>
                <tr>
                  <th scope="row" className="white-to-pink">{t('translation:inGameRewards/Staking')}</th>
                  <td className="white-to-purple">29%</td>
                  <td className="white-to-purple">14.500.000.000</td>
                  <td className="white-to-purple"></td>
                  <td className="white-to-purple"></td>
                  <td className="white-to-purple"></td>
                </tr>
                <tr>
                  <th scope="row" className="pink-to-white">{t('translation:byhorsesTreasury')}</th>
                  <td className="white-to-purple">7%</td>
                  <td className="white-to-purple">3.500.000.000</td>
                  <td className="white-to-purple"></td>
                  <td className="white-to-purple"></td>
                  <td className="white-to-purple"></td>
                </tr>
                <tr>
                  <th scope="row" className="white-to-pink">{t('translation:liquidity')}</th>
                  <td className="white-to-purple">5%</td>
                  <td className="white-to-purple">2.500.000.000</td>
                  <td className="white-to-purple"></td>
                  <td className="white-to-purple"></td>
                  <td className="white-to-purple"></td>
                </tr>
                <tr>
                  <th scope="row" className="pink-to-white">{t('translation:team/Development')}</th>
                  <td className="white-to-purple">25%</td>
                  <td className="white-to-purple">12.500.000.000</td>
                  <td className="white-to-purple"></td>
                  <td className="white-to-purple"></td>
                  <td className="white-to-purple"></td>
                </tr>
                <tr>
                  <th scope="row" className="white-to-pink">{t('translation:marketing')}</th>
                  <td className="white-to-purple">6%</td>
                  <td className="white-to-purple">3.000.000.000</td>
                  <td className="white-to-purple"></td>
                  <td className="white-to-purple"></td>
                  <td className="white-to-purple"></td>
                </tr>
                <tr>
                  <th scope="row" className="pink-to-white">{t('translation:advisors')}</th>
                  <td className="white-to-purple">6%</td>
                  <td className="white-to-purple">3.000.000.000</td>
                  <td className="white-to-purple"></td>
                  <td className="white-to-purple"></td>
                  <td className="white-to-purple"></td>
                </tr>
                <tr>
                  <th scope="row" className="white-to-pink">{t('translation:airdrop')}</th>
                  <td className="white-to-purple">1%</td>
                  <td className="white-to-purple">500.000.000</td>
                  <td className="white-to-purple"></td>
                  <td className="white-to-purple"></td>
                  <td className="white-to-purple"></td>
                </tr>
                <tr>
                  <th scope="row" className="pink-to-white">{t('translation:preSale')}</th>
                  <td className="white-to-purple">10%</td>
                  <td className="white-to-purple">5.000.000.000</td>
                  <td className="white-to-purple">0.00075</td>
                  <td className="white-to-purple">3.750.000</td>
                  <td className="white-to-purple">1.350.000</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Col>
        <Col xs={{ span: 22, offset: 1 }} lg={{ span: 20, offset: 2 }} style={{ marginTop: 120 }} align='center'>
          <h1>{t('translation:vestingSchedule2')}</h1>
          <div className='byh-table-container'>
            <table className="byh-table">
              <thead>
                <tr>
                  <th scope="col" className="pink-to-white">{t('translation:team/Development')}</th>
                  <td className="white-to-purple">{t('translation:vestingSchedule2P1')}</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row" className="white-to-pink">{t('translation:marketing')}</th>
                  <td className="white-to-purple">{t('translation:vestingSchedule2P2')}</td>
                </tr>
                <tr>
                  <th scope="row" className="pink-to-white">{t('translation:advisors')}</th>
                  <td className="white-to-purple">{t('translation:vestingSchedule2P3')}</td>
                </tr>
                <tr>
                  <th scope="row" className="white-to-pink">{t('translation:seedSale')}</th>
                  <td className="white-to-purple">{t('translation:vestingSchedule2P4')}</td>
                </tr>
                <tr>
                  <th scope="row" className="pink-to-white">{t('translation:publicSale')}</th>
                  <td className="white-to-purple">{t('translation:vestingSchedule2P5')}</td>
                </tr>
                <tr>
                  <th scope="row" className="white-to-pink">{t('translation:preSale/PrivateSale')}</th>
                  <td className="white-to-purple">{t('translation:vestingSchedule2P6')}</td>
                </tr>
                <tr>
                  <th scope="row" className="pink-to-white">{t('translation:treasuryTokens')}</th>
                  <td className="white-to-purple">{t('translation:vestingSchedule2P7')}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Col>

      </Row>
    </>
  )
}

const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(Byh));