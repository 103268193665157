import axios from 'axios';
import { getApiBase } from './config'

const base = getApiBase()

export const protectedApi = axios.create({
    baseURL: base
});

export const publicApi = axios.create({
    baseURL: base,
})

protectedApi.interceptors.request.use(function (config) {
    const user = JSON.parse(window.localStorage.getItem('user'));
    config.headers.Authorization =  'Bearer ' + user.access_token;

    return config;
});