import React from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setRedux } from "../../redux/byhorses/actions";
import { Button, Checkbox, Col, Input, Row, Select } from 'antd'
import { useState } from 'react'
import validator from 'validator';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

const labelStyle = {
  fontWeight: 700,
  color: "#472682",
  marginLeft: 2
}

const Contact = (props) => {
  const { t } = useTranslation()
  const [language, setLanguage] = useState('')
  const [validEmailError, setValidEmailError] = useState(false)
  const [errors, setErrors] = useState({
    fullName: false,
    email: false,
    category: false,
    title: false,
    description: false,
    checkbox1: false,
    checkbox2: false,
  })
  const [form, setForm] = useState({
    fullName: "",
    email: "",
    category: undefined,
    title: "",
    description: "",
    checkbox1: false,
    checkbox2: false,
  })

  const handleInputChange = (name, event) => {
    setForm((prevState) => ({
      ...prevState,
      [name]: event.target.value
    }))
    setErrors((prevState) => ({
      ...prevState,
      [name]: false
    }))
    if (name === "email") setValidEmailError(false)
  }
  const handleSelectChange = (name, value) => {
    setForm((prevState) => ({
      ...prevState,
      [name]: value
    }))
    setErrors((prevState) => ({
      ...prevState,
      [name]: false
    }))
  }
  const handleCheckboxChange = (name, event) => {
    setForm((prevState) => ({
      ...prevState,
      [name]: event.target.checked
    }))
    setErrors((prevState) => ({
      ...prevState,
      [name]: false
    }))
  }

  const submitForm = () => {
    if (!form.checkbox1 || !form.checkbox2) return
    Object.keys(form).forEach((key) => {
      const value = form[key];
      if (value === "" || value === undefined) {
        setErrors((prevState) => ({
          ...prevState,
          [key]: true
        }))
        return
      }
    });
    if (!validator.isEmail(form.email)) {
      setValidEmailError(true)
      return
    }

  }

  // Handle Language
  useEffect(() => {
    let lang = localStorage.getItem('multilang')
    if (!lang) lang = 'tr'
    setLanguage(lang)
  }, [])

  return (
    <Row>
      <Col xs={24} className={language === 'tr' ? 'contact-top-tr' : 'contact-top-en'} style={{ minHeight: 500 }}>
        <Row align='middle'>
          <Col xs={{ span: 12, offset: 6 }} lg={{ span: 6, offset: 6 }} >
            <Row style={{
              fontSize: props.reduxState?.innerWidth > 768 ? 18 : 16,
              whiteSpace: "nowrap",
              marginTop: props.reduxState?.innerWidth > 768 ? undefined : 70,
              marginLeft: props.reduxState?.innerWidth > 768 ? undefined : 20
            }}>

              {language === 'tr' ?
                <>
                  <Col xs={24}>
                    <b>byHorses</b> hakkında daha detaylı
                  </Col>
                  <Col xs={24}>
                    bilgi almak, görüş, öneri ve şikayet
                  </Col>
                  <Col xs={24}>
                    bildirmek için formu doldurarak
                  </Col>
                  <Col xs={24}>
                    bizimle iletişime geçebilirsiniz!
                  </Col>
                </>
                :
                <>
                  <Col xs={24}>
                    More detailed about <b>byHorses</b>
                  </Col>
                  <Col xs={24}>
                    You can contact us by filling out
                  </Col>
                  <Col xs={24}>
                    the form in order to get information,
                  </Col>
                  <Col xs={24}>
                    and to submit comments,
                  </Col>
                  <Col xs={24}>
                    suggestions and complaints
                  </Col>
                </>
              }
            </Row>
          </Col>
          <Col xs={{ span: 22, offset: 1 }} lg={{ span: 7, offset: 2 }}>
            <div style={{
              backgroundColor: '#FFFFFF',
              borderRadius: 20,
              padding: 20,
              marginTop: props.reduxState?.innerWidth > 768 ? undefined : 100
            }}>
              <Row gutter={[24, 8]}>
                <Col xs={{ span: 24, offset: 0 }}>
                  <label style={labelStyle}>{t('translation:nameSurname')}</label>
                  <Input className='' placeholder='' onChange={(event) => handleInputChange("fullName", event)} />
                  <span style={{ display: !errors.fullName && "none", color: '#c6017f' }}>{t('translation:nameSurnameError')}</span>
                </Col>
                <Col xs={{ span: 24, offset: 0 }}>
                  <label style={labelStyle}>{t('translation:email')}</label>
                  <Input className='' placeholder='' onChange={(event) => handleInputChange("email", event)} />
                  <span style={{ display: !validEmailError && "none", color: '#c6017f' }}>{t('translation:emailError')}</span>
                </Col>
                <Col xs={{ span: 24, offset: 0 }}>
                  <label style={labelStyle}>{t('translation:category')}</label>
                  <Select allowClear style={{ width: '100%' }} className='purple-select'
                    placeholder="" onChange={(value) => handleSelectChange("category", value)}>
                    <Select.Option value={0}>{t(`translation:horses`)}</Select.Option>
                    <Select.Option value={1}>{t(`translation:jockeys`)}</Select.Option>
                    <Select.Option value={2}>{t(`translation:studs`)}</Select.Option>
                    <Select.Option value={3}>{t(`translation:general`)}</Select.Option>
                  </Select>
                  <span style={{ display: !errors.category && "none", color: '#c6017f' }}>{t('translation:categoryError')}</span>
                </Col>
                <Col xs={{ span: 24, offset: 0 }}>
                  <label style={labelStyle}>{t('translation:title')}</label>
                  <Input className='' placeholder='' onChange={(event) => handleInputChange("title", event)} />
                  <span style={{ display: !errors.title && "none", color: '#c6017f' }}>{t('translation:titleError')}</span>
                </Col>
                <Col xs={{ span: 24, offset: 0 }}>
                  <label style={labelStyle}>{t('translation:description')}</label>
                  <Input className='' placeholder='' onChange={(event) => handleInputChange("description", event)} />
                  <span style={{ display: !errors.description && "none", color: '#c6017f' }}>{t('translation:descriptionError')}</span>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} align='left'>
                  <Checkbox onChange={(e) => handleCheckboxChange('checkbox1', e)}>
                    {language === 'tr' ?
                      <>
                        <span style={{ color: '#472682' }}>Tarafıma gönderilecek bildirimleri (sms, mobil, e-posta) almayı </span>
                        <span style={{ color: "#c6017f" }}> kabul ediyorum!</span>
                      </>
                      :
                      <>
                        <span style={{ color: '#472682' }}>Notifications to be sent to me (sms, mobile, e-mail) I </span>
                        <span style={{ color: "#c6017f" }}> agree to receive!</span>
                      </>
                    }
                  </Checkbox>
                </Col>
                <Col xs={{ span: 24, offset: 0 }} align='left'>
                  <Checkbox onChange={(e) => handleCheckboxChange('checkbox2', e)}>
                    {language === 'tr' ?
                      <>
                        <span style={{ color: '#472682' }}>Kişisel Veri Kullanımı Aydınlatma Metnini </span>
                        <span style={{ color: "#c6017f" }}> kabul ediyorum!</span>
                      </>
                      :
                      <>
                        <span style={{ color: "#c6017f" }}>I accept the </span>
                        <span style={{ color: '#472682' }}>Personal Data Usage Clarification Text! </span>
                      </>
                    }
                  </Checkbox>
                </Col>
                <Col xs={24} align='center' style={{ marginTop: 20, marginBottom: 20 }}>
                  <Button className='contact-submit-btn' type='primary'
                    onClick={submitForm} size="large"
                    style={(!form.checkbox1 || !form.checkbox2) ? {
                      borderRadius: 20,
                      border: 'none',
                      backgroundColor: '#472682',
                      cursor: 'not-allowed',
                    } : { borderRadius: 20 }}>
                    <span style={(!form.checkbox1 || !form.checkbox2) ? { color: "#FFFFFF" } : undefined}>{t('translation:send')}</span>
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

const mapState = (globalState) => {
  return { reduxState: globalState.reduxState };
};
export default connect(mapState, { setRedux })(withRouter(Contact));